import { render, staticRenderFns } from "./invoicePage.vue?vue&type=template&id=72a29eb8&scoped=true&"
import script from "./invoicePage.vue?vue&type=script&lang=js&"
export * from "./invoicePage.vue?vue&type=script&lang=js&"
import style0 from "./invoicePage.vue?vue&type=style&index=0&id=72a29eb8&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72a29eb8",
  null
  
)

export default component.exports