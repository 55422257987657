<template>
  <div>
    <!-- invoice history -->
    <br />
    <b>Invoice History</b>
    <v-data-table :sort-by="defaultSortBy" :sort-desc="defaultSortDesc" :headers="invoiceHeader"
      :items="invoiceListItems" :items-per-page="5" class="elevation-1">
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editInvoiceItem(item)">mdi-pencil</v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="editInvoicePage" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Current Invoice</span>
          <v-spacer />
          <v-btn icon @click="editInvoicePage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-list dense>
            <v-list-item v-for="(service, index) in invoiceServices" :key="index" class="mb-2">
              <v-card class="pa-3 full-width" outlined>
                <div>Service: {{ service.service.serviceName }}</div>
                <div>Provider: {{ service.provider.providerName }}</div>
                <div>Tax: {{ service.service.serviceHST ? 'Yes' : 'No' }}</div>
                <br />
                <span>Total: {{ service.service.servicePrice }}</span>
                <div class="d-flex align-center"><span>Amount: {{ service.service.amount }}</span>
                </div>
              </v-card>
            </v-list-item>
          </v-list>
          <v-card>
            <v-container>
              Total: {{ this.total.toFixed(2) }}
            </v-container>
          </v-card>
          <br />
          <v-btn color="primary" @click="printInvoiceNoDB">Print Current Invoice</v-btn>
          <br />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--  Invoice pdf   -->
    <VueHtml2pdf :show-layout="false" :float-layout="true" :enable-download="false" :preview-modal="true"
      :paginate-elements-by-height="1400" filename="Invoice" :pdf-quality="2" :manual-pagination="false" pdf-format="a4"
      pdf-orientation="portrait" pdf-content-width="800px" @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)" ref="invoicePDF">

      <section slot="pdf-content" class="marginall">

        <h1 class="center">Invoice</h1>

        <div class="center">
          <p v-if="location">{{ this.location.location }}</p>
        </div>


        <div class="right-aligned">
          <p>Invoice Number: {{ invoiceNumber }}</p>
        </div>
        <div class="left-aligned">
          <p>Issue Date: {{ today }}</p>
          <p>Bill To: {{ this.oneUser && this.oneUser.basicInfo ? this.oneUser.basicInfo.first_name + ' ' +
      this.oneUser.basicInfo.last_name : 'N/A' }} </p>
          <p>Address: {{ this.oneUser ? this.oneUser.defaultAddress : 'N/A' }}</p>
          <p>Phone: {{ this.oneUser ? this.oneUser.defaultPhoneNumber : 'N/A' }}</p>
          <p>Email: {{ this.oneUser ? this.oneUser.defaultEmail : 'N/A' }}</p>
        </div>

        <br />
        <br />
        <table>
          <thead>
            <tr>
              <th>Service Name</th>
              <th>Unit Price ($)</th>
              <th>Amount</th>
              <th>Price ($)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(service, index) in invoiceServices" :key="index">
              <td>{{ service.service.serviceName }}</td>
              <td>{{ parseFloat(service.service.servicePrice).toFixed(2) }}</td>
              <td>{{ service.service.amount }}</td>
              <td>{{ (service.service.servicePrice * service.service.amount).toFixed(2) }}</td>
            </tr>
          </tbody>
        </table>
        <br />

        <div class="right-aligned">
          <p>SubTotal: {{ subTotal.toFixed(2) }}</p>
          <p>Tax: {{ hst.toFixed(2) }}</p>
          <p>Total: {{ total.toFixed(2) }}</p>
        </div>
        <br />

        <div class="left-aligned">
          <p>Professional Number: {{ selectedNumber }} </p>
          <p>Provider: {{ providerName }}</p>
          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
          <p>Signature: ______________________</p>
        </div>

      </section>
    </VueHtml2pdf>

  </div>
</template>
<script>
import VueHtml2pdf from "vue-html2pdf";
export default {
  components: {
    VueHtml2pdf,
  },
  name: "allInvoicePage",
  data: () => ({
    providerName: null,
    selectedNumber: null,
    today: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    invoiceCounter: 0,
    oneUser: null,
    selectedPaymentMethod: '',
    invoiceNumber: 'null',
    services: [],
    editInvoicePage: false,
    editing: false,
    invoiceServices: [],
    location: null,
    defaultSortBy: 'date',
    defaultSortDesc: true,
    selectedEvent: [],
    invoiceHeader: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "date",
      },
      {
        text: "Total Price ($)",
        value: "total",
      },
      { text: "Actions", value: "actions", sortable: false },

    ],
    invoiceListItems: [],
  }),
  created() {
    this.getInvoiceList();
  },

  computed: {
    subTotal() {
      return this.invoiceServices.reduce((total, service) => {
        const price = service.service.servicePrice;
        return total + price * service.service.amount;
      }, 0);
    },
    hst() {
      return this.invoiceServices.reduce((total, service) => {
        if (service.service.serviceHST) {
          const price = service.service.servicePrice;
          const taxRate = service.service.hstRate && !isNaN(parseFloat(service.service.hstRate)) ? 1 + parseFloat(service.service.hstRate) / 100 : 1;
          return total + price * service.service.amount * (taxRate - 1);
        }
        return total;
      }, 0);
    },
    total() {
      return this.subTotal + this.hst;
    },
    totalSaved() {
      const originalTotal = this.invoiceServices.reduce((total, service) => {
        return total + service.service.servicePrice * service.service.amount;
      }, 0);
      return originalTotal - this.total;
    },
    difference() {
      const originalTotal = this.invoiceServices.reduce((total, service) => {
        const price = service.service.servicePrice * service.service.amount;
        return total + (service.service.serviceHST ? price * 1.13 : price);
      }, 0);
      return originalTotal - this.total;
    }
  },

  methods: {
    async printInvoiceNoDB() {
      this.$refs.invoicePDF.generatePdf()
    },

    async editInvoiceItem(data) {
      this.providerName = data.selectedEvent.provider[0].provider.providerName
      this.editing = true
      this.invoiceCounter++
      this.invoiceNumber = data.invoiceNumber || 'null'
      this.selectedPaymentMethod = data.paymentMethod
      this.selectedNumber = data.professionalNumber
      this.invoiceServices = data.invoiceServices
      await this.getUserInfo(data.UserId)
      this.editInvoicePage = true
    },

    async getInvoiceList() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/receiptList/getAllInvoice",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
        }
      })
        .then((response) => {
          this.invoiceListItems = response.data
          this.invoiceListItems.forEach((doc) => {
            doc.date = doc.date.split("T")[0] + " " + doc.date.split("T")[1].split('.')[0]
          })
        }).catch((error) => {
          console.error("Error fetching invoice list: ", error);
        });
    },

    async getUserInfo(event) {
      let user = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getOneUser",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { userId: event },
      })
        .then((response) => {
          let appData = response.data;
          user = appData;
        })
        .catch((error) => console.log(error));

      this.oneUser = user;

      let addressTemp = this.oneUser.addresses.find(address => address.default === true);
      if (addressTemp) {
        this.oneUser.defaultAddress = addressTemp.AddressLine1 + ' ' + addressTemp.AddressLine2 + ' ' + addressTemp.City + ' ' + addressTemp.Province + ' ' + addressTemp.PostalCode + ' ' + addressTemp.Country
      }
    },

  },
};
</script>
<style scoped>
.highlight-text {
  font-size: larger;
  font-weight: bold;
  color: red;
}

.marginall {
  margin-top: 100px;
  margin-bottom: 100px;
  margin-right: 100px;
  margin-left: 100px;
}

.full-width {
  width: 100%;
}

.center {
  text-align: center;
  justify-content: center;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  /* Center align text in table cells */
}

.line-through {
  text-decoration: line-through;
}


thead {
  background-color: #f0f0f0;
}

.left-aligned {
  text-align: left;
}

.right-aligned {
  text-align: right;
}
</style>
