<template>
  <v-card flat>
    <v-card-text>
      <v-btn color="primary" @click="addNewLocationFn()"> New Location </v-btn>
      <v-data-table
        :headers="locationListHeader"
        :items="locationListItem"
        :items-per-page="5"
        class="elevation-1"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editLocationItem(item)"
            >mdi-pencil</v-icon
          >
        </template>
      </v-data-table>
      <v-dialog v-model="addNewLocation" max-width="450">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Add New Location</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="addNewLocation = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-container>
            <v-form @submit.prevent="addLocationEvent">
              <v-row>
                <v-col cols="12">
                  <v-card>
                    <v-card-title>Info</v-card-title>
                    <v-container>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="addCompanyName"
                            label="Company"
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="addLocationName"
                            label="Location Address"
                          />
                        </v-col>
                      </v-row>
                      <v-row>
                        <v-col cols="6">
                          <v-text-field
                            v-model="addLocationEmail"
                            label="Location Email"
                          />
                        </v-col>
                        <v-col cols="6">
                          <v-text-field
                            v-model="addLocationPhone"
                            label="Location Phone Number"
                            @input="acceptLocation"
                          />
                        </v-col>
                      </v-row>
                      <v-card-actions>
                        <v-btn
                          :disabled="!addCompanyName"
                          type="submit"
                          color="primary"
                          class="mb-4"
                          @click.stop="addNewLocation = false"
                        >
                          Add
                        </v-btn>
                      </v-card-actions>
                    </v-container>
                  </v-card>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog scrollable v-model="editLocationPage" fullscreen :scrim="false">
        <v-card color="#EAE7F0">
          <v-card-title>
            Location Management
            <v-spacer />
            <v-btn icon @click="editLocationPage = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <settingsManageLocationDetail
              :selectedLocation="selectedLocation"
              :key="updateLocationPicker"
              @close="(editLocationPage = false), getLocationList()"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-card-text>
  </v-card>
</template>
<script>
import settingsManageLocationDetail from "./settingsManageLocationDetail.vue";
export default {
  name: "settingsPage",
  props: ["userGroup"],
  components: {
    settingsManageLocationDetail,
  },
  data: () => ({
    addNewLocation: false,
    addLocationName: "",
    addCompanyName: "",
    addLocationEmail: "",
    addLocationPhone: "",
    locationListHeader: [
      { text: "id", align: "start", value: "idNumber" },
      { text: "Location Name", align: "start", value: "companyName" },
      { text: "Address", align: "start", value: "location" },
      { text: "Phone Number", align: "start", value: "phone" },
      { text: "Email", align: "start", value: "email" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    locationListItem: [],
    selectedLocation: [],
    editLocationPage: false,
    updateLocationPicker: 0,
  }),
  async mounted() {
    await this.getLocationList();
  },
  methods: {
    addNewLocationFn() {
      this.addLocationName = "";
      this.addCompanyName = "";
      this.addLocationPhone = "";
      this.addLocationEmail = "";
      this.addNewLocation = true;
    },
    async editLocationItem(item) {
      this.updateLocationPicker++;
      this.selectedLocation = Object.assign({}, item);
      this.addLocationName = this.selectedLocation.location;
      this.addCompanyName = this.selectedLocation.companyName;
      this.addLocationPhone = this.selectedLocation.phone;
      this.addLocationEmail = this.selectedLocation.email;
      this.editLocationPage = true;
    },
    async getLocationList() {
      await this.$getLocationsList();
      this.locationListItem = this.$LocationsListObj;
    },
    async addLocationEvent() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/addLocation",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          location: this.addLocationName,
          companyName: this.addCompanyName,
          phone: this.addLocationPhone,
          email: this.addLocationEmail,
        },
      }).catch((error) => console.log(error));
      this.addLocationName = null;
      this.addCompanyName = null;
      this.addLocationEmail = null;
      this.addLocationPhone = null;
      this.getLocationList();
    },
    acceptLocation() {
      var x = this.addLocationPhone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.addLocationPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
  },
};
</script>
<style scoped>
table,
td,
th {
  border: 1px solid;
}
.patientTable {
  border-collapse: collapse;
  text-align: center;
  width: 100%;
}
.center {
  text-align: center;
  position: relative;
}
</style>
