<template>
  <div>
    <br />
    <v-data-table :headers="headers" :items="patientsObj" :items-per-page="itemsPerPage" :page.sync="page"
      :sort-by="['idNumber']" :sort-desc="[true]" :loading="loading" loading-text="Loading... Please wait"
      class="elevation-1" :custom-filter="customSearch" hide-default-footer><br />
      <template v-slot:top>
        <v-toolbar flat color="white">
          <v-btn color="#818cf8" @click="(addNewUser = true), reloadAddPatient++">Add New Patient</v-btn>
          <v-text-field v-model="patientListSearch" label="Search" filled type="text" class="search ml-5 mr-5"
            :append-icon="iconName" @keyup.enter="searchUser" @click:append="searchUser" color="black"></v-text-field>
          <v-btn color="#818cf8" @click="reloadPage()">Clear</v-btn>

          <v-dialog v-model="dialog" width="800px" @keydown.esc="reloadPage" @click:outside="reloadPage">
            <v-card>
              <patientDetailPage @close="reloadPage" :selectedEvent="editedItem" :key="reloadPatient"
                :userId="editedItem.UserId" />
            </v-card>
          </v-dialog>
          <v-dialog v-model="addNewUser" width="800px" :retain-focus="false" persistent>
            <v-card>
              <v-card-title>Add New Patient
                <v-spacer />
                <v-btn icon @click="addNewUser = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <newPatient @close="addedNewPatient" :key="reloadAddPatient" />
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
    </v-data-table>
    <div class="text-center pt-2">
      <v-pagination v-model="page" :length="isLastPage ? page : pageCount + 1"
        @input="paginationPage($event)"></v-pagination>
    </div>
  </div>
</template>
<style scoped>
.search {
  margin-top: 3%;
}
</style>
<script>
export default {
  name: "allPatientsPage",
  mounted() {
    this.getPatientList();
  },
  computed: {
    iconName() {
      return this.loading ? "mdi-loading" : "mdi-magnify";
    },
  },
  components: {},
  data: () => ({
    page: 1,
    isLastPage: false,
    pageCount: 1,
    itemsPerPage: 10,
    previousPage: 1,
    addNewUser: false,
    dialog: false,
    patientDetail: false,
    reloadPatient: 0,
    reloadAddPatient: 0,
    desserts: [],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {},
    first_name: "",
    preferred_name: "",
    last_name: "",
    email: "",
    phone: "",
    patientListSearch: "",
    headers: [
      {
        text: "id",
        align: "start",
        value: "idNumber",
      },
      {
        text: "Last Name",
        align: "start",
        value: "last_name",
      },
      {
        text: "First Name",
        align: "start",
        value: "first_name",
      },
      {
        text: "Preferred Name",
        align: "start",
        value: "preferred_name",
      },
      { text: "Last Visit", value: "last_visit" },
      { text: "Email", value: "email" },
      { text: "Phone", value: "phone" },
      { text: "Date Created", value: "date_createdDisplay" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    loading: false,
    patientsObj: [],
    firstId: "",
    lastId: "",
    orderBy: null,
    direction: null,
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  methods: {
    reloadPage() {
      this.dialog = false
      this.reloadCurrentPage()
    },
    async searchUser() {
      this.pageCount = 1
      this.isLastPage = true
      this.loading = true
      this.patientsObj = []
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/searchUserByPage",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        data: {
          search: this.patientListSearch
        },
      })
        .then((response) => {
          this.itemsPerPage = -1
          this.patientsObj = response.data;
          this.pageCount = -1
          this.loading = false
        })
        .catch((error) => console.log(error));
    },
    addedNewPatient() {
      (this.addNewUser = false), this.getPatientList();
    },
    async paginationPage(event) {
      this.loading = true
      this.patientsObj = []
      if (event > this.previousPage) {
        if (!this.isLastPage) {
          this.direction = "next";
          await this.getPatientList();
          this.pageCount = this.pageCount + 1;
        } else {
          this.direction = "next";
          this.isLastPage = false;
          this.getPatientList();
        }
      } else {
        this.pageCount = this.pageCount - 1;
        this.direction = "previous";
        this.getPatientList();
      }
      this.previousPage = event;
    },
    async editItem(item) {
      this.reloadPatient++;
      this.editedIndex = this.patientsObj.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },

    async reloadCurrentPage() {
      // Clear the search input
      this.patientListSearch = '';
      // Start loading
      this.loading = true;
      // Clear the current displayed patients
      this.patientsObj = [];
      // Reset items per page to default
      this.itemsPerPage = 10;
      // Reset page count if necessary, and set current page to 1
      this.pageCount = 1; // Adjust according to your total data count
      this.page = 1; // Reset to first page

      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/reloadCurrentPage",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        data: {
          startId: this.firstId,
          orderBy: this.orderBy,
          itemsPerPage: this.itemsPerPage,
          pageCount: this.pageCount,
        },
      })
        .then((response) => {
          this.patientsObj = response.data.data;
          this.isLastPage = response.data.message === "Last Page";
          this.firstId = response.data.currentStartId;
          this.lastId = response.data.currentEndId;
          this.loading = false;
        })
        .catch((error) => console.log(error));
    },

    async getPatientList() {
      this.patientListSearch = ''
      this.loading = true
      this.patientsObj = []
      this.itemsPerPage = 10

      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getUserByPage",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        data: {
          startId: this.firstId,
          endId: this.lastId,
          direction: this.direction,
          orderBy: this.orderBy,
          itemsPerPage: this.itemsPerPage,
          pageCount: this.pageCount,
        },
      })
        .then((response) => {
          this.patientsObj = response.data.data;
          this.isLastPage = response.data.message;
          this.firstId = response.data.currentStartId;
          this.lastId = response.data.currentEndId;
          this.loading = false
        })
        .catch((error) => console.log(error));
    },
    acceptNumber(phone) {
      var x = phone.replace(/\D/g, "").match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      phone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    customSearch(value, search, item) {
      return Object.values(item).some(
        (v) => v && v.toString().toLowerCase().includes(search)
      );
    },
  },
};
</script>
