<template>
  <div>
    <br />
    <v-container>
      <v-form @submit.prevent="addEvent">
        <v-row>
          <v-col cols="12" sm="5">
            <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
              offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="DateToCreateNewEvent" label="Select Date" prepend-icon="mdi-calendar" readonly
                  v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker ref="datePicker" v-model="DateToCreateNewEvent" @input="validateDate()"></v-date-picker>
            </v-menu>
            <div v-if="isPastDate" style="color: red">
              Cannot book past dates!
            </div>
            <v-row>
              <v-col cols="12">
                <v-text-field :append-icon="iconName" filled @keyup.enter="searchUserFn" @click:append="searchUserFn"
                  v-model="searchUser" type="text" label="Search Patient"></v-text-field>
              </v-col>
            </v-row>
            <v-btn color="#818cf8" @click="(addNewUser = true), reloadAddPatient++">Add New Patient</v-btn>
            <br />
            <div v-if="userSuggestionButtonList.length != 0">
              Suggested Patient: {{ suggestedUser }}
              <div class="borderedLine">
                <div v-for="(button, i) in userSuggestionButtonList" :key="i" class="but">
                  <div>
                    <v-row>
                      <v-col>
                        <v-btn @click="getUserInfo(button.id)">{{ button.first_name }} {{ button.preferred_name }}
                          {{ button.last_name }} {{ button.phone }}</v-btn>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <v-card v-if="oneUser.UserId">
              <v-toolbar dark>
                <v-toolbar-title>
                  {{ this.oneUser.basicInfo.first_name }}
                  {{ this.oneUser.basicInfo.last_name }}
                  <div class="inlineblocking" v-if="oneUser.basicInfo.preferred_name">
                    ({{ this.oneUser.basicInfo.preferred_name }})
                  </div>
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <div v-if="this.oneUser.basicInfo.first_name">
                  Loyalty: {{ oneUser.basicInfo.loyaltyRating }}
                </div>
              </v-toolbar>
              <patientInformationDisplay @close="closeEditUserInfo" v-if="oneUser.UserId" :selectedEvent="oneUser"
                :key="refreshCounter" />
            </v-card>
            <br />
            <div class="onelinebreak">
              <v-row>
                <v-col class="d-flex" cols="12" sm="6">
                  <locationSelector @locationSelected="onLocationSelected" :locationFill="locationFill"
                    :key="locationFill" />
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <v-select v-model="action_with_doctor" :items="action_with_doctor_dropdown" label="Action" dense
                    outlined></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex" cols="12" sm="6">
                  <providerSelector @providerSelected="onProviderSelected" :locationId="this.locationId"
                    :key="locationId + locationFill" :providerFill="providerFill" />
                </v-col>
                <v-col class="d-flex" cols="12" sm="6">
                  <serviceSelector @serviceSelected="onServiceSelected" :providerId="this.providerId"
                    :locationId="locationId" :key="providerId + '-' + locationId" />
                </v-col>
              </v-row>
            </div>
            {{ this.timeSlotLengthToDisplay }}
            <v-text-field v-if="this.UserId" :key="this.UserId" v-model="perVisitDetails" type="text"
              label="Notes For This Visit"></v-text-field>

            <v-text-field v-model="color" label="Color"></v-text-field>
            <v-row justify="space-around">
              <v-color-picker v-model="color" type="color" label="Color" class="ma-2" :swatches="swatches" hide-canvas
                hide-inputs hide-sliders show-swatches></v-color-picker>
            </v-row>
            <br />
            <!-- <v-row align="center">
              <v-checkbox v-model="phoneEnable" hide-details class="shrink mr-2 mt-0"></v-checkbox>
              <v-text-field :disabled="!phoneEnable" v-model="checkPhone" label="Phone Reminder"></v-text-field>
            </v-row> -->
            <!-- <v-row>
              <v-checkbox v-model="emailEnable" hide-details class="shrink mr-2 mt-0"></v-checkbox>
              <v-text-field :disabled="!emailEnable" v-model="checkEmail" label="Email Reminder"></v-text-field>
              <v-checkbox v-model="sendEmailToProviderEnable" label="Also Send Email to Provider"
                class="shrink mr-2 mt-0"></v-checkbox>
              <p>{{ doctorSelector.providerEmail }}</p>
            </v-row> -->
            <v-btn color="primary" class="mb-4 mr-2" @click="cancelCreateEvent">Cancel</v-btn>
            <v-btn :disabled="!tempStart ||
              !service ||
              hasError == null ||
              !timeValidation ||
              isPastDate
              " type="submit" color="primary" class="mb-4" @click.stop="handleSubmit"> <v-progress-circular
                indeterminate color="white" size="24" width="3" v-if="loading">
              </v-progress-circular>

              <span v-if="!loading">Create</span></v-btn>

            <div v-if="formError">{{ formError }}</div>

          </v-col>
          <v-col cols="12" sm="7">
            <v-calendar ref="calendar" v-model="today" type="category" :events="events" :now="today" :value="today"
              :categories="categoryName" :first-interval="6" :interval-minutes="60" interval-height="70"
              :interval-count="18" @mousedown:event="startDrag" @mousemove:time="mouseMove" @mouseup:time="endDrag"
              @mousedown:time-category="startTimeDayCategory" @mousemove:time-category="mouseMoveTimeCategory"
              @mouseleave.native="cancelDrag">
              <template v-slot:event="{ event, timed, eventSummary }">
                <div class="v-event-draggable">
                  <component :is="{ render: eventSummary }"></component>
                </div>
                <div v-if="timed" class="v-event-drag-bottom" @mousedown.stop="extendBottom(event)"></div>
              </template>
            </v-calendar>
          </v-col>
        </v-row>
      </v-form>
    </v-container>
    <v-dialog v-model="addNewUser" persistent width="850px">
      <v-card>
        <v-card-title>Add New Patient
          <v-spacer />
          <v-btn icon @click="addNewUser = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <newPatient @close="addedNewPatient($event)" @id="getAddedId" :minimal="true" :key="reloadAddPatient" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>

export default {
  name: "newEventPage",
  props: {
    providerfill: {
      providerfill: String,
    },
    startfill: {
      startfill: String,
    },
    endfill: {
      endfill: String,
    },
    initialState: Object,
  },
  async mounted() {
    if (this.initialState && Object.keys(this.initialState).length) {
      Object.assign(this.$data, this.initialState);
    }
    this.DateToCreateNewEvent = this.today;
    if (this.startfill) {
      this.start = this.startfill;
    }
    if (this.endfill) {
      this.end = this.endfill;
    }
    if (this.providerfill) {
      this.doctor = this.providerfill;
    }
    function addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    const d = new Date();
    let h = addZero(d.getHours());
    let m = addZero(d.getMinutes());
    let s = addZero(d.getSeconds());
    this.start = h + ":" + m + ":" + s;
    this.end = h + ":" + m + ":" + s;
  },
  components: {},
  data: () => ({
    loading: false,
    refreshCounter: 0,
    DateToCreateNewEvent: null,
    details: null,
    perVisitDetails: null,
    UserId: null,
    menu: false,
    categoryName: null,

    pickerDate: new Date().toISOString().substr(0, 7),
    isPastDate: false,
    timeSlotLengthToDisplay: "",

    action_with_doctor_dropdown: ["Initial Visit", "Follow Up"],
    reloadAddPatient: 0,
    occupation: "",
    birth_date: "",
    first_name: "",
    preferred_name: "",
    last_name: "",
    doctor: null,
    service: null,
    action_with_doctor: null,
    service_type_dropdown: [],
    action: null,
    dateOnly: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .slice(0, -1)
      .split("T")[0],
    start: new Date().getHours() + ":" + new Date().getMinutes(),
    end: new Date().getHours() + ":" + new Date().getMinutes(),
    userSuggestionButtonList: [],
    color: "#1976D2",
    searchUser: "",
    location: null,
    SelectedUserId: "",
    locationId: null,
    providerId: null,
    addNewUser: false,

    currentlyEditingNotes: false,
    checkEmail: null,
    checkPhone: null,
    phoneEnable: false,
    emailEnable: false,
    sendEmailToProviderEnable: false,
    suggestedUser: null,
    swatches: [
      ["#DC2127", "#FF887C"],
      ["#FFB878", "#FBD75B"],
      ["#7AE7BF", "#51B749"],
      ["#46D6DB", "#5484ED"],
    ],
    hasError: false,
    events: [],
    today: new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000
    )
      .toISOString()
      .substr(0, 10),
    tomorrow: new Date(
      new Date().getTime() - new Date().getTimezoneOffset() * 60000 + 86400000
    )
      .toISOString()
      .substr(0, 10),
    value: "",
    dragEvent: null,
    dragStart: null,
    createEvent: null,
    createStart: null,
    extendOriginal: null,
    tempStart: null,
    tempEnd: "",
    dragTime: null,
    doctorSelector: [],
    oneUser: [],
    locationFill: null,
    providerFill: null,
  }),
  watch: {},
  computed: {
    iconName() {
      return this.loading ? "mdi-loading" : "mdi-magnify";
    },
    formError() {
      if (!this.service) {
        return "Prompt: Select a service!";
      }
      if (!this.timeValidation) {
        return "Prompt: Time validation failed!";
      }
      if (this.hasError == null) {
        return "Prompt: An error has occurred!";
      }
      if (!this.tempStart) {
        return "Prompt: Select a time slot!";
      }
      return null;
    },
    timeValidation() {
      if (this.start && this.end) {
        const startTimeValid = this.validateTime(this.start);
        const endTimeValid = this.validateTime(this.end);
        if (startTimeValid === false || endTimeValid === false) {
          return "Error: Time not within range";
        } else if (startTimeValid > endTimeValid) {
          return "Error: End time is before start time";
        } else {
          return "Ok";
        }
      }
      return "Please enter both start and end times";
    },
  },
  methods: {
    async handleSubmit() {
    
    this.loading = true; 
    this.loading = false;
  },
    closeEditUserInfo(eventId) {
      this.getUserInfo(eventId);
    },
    captureSnapshot() {
      this.$parent.childStateSnapshot = JSON.parse(JSON.stringify(this.$data));
    },
    validateDate() {
      const offset = new Date().getTimezoneOffset() * 60000;
      const todayLocal = new Date(Date.now() - offset).setHours(0, 0, 0, 0);
      let selectedDate = new Date(this.DateToCreateNewEvent).setHours(
        0,
        0,
        0,
        0
      );

      this.menu = false;
      if (selectedDate < todayLocal) {
        this.isPastDate = true;
        this.DateToCreateNewEvent = this.formatDate(todayLocal);
        this.today = this.DateToCreateNewEvent;
        this.$refs.datePicker.input(this.DateToCreateNewEvent);
      } else {
        this.isPastDate = false;
        this.today = this.DateToCreateNewEvent;
        this.getDayEvent();
      }
    },
    formatDate(date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    noop() { },

    onLocationSelected(data) {
      this.locationId = data.locationId;
      this.location = data;
      this.providerId = null;
    },
    onProviderSelected(data) {
      this.providerId = data.providerId;
      this.doctorSelector = data;
      this.categoryName = data.providerName;
    },
    onServiceSelected(data) {
      this.service = data;
      this.getTimeLengthDifference(data);
    },

    validateTime(timeString) {
      const parts = timeString.split(":");
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      if ((hours >= 7 && hours < 23) || (hours === 23 && minutes === 0)) {
        return hours * 60 + minutes;
      } else {
        return false;
      }
    },
    getErrorMessage(err) {
      if (err != null) {
        this.hasError = true;
      }
    },
    updateDate(value) {
      this.dateOnly = value;
    },
    async getAddedId(value) {
      this.addNewUser = false;
      this.getUserInfo(value);
    },
    addedNewPatient(event) {
      this.getUserInfo(event);
      this.addNewUser = false;
    },
    async searchUserFn() {
      if (this.loading) return;
      this.loading = true;
      this.userSuggestionButtonList = [];
      try {
        const response = await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/searchUser",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: { input: this.searchUser },
        });
        for (const doc of response.data) {
          let appData = doc;
          appData.id = doc.UserId;
          this.userSuggestionButtonList.push(appData);
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
      if (this.userSuggestionButtonList.length < 1) {
        this.suggestedUser = "No Result!";
      } else {
        this.suggestedUser = null;
      }
      if (this.searchUser.length === 0) {
        this.userSuggestionButtonList = [];
      }
    },
    cancelCreateEvent() {
      this.$emit("cancelEvent");
    },
    async getUserInfo(event) {
      this.locationFill = null;
      this.providerFill = null;
      let user = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getOneUser",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { userId: event },
      })
        .then((response) => {
          let appData = response.data;
          user = appData;
        })
        .catch((error) => console.log(error));
      this.oneUser = user;
      this.refreshCounter++;
      this.UserId = user.UserId;
      if (user.firstVisit == true) {
        this.action_with_doctor = this.action_with_doctor_dropdown[0];
      } else {
        this.action_with_doctor = this.action_with_doctor_dropdown[1];
      }
      if (this.oneUser.basicInfo.defaultLocation) {
        this.locationFill = this.oneUser.basicInfo.defaultLocation.locationId;
      }
      if (
        this.oneUser.basicInfo.defaultLocation &&
        this.oneUser.basicInfo.defaultProvider
      ) {
        this.providerFill = this.oneUser.basicInfo.defaultProvider.providerId;
      }

      this.checkPhone = this.oneUser.defaultPhoneNumber
      this.checkEmail = this.oneUser.defaultEmail

      this.searchUser = null;
      this.userSuggestionButtonList = [];
    },

    async addEvent() {
      if (this.oneUser) {
        await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/createNewEvent",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: {
            perVisitDetails: this.perVisitDetails,
            doctor: this.doctorSelector,
            location: this.location,
            service: this.service,
            action_with_doctor: this.action_with_doctor,
            start: this.tempStart,
            end: this.tempEnd,
            color: this.color,
            first_name: this.oneUser.basicInfo.first_name,
            name:
              this.oneUser.basicInfo.first_name +
              " " +
              this.oneUser.basicInfo.preferred_name +
              " " +
              this.oneUser.basicInfo.last_name,
            preferred_name: this.oneUser.basicInfo.preferred_name,
            last_name: this.oneUser.basicInfo.last_name,
            UserId: this.oneUser.UserId,
            calEventStart: this.tempStart,
            calEventEnd: this.tempEnd,
            category: this.categoryName,
          },
        })
          .catch((error) => console.log(error));
        //send email

        // if (this.emailEnable == true) {
        //   await this.sendEmail()
        // }
        // if (this.sendEmailToProviderEnable == true) {
        //   await this.sendEmailToProvider()
        // }
        if (this.phoneEnable == true) {
          let name = this.oneUser.basicInfo.last_name
          let date = this.$moment(this.tempStart).format('YYYY-MM-DD')
          let startTime = this.$moment(this.tempStart).format('HH:mm')
          let endTime = this.$moment(this.tempEnd).format('HH:mm')
          await this.$axios({
            method: "post",
            url: this.$axios.defaults.baseURL + "/api/twilioTextMessage",
            headers: { Authorization: localStorage.getItem("accessToken") },
            data: {
              phoneNumber: this.checkPhone,
              start: startTime,
              name: name,
              date: date,
              end: endTime,
            }
          }).then(() => {
            alert('Reminder Sent!')
          })
            .catch((error) => console.log(error));
        }

        if (this.location) {
          localStorage.setItem("lOp", JSON.stringify(this.location));
        }


        this.$refs.newEventDialog = false;
        this.tempStart = null;
        this.tempEnd = "";
        this.first_name = "";
        this.preferred_name = "";
        this.name = "";
        this.last_name = "";
        this.email = "";
        this.phone = "";
        this.service = "";
        this.action_with_doctor = "";
        this.doctor = "";
        this.doctorSelector = null;
        this.perVisitDetails = null;
        this.color = "#1976D2";
        this.SelectedUserId = null;
        this.$emit("closePage", false);
      } else {
        alert("Name and dates are required! ");
      }
      try {
        const storedData = localStorage.getItem("dwp");
        if (storedData) {
          let receivedDataDWP = JSON.parse(storedData);
          receivedDataDWP.selectedDate = this.DateToCreateNewEvent;
          localStorage.setItem("dwp", JSON.stringify(receivedDataDWP));
        }
      } catch (error) {
        console.error("Error accessing localStorage:", error);
      }
      if (this.$route.path !== "/day") {
        this.$router.push("/day");
      } else {
        location.reload();
      }
    },
    getTimeLengthDifference(time) {
      this.getDayEvent();
      let temp = time.milliseconds / 60000;

      if (temp === 5) {
        return "5 mins";
      } else if (temp === 10) {
        return "10 mins";
      } else if (temp === 15) {
        return "15 mins";
      } else if (temp === 20) {
        return "20 mins";
      } else if (temp === 25) {
        return "25 mins";
      } else if (temp === 30) {
        return "30 mins";
      } else if (temp === 35) {
        return "35 mins";
      } else if (temp === 40) {
        return "40 mins";
      } else if (temp === 45) {
        return "45 mins";
      } else if (temp === 50) {
        return "50 mins";
      } else if (temp === 55) {
        return "55 mins";
      } else if (temp === 60) {
        return "1 hr";
      } else if (temp === 65) {
        return "1 hr 5 mins";
      } else if (temp === 70) {
        return "1 hr 10 mins";
      } else if (temp === 75) {
        return "1 hr 15 mins";
      } else if (temp === 80) {
        return "1 hr 20 mins";
      } else if (temp === 85) {
        return "1 hr 25 mins";
      } else if (temp === 90) {
        return "1 hr 30 mins";
      } else if (temp === 95) {
        return "1 hr 35 mins";
      } else if (temp === 100) {
        return "1 hr 40 mins";
      } else if (temp === 105) {
        return "1 hr 45 mins";
      } else if (temp === 110) {
        return "1 hr 50 mins";
      } else if (temp === 115) {
        return "1 hr 55 mins";
      } else if (temp === 120) {
        return "2 hrs";
      }
      this.timeSlotLengthToDisplay = temp;
      this.addTimeAndConvertBack(time.milliseconds);
    },
    addTimeAndConvertBack(time) {
      this.end = this.start;
      const parts = this.start.split(":");
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      let inputTimeInMs = (hours * 60 + minutes) * 60 * 1000;
      let newTimeInMs = inputTimeInMs + parseInt(time);
      let date = new Date(newTimeInMs);
      let newHours = date.getUTCHours();
      let newMinutes = date.getUTCMinutes();
      newHours = newHours < 10 ? "0" + newHours : newHours;
      newMinutes = newMinutes < 10 ? "0" + newMinutes : newMinutes;
      this.end = `${newHours}:${newMinutes}`;
    },
    startDrag({ event, timed }) {
      if (event && timed && !event.fromDatabase) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    // async sendEmail() {
    //   if (this.location && this.oneUser) {
    //     let name = this.oneUser.basicInfo.last_name
    //     let date = this.$moment(this.tempStart).format('YYYY-MM-DD')
    //     let startTime = this.$moment(this.tempStart).format('HH:mm')
    //     let endTime = this.$moment(this.tempEnd).format('HH:mm')
    //     await this.$axios({
    //       method: "post",
    //       url: this.$axios.defaults.baseURL + "/api/sendEmail",
    //       headers: { Authorization: localStorage.getItem("accessToken") },
    //       data: {
    //         email: this.checkEmail,
    //         start: startTime,
    //         patientLastName: name,
    //         date: date,
    //         end: endTime,
    //         location: this.location
    //       },
    //     })
    //       .catch((error) => console.log(error));
    //   }
    // },
    // async sendEmailToProvider() {
    //   if (this.doctorSelector && this.location && this.oneUser) {
    //     let provider = this.doctorSelector
    //     let name = this.oneUser.basicInfo.last_name
    //     let date = this.$moment(this.tempStart).format('YYYY-MM-DD')
    //     let startTime = this.$moment(this.tempStart).format('HH:mm')
    //     let endTime = this.$moment(this.tempEnd).format('HH:mm')
    //     await this.$axios({
    //       method: "post",
    //       url: this.$axios.defaults.baseURL + "/api/sendEmail/doc",
    //       headers: { Authorization: localStorage.getItem("accessToken") },
    //       data: {
    //         email: provider.providerEmail,
    //         start: startTime,
    //         providerLastName: provider.providerName,
    //         patientLastName: name,
    //         date: date,
    //         end: endTime,
    //         location: this.location
    //       },
    //     })
    //       .catch((error) => console.log(error));

    //   }
    // },
    async startTimeDayCategory(tms) {
      if (!this.service) {
        return;
      }
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime === null) {
        const start = this.dragEvent.start;
        this.dragTime = mouse - start;
      } else {
        this.events = this.events.filter(
          (item) => !Object.prototype.hasOwnProperty.call(item, "temp")
        );
        this.createStart = this.roundTime(mouse);
        this.provider = tms.category;
        let tempEnd = this.createStart + parseInt(this.service.milliseconds);
        this.createEvent = {
          temp: true,
          name: this.service.serviceName,
          color: "#66ccff",
          start: this.createStart,
          end: tempEnd,
          timed: true,
          location: "",
          category: tms.category,
          new: true,
        };
        this.events.push(this.createEvent);
      }
    },
    extendBottom(event) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    mouseMove(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    endDrag() {
      if (this.createEvent) {
        this.tempStart = this.createEvent.start;
        this.tempEnd = this.createEvent.end;
      }
      if (this.dragEvent) {
        this.tempStart = this.dragEvent.start;
        this.tempEnd = this.dragEvent.end;
      }
      this.dragTime = null;
      this.dragEvent = null;
      this.createEvent = null;
      this.createStart = null;
      this.extendOriginal = null;
    },
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      this.createEvent = null;
      this.createStart = null;
      this.dragTime = null;
      this.dragEvent = null;
    },
    roundTime(time, down = true) {
      const roundTo = 15;
      const roundDownTime = roundTo * 60 * 1000;
      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    toTime(tms) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    changeCalendarView() {
      this.$refs.calendar.scrollTo(this.selectedDate);
    },


    async getDayEvent() {
      let events = []
      if (this.providerId && this.locationId) {
        this.events = [];
        await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/getEventsForDayCalendarAdvanced",
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          data: {
            providerIdList: [this.doctorSelector],
            locationId: this.locationId,
            date: this.DateToCreateNewEvent,
          },
        }).then((response) => {
          response.data.forEach((doc) => {
            if (doc.offTime == true) {
              doc.start = (this.$moment(doc.start).tz('UTC').format('YYYY-MM-DD HH:mm:ss'))
              doc.end = (this.$moment(doc.end).tz('UTC').format('YYYY-MM-DD HH:mm:ss'))

            }
            events.push(doc)
          });
        })
          .catch((error) => console.log(error));
        this.events = events;
      }
    },




    async mouseMoveTimeCategory(tms) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime) {
        if (tms.category) {
          this.dragEvent.category = tms.category;
        }
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        this.createEvent.start = min;
        this.createEvent.end = max;
        let startNew = new Date(this.createEvent.start - this.$tzOffset);
        this.createEventStart =
          startNew.toISOString().slice(0, -1).split(":")[0] +
          ":" +
          startNew.toISOString().slice(0, -1).split(":")[1];
        this.createEvent.start = this.createEventStart;
        let endNew = new Date(this.createEvent.end - this.$tzOffset);
        this.createEventEnd =
          endNew.toISOString().slice(0, -1).split(":")[0] +
          ":" +
          endNew.toISOString().slice(0, -1).split(":")[1];
        this.createEvent.end = this.createEventEnd;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                        supported by Chrome, Opera and Firefox */
}

.my-custom-button {
  color: #44c0e9 !important;
  /* Light blue text color */
  border: 2px solid #44c0e9 !important;
  /* Thicker light blue border */
  box-shadow: none !important;
  margin-right: 3px;
  /* No drop shadow */
}

.row-bottom {
  align-self: flex-end;
}

.multiline {
  white-space: pre-wrap;
  width: 400px;
}

.inlineblocking {
  display: inline-block;
}

.borderedLine {
  border-style: solid;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border: 2px solid;
  border-radius: 5px;
  min-height: 10vh;
}

.but {
  display: inline-block;
}
</style>
