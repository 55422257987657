<template>
  <v-container>
    <div>
      <v-row>
        <v-col sm="9">
          <v-text-field
            v-model="newAddress.AddressLine1"
            label="Address Line 1"
          ></v-text-field>
          <v-text-field
            v-model="newAddress.AddressLine2"
            label="Address Line 2"
          ></v-text-field>
          <v-text-field v-model="newAddress.City" label="City"></v-text-field>
          <v-text-field
            v-model="newAddress.Province"
            label="Province"
          ></v-text-field>
          <v-text-field
            v-model="newAddress.PostalCode"
            label="Postal Code"
          ></v-text-field>
          <v-text-field
            v-model="newAddress.Country"
            label="Country"
          ></v-text-field>
        </v-col>
        <v-col sm="1">
          <v-btn icon color="green" @click="confirmAddAddress"
            ><v-icon>mdi-tag-plus</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-list>
        <v-list-item v-for="(address, index) in sortedAddresses" :key="index">
          <v-list-item-content>
            <div
              :class="{
                'default-address': address.default,
                address: !address.default,
              }"
            >
              <p>
                <b>Address: </b> {{ address.AddressLine1 }}
                {{ address.AddressLine2 }} {{ address.City }}
                {{ address.Province }}
                {{ address.PostalCode }}
                {{ address.Country }}
              </p>
            </div>
          </v-list-item-content>
          <v-list-item-action v-if="address.status">
            <v-row>
              <v-btn icon color="green" @click="makeDefault(address)"
                ><v-icon>{{
                  address.default
                    ? "mdi-checkbox-marked-circle-outline"
                    : "mdi-checkbox-blank-circle-outline"
                }}</v-icon></v-btn
              >
              <v-btn icon color="red" @click="promptDeleteAddress(address)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-dialog v-model="showDeleteDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline"
            >Confirmation <v-spacer />
            <v-btn icon @click="showDeleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-card-title
          >

          <v-card-text>{{ deleteDialogMessage }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isDefault"
              color="red darken-1"
              text
              @click="showDeleteDialog = false"
              >No</v-btn
            >
            <v-btn
              v-if="!isDefault"
              color="red darken-1"
              text
              @click="confirmDeleteAddress"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
export default {
  name: "patientAddressHistory",
  props: ["userId"],
  computed: {
    sortedAddresses() {
      return this.addresses.slice().sort((a, b) => b.default - a.default);
    },
  },
  data: () => ({
    showDeleteDialog: false,
    addressToBeDeleted: null,
    deleteDialogMessage: "",
    newAddress: {
      AddressLine1: "",
      AddressLine2: "",
      City: "",
      Province: "",
      PostalCode: "",
      Country: "",
    },
    addresses: [],
    isDefault: false,
  }),
  async mounted() {
    this.getAddresses();
  },
  methods: {
    async getAddresses() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalAddress/get",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
        },
      })
        .then((response) => {
          if (response.data.addresses) {
            this.addresses = response.data.addresses;
          }
        })
        .catch((error) => console.log(error));
    },
    async addAddress() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalAddress/add",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
          address: this.newAddress,
        },
      })
        .then(() => {
          this.getAddresses();
        })
        .catch((error) => console.log(error));
      this.newAddress = {
        AddressLine1: "",
        AddressLine2: "",
        City: "",
        Province: "",
        PostalCode: "",
        Country: "",
      };
    },
    confirmAddAddress() {
      this.addAddress();
    },
    async deleteAddress(address) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalAddress/delete",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
          addressId: address.id,
        },
      })
        .then(() => {
          this.getAddresses();
        })
        .catch((error) => console.log(error));
    },
    promptDeleteAddress(address) {
      if (address.default) {
        this.deleteDialogMessage =
          "This address is currently set as the default. It cannot be deleted.";
        this.isDefault = true;
      } else {
        this.deleteDialogMessage =
          "Are you sure you want to delete this address?";
        this.isDefault = false;
        this.addressToBeDeleted = address;
      }
      this.showDeleteDialog = true;
    },
    confirmDeleteAddress() {
      if (this.addressToBeDeleted) {
        this.deleteAddress(this.addressToBeDeleted);
        this.addressToBeDeleted = null;
      }
      this.showDeleteDialog = false;
    },
    async makeDefault(address) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalAddress/setDefault",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
          address: address.id,
        },
      })
        .then(() => {
          this.getAddresses();
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style scoped>
.default-address {
  color: green !important;
}
</style>
