<template>
  <div>
    <v-tabs background-color="white" centered grow v-model="currentTab">
      <v-tab>All Products</v-tab>
      <v-tab>Stock History</v-tab>

      <v-tab-item key="products">
        <!-- Add a search text field here -->
        <v-card>
          <v-card-title>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-data-table :headers="headerForProduct" :items="filteredProducts" :sort-by="['idNumber']"
            :sort-desc="[true]" class="elevation-1">
            <template v-slot:top>
              <v-toolbar flat color="white">
                <v-btn color="#818cf8" @click="addNewProductPage = true">Add New Product</v-btn>
                <v-spacer></v-spacer>
                <v-text-field v-model="productSearchTerm" append-icon="mdi-magnify" label="Search" single-line
                  hide-details></v-text-field>
              </v-toolbar>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editProductName(item)"> mdi-pencil </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-tab-item>
      <v-tab-item key="dates">
        <v-data-table :headers="headerForStock" :items="productsDateObj" :sort-by="['purchase_date']"
          :sort-desc="[true]" class="elevation-1">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-btn color="#818cf8" @click="addNewRecordPage">Add New Stock</v-btn>
              <v-btn color="#818cf8" @click="addNewRecordPageWithAI">Add New Stock With AI</v-btn>
            </v-toolbar>

          </template>
          <template v-slot:[`item.stock`]="{ item }">
            {{ item.leftoverStock }}/{{ item.totalPurchaseAmount }}
          </template>
        </v-data-table>
      </v-tab-item>

    </v-tabs>
    <br />

    <!-- add new Product -->
    <v-dialog v-model="addNewProductPage" width="800px" :retain-focus="false" persistent>
      <v-card>
        <v-card-title class="text-center">Add New
          <v-spacer></v-spacer>
          <v-btn icon @click="addNewProductPage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <!-- Other Fields Show Only After Selection or Manual Addition -->
          <div>
            <v-text-field v-model="newRecord.name_ch" label="Name in Chinese" outlined></v-text-field>
            <v-text-field v-model="newRecord.name_en" label="Name in English" outlined></v-text-field>
            <v-text-field v-model="newRecord.name_la" label="Name in Latin" outlined></v-text-field>

            <v-text-field v-model="newRecord.mult" label="Multiplier" type="number" outlined></v-text-field>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="addSingleProduct">Add Product</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- add new Stock page -->
    <v-dialog v-model="addNewRecord" width="800px" :retain-focus="false" persistent>
      <v-card>
        <v-card-title class="text-center">Add New
          <v-spacer></v-spacer>
          <v-btn icon @click="addNewRecord = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-alert type="error" v-if="errorMessage" dense dismissible @click="errorMessage = ''">
          {{ errorMessage }}
        </v-alert>
        <v-card-text>
          <div class="d-flex justify-center">
            <v-autocomplete v-model="selectedProduct" label="Search Product" outlined auto-select-first
              append-outer-icon="mdi-magnify" :items="autocompleteOptions" :item-text="getAutocompleteItemText"
              :item-value="getAutocompleteItemValue" @update:search-input="fetchAutocompleteOptions"
              @change="updateNewRecordNameCh" return-object class="my-4" solo flat></v-autocomplete>
          </div>
          <div>
            <v-row>
              <v-col>
                <p>Chinese Name: {{ newRecord.name_ch }}</p>
              </v-col>
              <v-col>
                <p>English Name: {{ newRecord.name_en }}</p>
              </v-col>
              <v-col>
                <p>Latin Name: {{ newRecord.name_la }}</p>
              </v-col>
            </v-row>

            <!-- <v-text-field v-model="newRecord.mult" label="Sell Price Multiplier (%)" type="number"
              outlined @input="calculateSellPrice('new')"></v-text-field>
            <v-text-field v-model="newRecord.calculated_sell_price" label="Calculated Sell Price ($)" outlined
              readonly></v-text-field> -->
            <v-menu ref="menu" v-model="menu" :close-on-content-click="false" :nudge-right="40"
              transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="newRecord.purchase_date" label="Purchase Date" outlined readonly
                  append-icon="mdi-calendar" v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="newRecord.purchase_date" @input="menu = false"></v-date-picker>
            </v-menu>
            <v-row>
              <v-col>
                <v-text-field v-model="newRecord.price" label="Price ($)" outlined type="number"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="newRecord.purchase_amount" label="Purchase Amount (g)" outlined
                  type="number"></v-text-field>
              </v-col>
              <v-col>
                <v-text-field v-model="newRecord.lotNumber" label="Lot #" outlined></v-text-field>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="addSingleRecord">Add Product</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addNewRecordWithAI" width="800px" persistent>
  <v-card>
    <v-card-title class="text-center">
      Add New
      <v-spacer></v-spacer>
      <v-btn icon @click="addNewRecordWithAI = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text class="d-flex flex-column justify-space-between fill-height">
      <!-- Drag and Drop Box -->
      <div 
        @dragover.prevent="dragOver" 
        @drop.prevent="handleDrop"
        @dragenter.prevent="dragEnter" 
        @dragleave.prevent="dragLeave"
        :class="{'drag-over': isDragOver, 'disabled': isLoading}"
        class="drag-drop-box flex-grow-1">
        Drag files here or click to upload
        <v-progress-circular
          v-if="isLoading"
          indeterminate
          color="primary">
        </v-progress-circular>
      </div>
      <input type="file" ref="fileInput" style="display: none;" @change="handleFiles" accept=".pdf, .xlsx, .xls"/>
      <v-btn :disabled="isLoading" color="primary" @click="triggerFileInput" class="mt-3">Upload File</v-btn>
      <!-- Optionally display the data -->
      <div v-if="displayFileData.length > 0" class="mt-3">
        {{ displayFileData }}
      </div>
    </v-card-text>
  </v-card>
</v-dialog>


    <!-- edit page -->
    <v-dialog v-model="editProductDialog" width="800px" @keydown.esc="reloadPage" @click:outside="reloadPage">
      <v-card>
        <v-card-title>Edit Product
          <v-spacer />
          <v-btn icon @click="editProductDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="productNamesEdit.name_ch" label="Name in Chinese" outlined></v-text-field>
          <v-text-field v-model="productNamesEdit.name_en" label="Name in English" outlined></v-text-field>
          <v-text-field v-model="productNamesEdit.name_la" label="Name in Latin" outlined></v-text-field>

          <v-text-field v-model="productNamesEdit.mult" label="Multiplier" type="number" outlined></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="updateProductNameOnly">Save Update</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>
<style scoped>
.search {
  margin-top: 3%;
}
</style>
<script>
export default {
  name: "allProductsPage",
  mounted() {
    const storedOption = localStorage.getItem("lOp");
    if (storedOption) {
      this.location = JSON.parse(storedOption);
      this.locationId = this.location.locationId;
    }
    this.getListByDate()
    this.getListByProductType()
  },
  computed: {
    filteredProducts() {
      const search = this.productSearchTerm.toLowerCase();
      return this.productsObj.filter(product =>
        Object.values(product).some(value =>
          value && value.toString().toLowerCase().includes(search)
        )
      );
    },
    getAutocompleteItemText() {
      return (item) => {
        let displayName = item.name_ch;
        if (item.name_pinyin) {
          displayName += ' (' + item.name_pinyin + ')';
        }
        return displayName + ' ' + item.name_en + ' ' + item.name_la;
      };
    },
    getAutocompleteItemValue() {
      return (item) => item.id;
    },
  },

  data: () => ({
    autocompleteOptions: [], // Array for autocomplete suggestions
    selectedProduct: null,
    productSearchTerm: "", // New data property for search term 
    addNewProductPage: false,
    editProductDialog: false,
    isDragOver: false,
    searchResults: [],
    isLoading:false,
    locationId: '',
    search: '',
    addNewRecordWithAI: false,
      displayFileData: [],
      errorMessage: '',
    addNewRecord: false, 
    currentTab: 0,
    menu: false,  
    menu1: false,
    productNamesEdit: {
      name_ch: '',
      name_en: '',
      name_la: '',
    },
    editRecord: {
      product_name: '',
      price: '',
      purchase_date: '',
      purchase_amount: '',
      stock: '',
      lotNumber: '',
      mult: '',
    },
    newRecord: {
      mult: 0,
      name_ch: "",
      name_en: "",
      name_la: "",
      price: '',
      purchase_date: '',
      purchase_amount: '',
      stock: '',
      lotNumber: '',
    },
    headerForProduct: [
      { text: "English", align: "start", value: "name_en" },
      { text: "Chinese", align: "start", value: "name_ch" },
      { text: "Latin", align: "start", value: "name_la" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    headerForStock: [
      { text: "English", align: "start", value: "name_en" },
      { text: "Chinese", align: "start", value: "name_ch" },
      { text: "Latin", align: "start", value: "name_la" },
      { text: "Last Purchase Date", align: "start", value: "purchase_date" },
      { text: "Stock (g)", value: "stock", sortable: false },
      { text: "Last Lot #", align: "start", value: "latestLotNumber" },
    ],




    productsObj: [],
    productsDateObj: []
  }),

  methods: {
    //get start
    async getListByProductType() {
      this.productsObj = []
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/products/getListByProductType",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        data: {
          locationId: this.locationId
        },
      })
        .then((response) => {
          this.productsObj = response.data;
        })
        .catch((error) => console.log(error));
    },

    async getListByDate() {
      this.productsDateObj = []; // Initialize or clear existing data
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/products/getListByRecord",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        data: {
          locationId: this.locationId
        },
      })
        .then((response) => {
          this.productsDateObj = response.data; // Use processed data directly
        })
        .catch((error) => console.log(error));
    },

    async fetchAutocompleteOptions(input) {
      if (!input || input.trim().length < 1) {
        this.autocompleteOptions = [];
        return;
      }
      try {
        const response = await this.$axios.post("/api/products/searchProducts", {
          searchText: input,
        });
        // Make sure the response includes name_pinyin where applicable
        this.autocompleteOptions = response.data;
      } catch (error) {
        console.error("Error fetching autocomplete options:", error);
      }
    },
  


    addNewRecordPageWithAI() {
      this.newRecord = {
        mult: 0,
      }
      this.addNewRecordWithAI = true;
    },

    addNewRecordPage() {
      this.newRecord = {
        mult: 0,
      }
      this.addNewRecord = true;
    },

    async addSingleProduct() {

      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/products/addSingleProduct",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        data: {
          newRecord: this.newRecord,
        },
      })
        .then(() => {
          this.getListByDate();
          this.getListByProductType()
          this.addNewProductPage = false;
          this.newRecord = {
            name_ch: "",
            name_en: "",
            name_la: "",
          }
        })
        .catch((error) => console.log(error));
    },
    async addSingleRecord() {
      const hasObjectID = !!this.newRecord.id;
      const hasAtLeastOneName = this.newRecord.name_ch || this.newRecord.name_en || this.newRecord.name_la;
      // Ensure all required fields are filled
      const areRequiredFieldsFilled = this.newRecord.price && this.newRecord.purchase_date && this.newRecord.purchase_amount && this.newRecord.lotNumber && hasObjectID && hasAtLeastOneName;
      if (!areRequiredFieldsFilled) {
        this.errorMessage = "Please fill in all required fields before proceeding.";
        return; // Stop if validation fails
      }
      // Add a Unix timestamp for `createdAt`
      const purchaseDate = new Date();
      // Convert the date to Unix time (seconds since the Unix epoch)
      const purchaseUnixTime = Math.floor(purchaseDate.getTime() / 1000);

      this.newRecord.createdAt = purchaseUnixTime; // Adds the current Unix time

      // Proceed with the record addition if validation passes
      try {
        await this.$axios({
          method: "post",
          url: `${this.$axios.defaults.baseURL}/api/products/addSingleRecord`,
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          data: {
            locationId: this.locationId,
            newRecord: this.newRecord,
          },
        });

        // Handle success
        this.getListByDate();
        this.getListByProductType();
        this.addNewRecord = false;
        this.addNewRecordWithAI = false
        this.newRecord = {}; // Resetting the new record
        this.errorMessage = ""; // Clear any existing error message
      } catch (error) {
        console.log(error);
        this.errorMessage = "An error occurred while adding the record. Please try again.";
      }
    },


    async updateProductNameOnly() {

      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/products/updateProductNameOnly",
        headers: {
          Authorization: localStorage.getItem("accessToken"),
        },
        data: {
          locationId: this.locationId,
          editRecord: this.productNamesEdit,
        },
      })
        .then(() => {
          this.getListByDate();
          this.getListByProductType()
          this.editProductDialog = false
          this.productNamesEdit = {
            name_ch: '',
            name_en: '',
            name_la: '',
            name_pinyin: '',
          }
        })
        .catch((error) => console.log(error));
    },

    reloadPage() {

      this.editProductDialog = false
    },


    async uploadFileToBackend(files) {
    this.isLoading = true; // Set loading state
    const formData = new FormData();
    formData.append('file', files[0]); // Assume only one file is needed.
    try {
      const response = await this.$axios.post('/api/productsAI/uploadFile', formData, {
        headers: {
          'Authorization': localStorage.getItem('accessToken'),
          'Content-Type': 'multipart/form-data'
        },
      });
      this.displayFileData = response.data.choices[0].message.content;
      console.log("this is data",response.data.choices[0].message.content);
    } catch (error) {
      console.error('Error uploading file:', error);
      this.errorMessage = "An error occurred while uploading the file. Please try again.";
    }
    this.isLoading = false; // Reset loading state
  },


    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    dragOver(event) {
      event.preventDefault();
    },
    dragEnter(event) {
      event.preventDefault();
      this.isDragOver = true;  // When a file enters the drop zone
    },
    dragLeave(event) {
      event.preventDefault();
      this.isDragOver = false; // When a file leaves the drop zone
    },
    handleDrop(event) {
      event.preventDefault();
      this.isDragOver = false; // Reset on drop
      const files = event.dataTransfer.files;
      this.uploadFileToBackend(files);
    },
    handleFiles(event) {
      const files = event.target.files;
      this.uploadFileToBackend(files);
    },

    updateNewRecordNameCh() {
      if (this.selectedProduct) {
        this.newRecord = this.selectedProduct
      }
    },
    customSearch(value, search, item) {
      return Object.values(item).some(
        (v) => v && v.toString().toLowerCase().includes(search)
      );
    },
  },
};
</script>

<style scoped>
.drag-drop-box {
  flex-grow: 1;
  min-height: 200px;
  border: 2px dashed grey;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin: 10px 0;
}

.drag-over {
  background-color: lightblue;  /* Visual feedback for dragging over */
  border-color: blue;
}

.disabled {
  opacity: 0.5; /* Lower opacity when loading */
  pointer-events: none; /* Disable interactions */
}

</style>
