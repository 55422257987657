<template>
  <div v-if="displayIfReschedule">
    the original time is: {{ displayIfReschedule }}
  </div>
</template>
<script>
export default {
  name: "getRescheduleTime",
  props: {
    eventId: {
      eventId: String,
    },
  },
  data: () => ({
    displayIfReschedule:null,
  }),
  async mounted() {
     this.getRescheduleTime()
  },
  methods: {
    async getRescheduleTime(){
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getRescheduleTime",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          eventId: this.eventId,
        },
      })
        .then((doc) => {
          this.displayIfReschedule = new Date(doc.data[0].beforeStart - this.$tzOffset)
            .toISOString() 
        })
        .catch((error) => console.log(error));
    }
  },
};
</script>
