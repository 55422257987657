<template>
  <div>
    <v-data-table
      :headers="docLocationListHeader"
      :items="individualDocLocationListItem"
      :items-per-page="5"
      class="elevation-1"
    >
    </v-data-table>
    <v-dialog v-model="addNewDocLocation" max-width="450">
      <v-toolbar flat color="primary" dark>
        <v-toolbar-title>Add Location</v-toolbar-title>
        <v-spacer />
        <v-btn icon @click="addNewDocLocation = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card>
        <v-container>
          <v-form @submit.prevent class="container-outline">
            <v-select
              return-object
              v-model="locationListDropdown"
              :items="location_name_dropdown"
              label="Location"
              item-text="companyName"
              dense
              outlined
            >
            </v-select>
            <v-btn
              type="submit"
              color="primary"
              class="mb-4"
              @click.stop="
                (addNewDocLocation = false), addDocLocationList(providerId)
              "
              >Add</v-btn
            >
          </v-form>
        </v-container>
      </v-card>
    </v-dialog>
  </div>
</template>
<style scoped>
.margintop {
  margin-top: 10px;
}
</style>
<script>
export default {
  name: "ProvidersLocation",
  props: ["providerId"],
  data: () => ({
    docLocationListHeader: [
      {
        text: "Location Name",
        align: "start",
        value: "location.companyName",
      },
    ],
    individualDocLocationListItem: [],
    addNewDocLocation: false,
    locationListDropdown: null,
    location_name_dropdown: [],
    location: null,
  }),
  async mounted() {
    this.getDocLocationList();
    this.updateLocationList();
  },
  methods: {
    async getDocLocationList() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getDocLocationList",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          docId: this.providerId,
        },
      })
        .then((doc) => {
          this.individualDocLocationListItem = doc.data;
        })
        .catch((error) => console.log(error));
    },
    async addDocLocationList(docId) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/addDocLocationList",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          docId: docId,
          location: this.locationListDropdown,
        },
      })
        .then(() => {
          this.getDocLocationList();
        })
        .catch((error) => console.log(error));
    },
    async deleteLocation(item) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/deleteDocLocationList",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          docId: this.providerId,
          locationId: item.id,
        },
      })
        .then(() => {
          this.getDocLocationList();
        })
        .catch((error) => console.log(error));
    },
    async updateLocationList() {
      await this.$getLocationsList();
      this.location_name_dropdown = this.$LocationsListObj;
    },
  },
};
</script>
