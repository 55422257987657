<template>
  <v-app>
    <!-- bar on top of the app -->
    <v-sheet height="64" v-if="isAuthenticated && showToolbar">
      <v-toolbar flat>
        <v-btn color="primary" class="mr-4" @click="newEventDialog = true">New Appointment</v-btn>
        <v-btn fab text small color="grey darken-2" @click="prevDay">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="nextDay">
          <v-icon small> mdi-chevron-right</v-icon>
        </v-btn>

        <v-btn outlined class="mr-4" color="grey darken-2" @click="TodayButton">
          Today
        </v-btn>

        <div class="highlight-text">{{ displayedMonth }}</div>

        <v-toolbar-title v-if="$refs.calendar">
          {{ $refs.calendar.title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>


        <v-menu class="mr-4" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              Options
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="(allBalancePage = true), allBalancePageCounter++">
              <v-list-item-title>Balance</v-list-item-title>
            </v-list-item>
            <v-list-item @click="(allInvoicesPage = true), allInvoicesPageCounter++">
              <v-list-item-title>Invoices</v-list-item-title>
            </v-list-item>
            <v-list-item @click="(allPatientsListPage = true), allPatientsPageCounter++">
              <v-list-item-title>Patients</v-list-item-title>
            </v-list-item>
            <v-list-item @click="(allProductsListPage = true), allProductsPageCounter++">
              <v-list-item-title>Products</v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>Analysis</v-list-item-title>
            </v-list-item>
 
          </v-list>
        </v-menu>



        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined color="grey darken-2" v-bind="attrs" v-on="on">
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item href="/day">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>

            <v-list-item href="/month">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>



        <v-btn outlined class="mr-4" color="grey darken-2"
          @click="(settingsPageToggle = true), settingsPageCounter++"><v-icon>mdi-cog</v-icon></v-btn>



      </v-toolbar>
    </v-sheet>

    <!-- new appointment -->
    <v-dialog v-model="newEventDialog" scrollable max-width="1000px" :retain-focus="false" persistent>
      <v-card>
        <v-card-title>New Event
          <v-spacer />
          <v-btn icon @click="newEventDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <newEventPage @closePage="newEventDialog = $event" :key="newEventPageCounter" :providerfill="this.provider"
            :startfill="this.createEventstart" :endfill="this.createEventend" @cancelEvent="cancelCreateEvent"
            :initial-state="childStateSnapshot" ref="newEventPage" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- list of all Invoices -->
    <v-dialog v-model="allInvoicesPage" scrollable max-width="1000px">
      <v-card>
        <v-card-title>All Invoices
          <v-spacer />
          <v-btn icon @click="allInvoicesPage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <allInvoicePage :key="allInvoicesPageCounter" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- list of all patients -->
    <v-dialog v-model="allPatientsListPage" scrollable max-width="1000px">
      <v-card>
        <v-card-title>All Patients
          <v-spacer />
          <v-btn icon @click="allPatientsListPage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <allPatientsPage :key="allPatientsPageCounter" />
        </v-card-text>
      </v-card>
    </v-dialog>
        <!-- list of all Products -->
        <v-dialog v-model="allProductsListPage" scrollable max-width="1000px">
      <v-card>
        <v-card-title>All Products
          <v-spacer />
          <v-btn icon @click="allProductsListPage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-divider></v-divider>

        <v-card-text>
          <allProductsPage :key="allProductsPageCounter" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- list of all Balances -->
    <v-dialog v-model="allBalancePage" scrollable max-width="1000px">
      <v-card>
        <v-card-title>All Patients
          <v-spacer />
          <v-btn icon @click="allBalancePage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <allBalancePage :key="allBalancePageCounter" />
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- settings dialog -->
    <v-dialog v-model="settingsPageToggle" scrollable max-width="1000px">
      <v-card>
        <v-card-title>Settings
          <v-spacer />
          <v-btn icon @click="settingsPageToggle = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <settingsPage @closePage="settingsPageToggle = false" :key="settingsPageCounter" />
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- main calendar page -->
    <v-main :style="{ paddingTop: computedPadding }">
  <router-view :key="$route.fullPath"></router-view>
</v-main>

  </v-app>
</template>

<style scoped>
.highlight-text {
  font-size: 1.5em;
  font-weight: bold;
}
.v-toolbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
}

.v-main {
  padding-top: 64lpx;
}


.v-toolbar {
  overflow-y: auto;
  max-height: 64px;
}
</style>

<script>
import Vue from "vue";
import allPatientsPage from "./components/customPages/ToolBarPages/allPatientsPage.vue";
import allProductsPage from "./components/customPages/ToolBarPages/allProductsPage.vue";
import settingsPage from "./components/customPages/ToolBarPages/settingsPage.vue";
import newEventPage from "./components/customPages/ToolBarPages/newEventPage.vue";
import allInvoicePage from "./components/invoiceAndReceipt/allInvoicePage.vue";

window.addEventListener(
  "contextmenu",
  function (e) {
    e.preventDefault();
  },
  false
);
export default {
  name: "App",
  components: {
    allPatientsPage,
    allProductsPage,
    settingsPage,
    allInvoicePage,
    newEventPage,
  },
  computed: {
    showToolbar() { 
      return this.$route.meta.showToolbar !== false;
    },
    computedPadding() {
      if (this.isAuthenticated && this.showToolbar) {
        return '64px';  // Height of the toolbar
      } else {
        return '0px';  // No padding if toolbar is not shown
      }
    }
  },
  async created() {
    const { status } = await this.$axios({
      method: "post",
      url: this.$axios.defaults.baseURL + "/api/checkLoggedIn",
      headers: {
        Authorization: localStorage.getItem("accessToken"),
      },
    }).catch((error) => console.log(error));
    if (status === 200) {
      this.isAuthenticated = true
    } else {
      this.$router.push("/loginPage");
    }

    this.$root.$on("login-success", () => {
      this.isAuthenticated = true;
    });
    this.$root.$on("login-failed", () => {
      this.isAuthenticated = false;
    });

    //find today's date and render
    this.$root.$on("focusChanged", this.focusChanged);
    this.updateType();
    this.setToday();
  },
  beforeDestroy() {
    this.$root.$off("focusChanged", this.focusChanged);
  },
  watch: {
    $route: "updateType",
    // remember the data in new event page when closing to prevent accidental losing input
    newEventDialog(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.$refs.newEventPage.captureSnapshot();
      }
    },
  },

  async mounted() {
    document.addEventListener('visibilitychange', function () {
      if (document.visibilityState === 'hidden') {
        // Record the current timestamp when the tab goes out of focus
        localStorage.setItem('lastTabFocus', Date.now());
      } else if (document.visibilityState === 'visible') {
        // Get the stored timestamp when the tab comes back into focus
        const lastTabFocus = localStorage.getItem('lastTabFocus');
        if (lastTabFocus) {
          const timeDiff = Date.now() - lastTabFocus;
          // If the tab was inactive for more than a specified time (e.g., 10 minutes), refresh the page
          if (timeDiff > 60 * 60 * 1000) { // 10 minutes in milliseconds
            window.location.reload();
          }
        }
      }
    });


    //if user is authenticated
    if (this.isAuthenticated) {
      //get some basic information to input to prototype for later use
      Vue.prototype.$getDoctorsList = async () => {
        let doctors = [];
        await this.$axios({
          method: "get",
          url: this.$axios.defaults.baseURL + "/api/getDoctorsList",
          headers: { Authorization: localStorage.getItem("accessToken") },
        })
          .then((response) => {
            response.data.forEach((doc) => {
              let appData = doc;
              doctors.push(appData);
            });
          })
          .catch((error) => console.log(error));
        Vue.prototype.$doctorsListObj = doctors;
      };

      Vue.prototype.$getServicesList = async () => {
        let services = [];
        await this.$axios({
          method: "get",
          url: this.$axios.defaults.baseURL + "/api/getServicesList",
          headers: { Authorization: localStorage.getItem("accessToken") },
        })
          .then((response) => {
            response.data.forEach((doc) => {
              let appData = doc;
              services.push(appData);
            });
          })
          .catch((error) => console.log(error));

        Vue.prototype.$servicesListObj = services;
      };

      Vue.prototype.$getLocationsList = async () => {
        let locationListTemp = [];
        await this.$axios({
          method: "get",
          url: this.$axios.defaults.baseURL + "/api/getLocationsList",
          headers: { Authorization: localStorage.getItem("accessToken") },
        })
          .then((response) => {
            response.data.forEach((doc) => {
              let appData = doc;
              locationListTemp.push(appData);
            });
          })
          .catch((error) => console.log(error));
        Vue.prototype.$LocationsListObj = locationListTemp;
      };
    }
  },
  data: () => ({
    displayedMonth: new Date(
      new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10)
    ).toLocaleString("default", { month: "long" }),


    type: "",
    category: "category",
    typeToLabel: {
      month: "Month",
      week: "Week",
      category: "Day",
    },
    isAuthenticated: null,
    allInvoicesPage: false,
    allBalancePage: false,
    allInvoicesPageCounter: 0,
    allBalancePageCounter: 0,
    allPatientsListPage: false,
    allProductsListPage:false,
    allPatientsPageCounter: 0,
    allProductsPageCounter:0,
    settingsPageCounter: 0,
    newEventPageCounter: 0,
    settingsPageToggle: false,
    newEventDialog: false,
    createEventstart: "",
    createEventend: "",
    provider: "",
    childStateSnapshot: {},
  }),
  methods: {
    focusChanged: function (value) {
      //calculate and display the month on top
      let monthNumber = value.split("-")[1];
      let monthDate = new Date(2023, monthNumber - 1);
      this.displayedMonth = monthDate.toLocaleString("default", {
        month: "long",
      });
    },

    updateType() {
      //check drop down is month or day
      let currentPath = this.$route.path;
      if (currentPath === "/month") {
        this.type = "month";
      } else if (currentPath === "/day") {
        this.type = "category";
      }
    },

    prevDay: function () {
      this.$root.$emit("prevDay");
    },

    nextDay: function () {
      this.$root.$emit("nextDay");
    },

    setToday() {
      //called when page is refreshed
      try {
        const storedData = localStorage.getItem("dwp");
        if (sessionStorage.getItem("isRefresh")) {
          // Page was refreshed - do nothing
        } else {
          if (storedData) {
            let receivedDataDWP = JSON.parse(storedData);
            receivedDataDWP.selectedDate = new Date(
              Date.now() - new Date().getTimezoneOffset() * 60000
            )
              .toISOString()
              .substr(0, 10);
            localStorage.setItem("dwp", JSON.stringify(receivedDataDWP));
            this.$root.$emit("focusToday");
          }
        }
        //this.$root.$emit("focusToday");
        sessionStorage.setItem("isRefresh", true);

        this.displayedMonth = new Date(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        ).toLocaleString("default", { month: "long" });
      } catch (error) {
        console.error("Error accessing localStorage:", error);
      }
    },
    TodayButton() {
      try {
        const storedData = localStorage.getItem("dwp");
        if (storedData) {
          let receivedDataDWP = JSON.parse(storedData);
          receivedDataDWP.selectedDate = new Date(
            Date.now() - new Date().getTimezoneOffset() * 60000
          )
            .toISOString()
            .substr(0, 10);
          localStorage.setItem("dwp", JSON.stringify(receivedDataDWP)); // Removed the extra space
          this.$root.$emit("focusToday");
        }
        this.displayedMonth = new Date(
          new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10)
        ).toLocaleString("default", { month: "long" });
      } catch (error) {
        console.error("Error accessing localStorage:", error);
      }
    },


    cancelCreateEvent() {
      //cancel create new event clears the data in the page
      this.newEventDialog = false;
      this.newEventPageCounter++;
    },
  },
};
</script>
