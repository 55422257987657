<template>
  <v-container>
    <div>
      <v-btn color="primary" @click="addNewIndividualService = true">
        Add Service
      </v-btn>
      <v-data-table :headers="serviceListHeader" :items="individualServiceListItem" :items-per-page="5"
        class="elevation-1">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editNewIndividualServiceFn(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
      <v-dialog v-model="addNewIndividualService" max-width="450">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Add Service</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="addNewIndividualService = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-container>
            <v-form @submit.prevent class="container-outline">
              <v-select return-object v-model="serviceNameDropdown" :items="service_name_dropdown" label="Services"
                item-text="serviceName" dense outlined>
              </v-select>
              <v-select v-model="selectedTime" :items="timeOptions" item-text="text" item-value="value"
                label="Select minutes" @change="calculateMilliseconds"></v-select>
              <v-text-field v-model="addIndividualServicePrice" type="number" label="Price/Unit"></v-text-field>
              <v-checkbox v-model="addServiceHST" :label="`Tax`"></v-checkbox>
              <v-text-field v-if="addServiceHST" v-model="hstRate" type="number"
                label="Tax Format: 13 For 13% Tax"></v-text-field>
              <br />
              <v-btn :disabled="!serviceNameDropdown ||
                !selectedTime ||
                !addIndividualServicePrice
                " type="submit" color="primary" class="mb-4" @click.stop="addIndividualServiceList()">add</v-btn>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
      <v-dialog v-model="editNewIndividualService" max-width="450">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Edit Service</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="editNewIndividualService = false, selectedService = []">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-container>
            <v-form @submit.prevent class="container-outline">
              <v-text-field v-model="selectedService.serviceName" type="input" label="Name"></v-text-field>
              <v-select v-model="selectedService.time" :items="timeOptions" item-text="text" item-value="value"
                label="Select minutes" @change="calculateMillisecondsForEditService"></v-select>
              <v-text-field v-model="selectedService.servicePrice" type="number" label="Price/Unit"></v-text-field>
              <v-checkbox v-model="selectedService.serviceHST" :label="`HST`"></v-checkbox>
              <v-text-field v-if="selectedService.serviceHST" v-model="selectedService.hstRate" type="number"
                label="Tax Format: 13 for 13% Tax"></v-text-field>
              <br />

              <v-icon small class="mr-2" @click="deleteIndividualService(selectedService)">mdi-delete</v-icon>

              <v-btn type="submit" color="primary" class="mb-4" @click.stop="updateServices()">Update Service Info</v-btn>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<style scoped>
.margintop {
  margin-top: 10px;
}
</style>
<script>
export default {
  name: "AvailableSerivce",
  props: {
    providerId: {
      providerId: String,
    },
    locationId: {
      locationId: String,
    },
  },
  data: () => ({
    selectedTime: null,
    hstRate: 0,
    milliseconds: null,
    timeOptions: [
      { text: "5 min", value: 5 },
      { text: "10 min", value: 10 },
      { text: "15 min", value: 15 },
      { text: "20 min", value: 20 },
      { text: "25 min", value: 25 },
      { text: "30 min", value: 30 },
      { text: "35 min", value: 35 },
      { text: "40 min", value: 40 },
      { text: "45 min", value: 45 },
      { text: "50 min", value: 50 },
      { text: "65 min", value: 55 },
      { text: "1 hr", value: 60 },
      { text: "1 hr 05 mins", value: 65 },
      { text: "1 hr 10 mins", value: 70 },
      { text: "1 hr 15 mins", value: 75 },
      { text: "1 hr 20 mins", value: 80 },
      { text: "1 hr 25 mins", value: 85 },
      { text: "1 hr 30 mins", value: 90 },
      { text: "1 hr 35 mins", value: 95 },
      { text: "1 hr 40 mins", value: 100 },
      { text: "1 hr 45 mins", value: 105 },
      { text: "1 hr 50 mins", value: 110 },
      { text: "1 hr 55 mins", value: 115 },
      { text: "2 hrs", value: 120 },
    ],
    serviceListHeader: [
      { text: "Service Name", value: "serviceName" },
      { text: "Service Price", value: "servicePrice" },
      { text: "Tax", value: "serviceHST" },
      { text: "Time", value: "milliseconds" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    addNewIndividualService: false,
    selectedService: { hst: false,hstRate:0},
    editNewIndividualService: false,
    individualServiceListItem: [],
    serviceNameDropdown: [],
    service_name_dropdown: [],
    addIndividualServicePrice: null,
    databseHST: false,
    locationListDropdown: [],
    location_name_dropdown: [],
    addServiceHST: false,
  }),
  async mounted() {
    this.getIndividualServiceList();
    this.updateServiceList();
  },
  methods: {
    editNewIndividualServiceFn(item) {
      this.selectedService = item 
      this.calculateMillisecondsForEditService()
      this.editNewIndividualService = true
    },
    calculateMilliseconds() { 
      this.milliseconds = this.selectedTime * 60 * 1000;
    },
    calculateMillisecondsForEditService() { 
      this.milliseconds = this.selectedService.time * 60 * 1000;
    },


    async updateServices() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/updateServices",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          milliseconds: this.milliseconds,
          docId: this.providerId,
          eventId: this.selectedService.id,
          locationId: this.locationId,
          serviceId: this.selectedService.serviceId,
          serviceName: this.selectedService.serviceName,
          servicePrice: this.selectedService.servicePrice,
          serviceHST: this.selectedService.serviceHST,
          hstRate: this.selectedService.hstRate || 0,
        },
      })
        .then(() => {
          this.getIndividualServiceList();
          this.editNewIndividualService = false;
        })
        .catch((error) => console.log(error));
    },
    async deleteIndividualService(item) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/deleteIndividualServiceList",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          docId: this.providerId,
          serviceId: item.id,
        },
      })
        .then(() => {
          this.editNewIndividualService = false
          this.getIndividualServiceList();
        })
        .catch((error) => console.log(error));
    },
    async addIndividualServiceList() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/addIndividualServiceList ",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          milliseconds: this.milliseconds,
          docId: this.providerId,
          locationId: this.locationId,
          serviceId: this.serviceNameDropdown.serviceId,
          serviceName: this.serviceNameDropdown.serviceName,
          servicePrice: this.addIndividualServicePrice,
          serviceHST: this.addServiceHST,
          hstRate: this.hstRate,
        },
      })
        .then(() => {
          this.getIndividualServiceList();
          this.addNewIndividualService = false;
          this.serviceNameDropdown = null;
          this.selectedTime = null;
          this.hstRate = 0
          this.addIndividualServicePrice = null;
          this.addServiceHST = false;
        })
        .catch((error) => console.log(error));
    },
    async getIndividualServiceList() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getIndividualServiceList",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          locationId: this.locationId,
          docId: this.providerId,
        },
      })
        .then((doc) => {
          doc.data.forEach((serv) => {
            serv.time = serv.milliseconds / 60000
            serv.milliseconds = this.calculateTimeLength(serv.milliseconds);
          });
          this.individualServiceListItem = doc.data;
        })
        .catch((error) => console.log(error));
    },
    async updateServiceList() {
      let tempService = [];

      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getServiceByLocation",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          locationId: this.locationId,
        },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            tempService.push(appData);
          });
        })
        .catch((error) => console.log(error));
      this.service_name_dropdown = tempService;
    },
    calculateTimeLength(time) {
      time = parseInt(time);
      let temp = time / 60000;

      if (temp === 5) {
        return "5 mins";
      } else if (temp === 10) {
        return "10 mins";
      } else if (temp === 15) {
        return "15 mins";
      } else if (temp === 20) {
        return "20 mins";
      } else if (temp === 25) {
        return "25 mins";
      } else if (temp === 30) {
        return "30 mins";
      } else if (temp === 35) {
        return "35 mins";
      } else if (temp === 40) {
        return "40 mins";
      } else if (temp === 45) {
        return "45 mins";
      } else if (temp === 50) {
        return "50 mins";
      } else if (temp === 55) {
        return "55 mins";
      } else if (temp === 60) {
        return "1 hr";
      } else if (temp === 65) {
        return "1 hr 5 mins";
      } else if (temp === 70) {
        return "1 hr 10 mins";
      } else if (temp === 75) {
        return "1 hr 15 mins";
      } else if (temp === 80) {
        return "1 hr 20 mins";
      } else if (temp === 85) {
        return "1 hr 25 mins";
      } else if (temp === 90) {
        return "1 hr 30 mins";
      } else if (temp === 95) {
        return "1 hr 35 mins";
      } else if (temp === 100) {
        return "1 hr 40 mins";
      } else if (temp === 105) {
        return "1 hr 45 mins";
      } else if (temp === 110) {
        return "1 hr 50 mins";
      } else if (temp === 115) {
        return "1 hr 55 mins";
      } else if (temp === 120) {
        return "2 hrs";
      } else {
        return temp + " mins";
      }
    },
  },
};
</script>
