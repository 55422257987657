<template>
  <div>
    <v-container>
      <br />
      <v-card class="mx-auto" outlined>
        <v-card-title>Information</v-card-title>
        <v-list-item>
          <v-list-item-content>
            <p class="my-1">Phone: {{ this.phone }}<br /></p>
            <p class="my-1">Email: {{ this.email }}<br /></p>
            <p class="my-1">Provider: {{ providerNameOrNone }}</p>
            <p class="my-1">Location: {{ locationNameOrNone }}<br /></p>
            <p class="my-1">Note: {{ this.note }}</p>
            <div v-if="showAllInfo">
              <p class="my-1">Address: {{ this.address }}<br /></p>
              <p class="my-2">
                Gender:{{ this.selectedEvent.basicInfo.gender }}<br />
              </p>
              <p class="my-2">
                Date of Birth: {{ this.selectedEvent.basicInfo.birth_date
                }}<br />
              </p>
              <p class="my-2">
                Occupation: {{ this.selectedEvent.otherInfo.occupation }}<br />
              </p>
              <p class="my-2">
                Family Doctor Name:
                {{ this.selectedEvent.otherInfo.familydoctorname }}<br />
              </p>
              <p class="my-2">
                Family Doctor Phone:
                {{ this.selectedEvent.otherInfo.familydoctorphone }}<br />
              </p>
              <p class="my-2">
                Emergency Contact Name:
                {{ this.selectedEvent.otherInfo.emergencyname }}<br />
              </p>
              <p class="my-2">
                Emergency Contant Phone:
                {{ this.selectedEvent.otherInfo.emergencyphone }}<br />
              </p>
            </div>
          </v-list-item-content>
          <v-btn text icon @click="showOrNot()"
            ><v-icon> mdi-information </v-icon></v-btn
          >
          <v-btn text icon @click="editUserInfo = true"
            ><v-icon> mdi-pencil </v-icon></v-btn
          >
        </v-list-item> </v-card
      ><br />
      <v-card outlined v-if="this.selectedEvent.UserId">
        <v-card-title>All Visits' Notes</v-card-title>
        <v-list-item>
          <NotesHistory
            :key="this.selectedEvent.UserId"
            :userId="this.selectedEvent.UserId"
          />
        </v-list-item>
      </v-card>
    </v-container>
    <v-dialog v-model="editUserInfo" max-width="600">
      <div>
        <v-card>
          <v-toolbar text color="primary" dark>
            <v-toolbar-title>Edit User Info</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="editUserInfo = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card>
            <v-container>
              <patientDetailSetting
                @close="handleClose()"
                :selectedEvent="selectedEvent"
                :key="this.selectedEvent.UserId"
              />
            </v-container>
          </v-card>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
<style scoped>
.onelinebreak {
  display: flex;
  justify-content: space-between;
}
.multiline {
  white-space: pre-wrap;
}
.inlineblocking {
  display: inline-block;
}
</style>
<script>
import NotesHistory from "../customPages/NotesHistory.vue";
export default {
  name: "patientInformationDisplay",
  props: ["selectedEvent"],
  components: {
    NotesHistory,
  },
  computed: {
    providerNameOrNone() {
      return (
        this.selectedEvent?.basicInfo?.defaultProvider?.providerName || "None"
      );
    },
    locationNameOrNone() {
      return (
        this.selectedEvent?.basicInfo?.defaultLocation?.companyName || "None"
      );
    },
  },
  data: () => ({
    phone: null,
    email: null,
    address: null,
    note: null,
    showAllInfo: false,
    editUserInfo: false,
  }),
  mounted() {
    if (this.selectedEvent.phones) {
      const defaultPhone = this.selectedEvent.phones.find(
        (phone) => phone.default
      );
      if (defaultPhone) {
        this.phone = defaultPhone.phoneNumber;
      }
    }
    if (this.selectedEvent.notes) {
      const reversedNotes = [...this.selectedEvent.notes].reverse();
      const defaultNote = reversedNotes.find((note) => note.pinned);
      if (defaultNote) {
        this.note = defaultNote.text;
      }
    }
    if (this.selectedEvent.emails) {
      const defaultEmail = this.selectedEvent.emails.find(
        (email) => email.default
      );
      if (defaultEmail) {
        this.email = defaultEmail.email;
      }
    }
    if (this.selectedEvent.addresses) {
      const defaultAddress = this.selectedEvent.addresses.find(
        (address) => address.default
      );
      if (defaultAddress) {
        this.address =
          defaultAddress.AddressLine1 +
          " " +
          defaultAddress.AddressLine2 +
          " " +
          defaultAddress.City +
          " " +
          defaultAddress.Province +
          " " +
          defaultAddress.Country +
          " " +
          defaultAddress.PostalCode;
      }
    }
  },
  methods: {
    async handleClose() {
      this.editUserInfo = false;
      this.$emit("close", this.selectedEvent.UserId);
    },
    showOrNot() {
      if (this.showAllInfo == true) {
        this.showAllInfo = false;
      } else {
        this.showAllInfo = true;
      }
    },
  },
};
</script>
