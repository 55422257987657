<template>
  <v-container>
    <div>
      <v-row>
        <v-col sm="9">
          <v-text-field
            v-model="newNoteText"
            label="New note"
            :error-messages="noteErrors"
          ></v-text-field>
        </v-col>
        <v-col sm="1">
          <v-btn icon color="green" @click="confirmAddNote"
            ><v-icon>mdi-tag-plus</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-list>
        <v-list-item v-for="(note, index) in displayedNotes" :key="index">
          <v-list-item-content>
            <div
              :class="{ 'pinned-note': note.pinned }"
              :style="
                note.status
                  ? {}
                  : { textDecoration: 'line-through', color: 'grey' }
              "
            >
              <b>{{ note.text }}</b>
            </div>
          </v-list-item-content>
          <v-list-item-action v-if="note.status">
            <v-row>
              <v-btn icon @click="togglePin(note)">
                <v-icon :class="{ 'green-icon': note.pinned }">{{
                  note.pinned ? "mdi-pin-off" : "mdi-pin"
                }}</v-icon>
              </v-btn>
              <v-btn icon color="red" @click="confirmDelete(note)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-btn v-if="notes.length > 3" @click="toggleExpand">
        <v-icon>{{
          isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
        }}</v-icon>
      </v-btn>
      <v-dialog v-model="showDeleteDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">Confirmation</v-card-title>
          <v-card-text>Are you sure you want to delete this note?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red darken-1" text @click="cancelDelete">No</v-btn>
            <v-btn color="red darken-1" text @click="confirmDeleteNote"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
export default {
  components: {},
  name: "patientNotesHistory",
  props: ["userId"],
  data: () => ({
    newNoteText: "",
    notes: [],
    isExpanded: false,
    noteErrors: [],
    showDeleteDialog: false,
    noteToDelete: null,
  }),
  computed: {
    displayedNotes() {
      return this.notes
        .slice()
        .reverse()
        .slice(0, this.isExpanded ? this.notes.length : 3);
    },
  },
  async mounted() {
    this.getNotes();
  },
  methods: {
    async getNotes() {
      this.events = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalNote/get",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
        },
      })
        .then((response) => {
          if (response.data.notes) {
            this.notes = response.data.notes;
            this.sortNotes();
          }
        })
        .catch((error) => console.log(error));
    },
    async addNote() {
      this.validateNoteInput();
      if (this.noteErrors.length === 0) {
        this.events = [];
        await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/personalNote/add",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: {
            userId: this.userId,
            noteText: this.newNoteText,
          },
        })
          .then(() => {
            this.getNotes();
          })
          .catch((error) => console.log(error));
        this.newNoteText = "";
      }
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
    },
    validateNoteInput() {
      this.noteErrors = [];
      if (!this.newNoteText.trim()) {
        this.noteErrors.push("Note text cannot be empty");
      }
    },
    confirmAddNote() {
      this.validateNoteInput();
      if (this.noteErrors.length === 0) {
        this.addNote();
      }
    },

    async deleteActual(note) {
      if (note.pinned) {
        await this.togglePin(note);
      }

      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalNote/delete",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
          noteNumber: note.number,
        },
      })
        .then(() => {
          this.getNotes();
        })
        .catch((error) => console.log(error));
    },
    async togglePin(note) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalNote/pin",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
          noteNumber: note.number,
        },
      })
        .then(() => {
          this.getNotes();
        })
        .catch((error) => console.log(error));
    },
    sortNotes() {
      this.notes.sort((a, b) => {
        if (!a.status && b.status) {
          return -1;
        }
        if (a.status && !b.status) {
          return 1;
        }
        return (b.pinned ? 0 : 1) - (a.pinned ? 0 : 1);
      });
    },
    confirmDelete(note) {
      this.noteToDelete = note;
      this.showDeleteDialog = true;
    },
    cancelDelete() {
      this.showDeleteDialog = false;
      this.noteToDelete = null;
    },
    confirmDeleteNote() {
      if (this.noteToDelete) {
        this.deleteActual(this.noteToDelete);
        this.showDeleteDialog = false;
        this.noteToDelete = null;
      }
    },
  },
};
</script>
<style scoped>
.v-icon {
  color: blue;
}
.green-icon {
  color: green !important;
}
.pinned-note {
  color: green !important;
}
</style>
