<template>
  <div>
    <v-btn text @click="generateAndViewPDF()">View PDF</v-btn>

    <v-dialog v-model="dialog" max-width="1200px">
      <iframe :src="pdfSrc" width="100%" height="800px"></iframe>
    </v-dialog>
  </div>
</template>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';

export default {
  name: "InvoicePDF",
  data: () => ({
    dialog: false,
    pdfSrc: ''
  }),
  methods: {
    generateAndViewPDF() {
      const doc = new jsPDF({
        orientation: 'portrait',
        unit: 'px',
        format: 'a4'
      });

      const marginLeft = 20;
      const marginTop = 20;
      const pageWidth = doc.internal.pageSize.getWidth();
      const pageHeight = doc.internal.pageSize.getHeight();

      // Company Header
      doc.setFontSize(18);
      doc.text('OctChain', marginLeft, marginTop);
      doc.setFontSize(12);
      doc.text('123 Bay', marginLeft, marginTop + 15);
      doc.text('Toronto, ON, CA', marginLeft, marginTop + 30);
      doc.text('Phone: (123)123-1234', marginLeft, marginTop + 45);
      doc.text('Email: 123@bay.com', marginLeft, marginTop + 60);
      doc.text('Website: www.octchain.com', marginLeft, marginTop + 75);

      // Invoice Info
      const invoiceText = 'Invoice #: 19';
      const invoiceTextWidth = doc.getTextWidth(invoiceText);
      doc.setFontSize(14);
      doc.text(invoiceText, pageWidth - invoiceTextWidth - marginLeft, marginTop);

      const paymentDateText = 'Payment Date: 01/01/2023';
      const paymentDateTextWidth = doc.getTextWidth(paymentDateText);
      doc.setFontSize(12);
      doc.text(paymentDateText, pageWidth - paymentDateTextWidth - marginLeft, marginTop + 15);

      const invoiceDateText = 'Invoice Date: 02/02/2023';
      const invoiceDateTextWidth = doc.getTextWidth(invoiceDateText);
      doc.text(invoiceDateText, pageWidth - invoiceDateTextWidth - marginLeft, marginTop + 30);

      // Draw a line
      doc.setLineWidth(1.5);
      doc.line(marginLeft, marginTop + 85, pageWidth - marginLeft, marginTop + 85);

      // Table (example content)
      doc.autoTable({
        startY: marginTop + 95,
        head: [['#', 'Description', 'Price', 'Quantity', 'Unit', 'Total']],
        body: [
          [1, 'Tuina', '200.00', '1', 'ea', '200.00'],
          [1, 'GuaSha', '20.00', '1', 'ea', '20.00'],
          [1, 'Massage', '100.00', '1', 'ea', '100.00'],
          [1, 'Herb', '77.00', '10', 'ea', '770.00'],

          // More rows...
        ],
        theme: 'grid',
        headStyles: { fillColor: [41, 128, 185] },
        styles: { cellPadding: 5, fontSize: 11 },
      });



      // Calculate the final Y position after the table and add some additional space
      let finalY = doc.lastAutoTable.finalY + 20; // Increase space between table and signature

      // Ensure there's enough space for the signature, else add a new page
      const spaceNeededForSignature = 40; // Height of the signature space plus some padding
      if (finalY + spaceNeededForSignature > pageHeight - marginTop) {
        doc.addPage();
        finalY = marginTop; // Reset Y position for new page
      }

      // Signature line
      const signatureLineXStart = pageWidth - 200; // X start position for signature line
      const signatureLineXEnd = pageWidth - marginLeft; // X end position for signature line
      finalY += 30; // Increase this to move the signature line further down
      doc.text('Signature:', signatureLineXStart, finalY);
      doc.line(signatureLineXStart, finalY + 10, signatureLineXEnd, finalY + 10);


      // Footer
      const footerText = 'The invoice is created on a computer and is valid without the signature and stamp.';
      const footerTextWidth = doc.getTextWidth(footerText);
      doc.text(footerText, (pageWidth - footerTextWidth) / 2, pageHeight - marginTop);

      // Generate PDF
      const pdfBlob = doc.output('blob');
      this.pdfSrc = URL.createObjectURL(pdfBlob);
      this.dialog = true;
    }
  },
};
</script>

<style scoped>
.marginall {
  margin: 15px;
}

.center {
  text-align: center;
}
</style>
