<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-col cols="12">
        <v-row>
          <v-col cols="4">
            <h1>Provider Work Time</h1>
            <v-card max-width="350px">
              <v-checkbox
                v-model="dateTime.mondayCheck"
                label="Monday"
              ></v-checkbox>
              <div v-if="dateTime.mondayCheck">
                <time-range-picker
                  v-model="dateTime.monday"
                  inputLabel="Work Hours"
                  hide-details
                  hide-selected
                  outlined
                  outerIcon=""
                  step="15"
                />
              </div>
              <v-checkbox
                v-model="dateTime.tuesdayCheck"
                label="Tuesday"
              ></v-checkbox>
              <div v-if="dateTime.tuesdayCheck">
                <time-range-picker
                  v-model="dateTime.tuesday"
                  inputLabel="Work Hours"
                  hide-details
                  hide-selected
                  outerIcon=""
                  outlined
                  step="15"
                />
              </div>
              <v-checkbox
                v-model="dateTime.wednesdayCheck"
                label="Wednesday"
              ></v-checkbox>
              <div v-if="dateTime.wednesdayCheck">
                <time-range-picker
                  v-model="dateTime.wednesday"
                  inputLabel="Work Hours"
                  hide-details
                  hide-selected
                  outlined
                  outerIcon=""
                  step="15"
                />
              </div>
              <v-checkbox
                v-model="dateTime.thursdayCheck"
                label="Thursday"
              ></v-checkbox>
              <div v-if="dateTime.thursdayCheck">
                <time-range-picker
                  v-model="dateTime.thursday"
                  inputLabel="Work Hours"
                  hide-details
                  hide-selected
                  outerIcon=""
                  outlined
                  step="15"
                />
              </div>
              <v-checkbox
                v-model="dateTime.fridayCheck"
                label="Friday"
              ></v-checkbox>
              <div v-if="dateTime.fridayCheck">
                <time-range-picker
                  v-model="dateTime.friday"
                  inputLabel="Work Hours"
                  hide-details
                  hide-selected
                  outlined
                  outerIcon=""
                  step="15"
                />
              </div>
              <v-checkbox
                v-model="dateTime.saturdayCheck"
                label="Saturday"
              ></v-checkbox>
              <div v-if="dateTime.saturdayCheck">
                <time-range-picker
                  v-model="dateTime.saturday"
                  inputLabel="Work Hours"
                  hide-details
                  hide-selected
                  outerIcon=""
                  outlined
                  step="15"
                />
              </div>
              <v-checkbox
                v-model="dateTime.sundayCheck"
                label="Sunday"
              ></v-checkbox>
              <div v-if="dateTime.sundayCheck">
                <time-range-picker
                  v-model="dateTime.sunday"
                  inputLabel="Work Hours"
                  hide-details
                  hide-selected
                  outlined
                  outerIcon=""
                  step="15"
                />
              </div>
            </v-card>
            <v-btn @click="showTime" color="primary"
              >Update Provider Work Schedule</v-btn
            >
          </v-col>
          <v-col>
            <v-row v-if="locationId" class="justify-space-between mb-4">
              <v-col cols="auto">
                <v-btn color="primary" dark @click="dialog = true"
                  >Add Time Slot</v-btn
                >
              </v-col>
            </v-row>

            <v-row class="justify-center">
              <v-card max-width="290">
                <v-date-picker
                  v-model="selectedDate"
                  :events="bookedDates"
                  :event-color="eventColor"
                  no-title
                  @input="menu = false"
                  :picker-date.sync="pickerDate"
                ></v-date-picker>
              </v-card>
              <br />
              <v-data-table
                :headers="timeSlotHeader"
                :items="timeSlots"
                :items-per-page="5"
                class="elevation-1"
                :custom-sort="customSort"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="deleteTimeSlot(item)"
                    >mdi-delete</v-icon
                  >
                </template>

                <template v-slot:[`item.dateOnly`]="{ item }">
                  <span :class="{ 'grey-out': isPastDate(item.dateOnly) }">{{
                    item.dateOnly
                  }}</span>
                </template>
              </v-data-table>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" persistent max-width="650px">
      <v-card>
        <v-card-title>
          <span class="headline">Add Provider Off Time</span>
          <v-spacer />
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  type="text"
                  label="Date (Year/Month/Day)"
                  v-model="dateOnly"
                  readonly
                  required
                  v-on="on"
                  v-bind="attrs"
                />
              </template>
              <v-date-picker
                v-model="selectedDate"
                :events="bookedDates"
                :event-color="eventColor"
                no-title
                @input="updateDate"
                :picker-date.sync="pickerDate"
              ></v-date-picker>
            </v-menu>
            <div v-if="dayOfWeek">{{ dayOfWeek }}</div>
            <span class="error--text" v-if="dateError">{{ dateError }}</span>
            <v-row>
              <v-col cols="12" sm="6">
                <v-time-picker
                  v-model="start"
                  :allowed-minutes="allowedStep"
                  ampm-in-title
                  class="mt-4"
                  required
                ></v-time-picker>
              </v-col>
              <v-col cols="12" sm="6">
                <v-time-picker
                  v-model="end"
                  :allowed-minutes="allowedStep"
                  ampm-in-title
                  class="mt-4"
                  required
                ></v-time-picker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3">
                <v-checkbox
                  v-model="fullDay"
                  label="Full Day"
                  @change="toggleFullDay"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            @click="addTimeSlot"
            :disabled="!isFormValid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  props: {
    providerId: {
      providerId: String,
    },
    locationId: {
      locationId: String,
    },
  },
  data() {
    return {
      individualDocLocationListItem: [],
      dateTime: {
        mondayCheck: false,
        tuesdayCheck: false,
        wednesdayCheck: false,
        thursdayCheck: false,
        fridayCheck: false,
        saturdayCheck: false,
        sundayCheck: false,
        monday: {
          start: "07:00",
          end: "21:00",
          isWorkDay: false,
        },
        tuesday: {
          start: "07:00",
          end: "21:00",
          isWorkDay: false,
        },
        wednesday: {
          start: "07:00",
          end: "21:00",
          isWorkDay: false,
        },
        thursday: {
          start: "07:00",
          end: "21:00",
          isWorkDay: false,
        },
        friday: {
          start: "07:00",
          end: "21:00",
          isWorkDay: false,
        },
        saturday: {
          start: "07:00",
          end: "21:00",
          isWorkDay: false,
        },
        sunday: {
          start: "07:00",
          end: "21:00",
          isWorkDay: false,
        },
      },
      date: null,
      timeSlotHeader: [
        { text: "Date", value: "dateOnly" },
        { text: "Start", value: "start" },
        { text: "End", value: "end" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      newTimeSlot: {
        date: "",
        startTime: "",
        endTime: "",
      },
      timeSlots: [],
      fullDay: false,
      dialog: false,
      menu: false,
      start: null,
      end: null,
      allowedStep: (m) => m % 15 === 0,
      location: null,

      currentMonth: new Date().getMonth() + 1,
      dateOnly: "",
      selectedDate: null,
      pickerDate: new Date().toISOString().substr(0, 7),
      locationWorkHour: [],
      providerWorkHour: [],
      currentYear: new Date().getFullYear(),
    };
  },
  watch: {
    pickerDate(val) {
      this.currentMonth = val.split("-")[1];
      this.currentYear = val.split("-")[0];
      this.bookedDates;
    },
  },
  computed: {
    dayOfWeek() {
      if (this.dateOnly) {
        const date = new Date(this.dateOnly);
        const days = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        return days[date.getDay()];
      }
      return "";
    },

    bookedDates: function () {
      if (
        !this.locationWorkHour ||
        Object.keys(this.locationWorkHour).length === 0
      ) {
        return [];
      }

      if (
        !this.providerWorkHour ||
        Object.keys(this.providerWorkHour).length === 0
      ) {
        return [];
      }

      const currentMonth = this.currentMonth - 1;

      const currentYear = this.currentYear;
      const daysOfWeek = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const bookedWeekdays = daysOfWeek.filter((day) => {
        return this.locationWorkHour.time[day + "Check"] === false;
      });

      const bookedproviderWeekdays = daysOfWeek.filter((day) => {
        return this.providerWorkHour[day + "Check"] === false;
      });

      const providerDatesForMonth = [];
      for (let i = 1; i <= 31; i++) {
        const dateObj = new Date(currentYear, currentMonth, i);
        if (dateObj.getMonth() === currentMonth) {
          const dayName = daysOfWeek[dateObj.getDay()];
          if (bookedproviderWeekdays.includes(dayName)) {
            providerDatesForMonth.push(dateObj.toISOString().split("T")[0]);
          }
        }
      }
      const datesForMonth = [];
      for (let i = 1; i <= 31; i++) {
        const dateObj = new Date(currentYear, currentMonth, i);
        if (dateObj.getMonth() === currentMonth) {
          const dayName = daysOfWeek[dateObj.getDay()];
          if (bookedWeekdays.includes(dayName)) {
            datesForMonth.push(dateObj.toISOString().split("T")[0]);
          }
        }
      }

      const individualDates = this.individualDocLocationListItem.map(
        (item) => item.dateOnly
      );

      return [
        ...this.timeSlots.map((timeSlot) => timeSlot.dateOnly),
        ...datesForMonth,
        ...providerDatesForMonth,
        ...individualDates,
      ];
    },

    isFormValid() {
      return !this.dateError && !this.startTimeError && !this.endTimeError;
    },
    dateError() {
      if (!this.dateOnly) {
        return "Date is required.";
      }
      if (this.isPastDate(this.dateOnly)) {
        return "Cannot book past dates.";
      }
      if (this.isDateBooked(this.dateOnly)) {
        return "This date is already booked.";
      }
      if (this.isDateBookedInLocationSettings(this.dateOnly)) {
        return "This date is booked in location settings.";
      }
      if (this.isDayBookedInLocationSettings(this.dateOnly)) {
        return "This day of the week is booked.";
      }
      if (this.isDayBookedInProviderSettings(this.dateOnly)) {
        return "This day of the week is booked.";
      }
      return "";
    },
    startTimeError() {
      if (!this.start) {
        return "Start time is required.";
      }
      return "";
    },
    endTimeError() {
      if (!this.end) {
        return "End time is required.";
      }
      if (this.start && this.end && this.start >= this.end) {
        return "End time must be later than start time.";
      }
      return "";
    },
  },
  async mounted() {
    await this.fetchProviderWorkTime();
    await this.fetchTimeSlotsFromDatabase();
    await this.getLocationOffTimeList();
  },
  methods: {
    isDateBookedInLocationSettings(date) {
      return this.individualDocLocationListItem.some(
        (slot) => slot.dateOnly === date
      );
    },
    async getLocationOffTimeList() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getLocationOffTimeList",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          locationId: this.locationId,
        },
      })
        .then((doc) => {
          this.locationWorkHour = doc.data.workHour;
          let timeSlots = [];
          doc.data.timeSlot.forEach((slot) => {
            let appData = slot;
            let tempStart =
              String(new Date(appData.start).getHours()).padStart(2, "0") +
              ":" +
              String(new Date(appData.start).getMinutes()).padStart(2, "0");
            let tempEnd =
              String(new Date(appData.end).getHours()).padStart(2, "0") +
              ":" +
              String(new Date(appData.end).getMinutes()).padStart(2, "0");
            let tempDate =
              String(new Date(appData.start).getFullYear()).padStart(4, "0") +
              "-" +
              String(new Date(appData.start).getMonth() + 1).padStart(2, "0") +
              "-" +
              String(new Date(appData.start).getDate()).padStart(2, "0");
            let weekName = "";

            timeSlots.push({
              start: tempStart,
              end: tempEnd,
              id: appData.id,
              dateOnly: tempDate,
              weekName: weekName,
            });
          });
          this.individualDocLocationListItem = timeSlots;
        })
        .catch((error) => console.log(error));
    },
    updateDate(date) {
      this.dateOnly = date;
      this.menu = false;
    },

    customSort(items, index, isDescending) {
      if (isDescending) {
        items.sort((a, b) => {
          if (this.isPastDate(a.dateOnly) && !this.isPastDate(b.dateOnly)) {
            return 1;
          }
          if (!this.isPastDate(a.dateOnly) && this.isPastDate(b.dateOnly)) {
            return -1;
          }
          return new Date(a.dateOnly) - new Date(b.dateOnly);
        });
      } else {
        items.sort((a, b) => {
          if (this.isPastDate(a.dateOnly) && !this.isPastDate(b.dateOnly)) {
            return 1;
          }
          if (!this.isPastDate(a.dateOnly) && this.isPastDate(b.dateOnly)) {
            return -1;
          }
          return new Date(b.dateOnly) - new Date(a.dateOnly);
        });
      }
      return items;
    },
    isDateBooked(date) {
      return this.timeSlots.some((slot) => slot.dateOnly === date);
    },
    isPastDate(date) {
      const today = new Date().toISOString().split("T")[0];
      return date < today;
    },
    isDayBookedInLocationSettings(dateString) {
      if (
        !this.locationWorkHour ||
        Object.keys(this.locationWorkHour).length === 0
      ) {
        return false;
      }

      const inputDate = new Date(`${dateString}T12:00:00`);

      const daysOfWeek = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const dayName = daysOfWeek[inputDate.getDay()];

      return this.locationWorkHour.time[dayName + "Check"] === false;
    },

    isDayBookedInProviderSettings(dateString) {
      if (
        !this.providerWorkHour ||
        Object.keys(this.providerWorkHour).length === 0
      ) {
        return false;
      }

      const inputDate = new Date(`${dateString}T12:00:00`);

      const daysOfWeek = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const dayName = daysOfWeek[inputDate.getDay()];

      return this.providerWorkHour[dayName + "Check"] === false;
    },

    eventColor(date) {
      const today = new Date().toISOString().split("T")[0];
      if (date < today) {
        return "grey";
      } else {
        const timeSlot = this.timeSlots.find((slot) => slot.dateOnly === date);
        if (timeSlot && this.start === "00:00" && this.end === "23:59") {
          return "red";
        }
        return "grey";
      }
    },
    async deleteTimeSlot(index) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/deleteProviderOffTime",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { id: index.id, providerId: this.providerId },
      })
        .then(() => {
          this.fetchTimeSlotsFromDatabase();
        })
        .catch((error) => console.log(error));
    },
    async addTimeSlot() {
      let newSlot = {};
      if (this.dateOnly && this.start && this.end) {
        this.start = this.dateOnly + "T" + this.start;
        this.end = this.dateOnly + "T" + this.end;
        newSlot = {
          startTime: new Date(this.start).getTime(),
          endTime: new Date(this.end).getTime(),
        };
        await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/addProviderOffTime",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: {
            providerId: this.providerId,
            locationId: this.locationId,
            time: newSlot,
          },
        }).catch((error) => console.log(error));
        this.fetchTimeSlotsFromDatabase();
        this.dateOnly = "";
        this.start = "";
        this.end = "";
        this.fullDay = false;
        this.dialog = false;
      }
    },
    toggleFullDay() {
      if (this.fullDay) {
        this.start = "00:00";
        this.end = "23:59";
      } else {
        this.start = "";
        this.end = "";
      }
    },
    async fetchTimeSlotsFromDatabase() {
      let timeSlots = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getProviderOffTime",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          providerId: this.providerId,
          locationId: this.locationId,
        },
      })
        .then((doc) => {
          doc.data.forEach((slot) => {
            let appData = slot;

            let tempStart =
              String(new Date(appData.start).getHours()).padStart(2, "0") +
              ":" +
              String(new Date(appData.start).getMinutes()).padStart(2, "0");
            let tempEnd =
              String(new Date(appData.end).getHours()).padStart(2, "0") +
              ":" +
              String(new Date(appData.end).getMinutes()).padStart(2, "0");
            let tempDate =
              String(new Date(appData.start).getFullYear()).padStart(4, "0") +
              "-" +
              String(new Date(appData.start).getMonth() + 1).padStart(2, "0") +
              "-" +
              String(new Date(appData.start).getDate()).padStart(2, "0");
            let weekName = "";

            timeSlots.push({
              start: tempStart,
              end: tempEnd,
              id: appData.id,
              dateOnly: tempDate,
              weekName: weekName,
            });
          });
          this.timeSlots = timeSlots;
        })
        .catch((error) => console.log(error));
    },
    async showTime() {
      await this.$axios({
        method: "post",
        url:
          this.$axios.defaults.baseURL + "/api/updateProviderWorkDays/update",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          locationId: this.locationId,
          providerId: this.providerId,
          time: this.dateTime,
        },
      })
        .then(() => {
          this.fetchProviderWorkTime();
          this.fetchTimeSlotsFromDatabase();
          this.getLocationOffTimeList();

          alert("Provider Work Time Updated!");
        })
        .catch((error) => console.log(error));
    },

    async fetchProviderWorkTime() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/updateProviderWorkDays/get",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          locationId: this.locationId,
          providerId: this.providerId,
        },
      })
        .then((doc) => {
          if (doc.data.time) {
            this.dateTime = doc.data.time;
            this.providerWorkHour = doc.data.time;
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style scoped>
.grey-out {
  color: grey;
  opacity: 0.6;
}

.material-icons {
  vertical-align: middle;
}
</style>
