<template>
  <div>
    <v-form @submit.prevent="addEvent" @keydown.enter.prevent="addEvent">
      <br />
      <v-row>
        <v-col cols="12" sm="6">
          <v-row>
            <v-col cols="12">
              <v-row>
                <v-col cols="12">
                  <v-text-field :append-icon="iconName" filled @keyup.enter="searchUserFn" @click:append="searchUserFn"
                    v-model="searchUser" type="text" label="Search Patient"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-btn color="#818cf8" @click="(addNewUser = true), reloadAddPatient++">Add New Patient</v-btn>
          <br />
          <div>
            Suggested Patient: {{ suggestedUser }}
            <div class="borderedLine">
              <div v-for="(button, i) in userSuggestionButtonList" :key="i" class="but">
                <div>
                  <v-row>
                    <v-col>
                      <v-btn @click="getUserInfo(button.id)">{{ button.first_name }} {{ button.preferred_name }}
                        {{ button.last_name }} {{ button.phone }}</v-btn>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </div>
          <br />
          <v-card v-if="oneUser.UserId">
            <v-toolbar dark>
              <v-toolbar-title>
                {{ this.oneUser.basicInfo.first_name }}
                {{ this.oneUser.basicInfo.last_name }}
                <div class="inlineblocking" v-if="oneUser.basicInfo.preferred_name">
                  ({{ this.oneUser.basicInfo.preferred_name }})
                </div>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <div v-if="this.oneUser.basicInfo.first_name">
                Loyalty: {{ oneUser.basicInfo.loyaltyRating }}
              </div>
            </v-toolbar>
            <patientInformationDisplay @close="closeEditUserInfo" v-if="oneUser.UserId" :selectedEvent="oneUser"
              :key="counter" />
          </v-card>
          <br />
          <br />
        </v-col>
        <v-col cols="12" sm="6">
          <div class="onelinebreak">
            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                location:<br />
                <b>{{ locationFill.companyName }}</b>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <v-select v-model="action_with_doctor" :items="action_with_doctor_dropdown" label="Action" dense
                  outlined></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="d-flex" cols="12" sm="6">
                provider:<br />
                <b>{{ providerFill.providerName }}</b>
              </v-col>
              <v-col class="d-flex" cols="12" sm="6">
                <serviceSelector @serviceSelected="onServiceSelected" :providerId="providerFill.providerId"
                  :locationId="locationFill.locationId" />
              </v-col>
              Service is : {{ serviceTimeDisplay }}
            </v-row>
          </div>
          <br />
          {{ this.timeSlotLengthToDisplay }}
          <v-card>
            <v-container>
              {{ dateOnly }} ({{ dayOfWeek }}) Start: {{ startFill }} End: {{ displayEndFill ? displayEndFill : endFill }}
              <br />
              <v-text-field v-model="perVisitDetails" type="text" label="Notes For This Visit"></v-text-field>
            </v-container>
          </v-card>
          <br />

          <!-- <v-row align="center">
              <v-checkbox v-model="phoneEnable" hide-details class="shrink mr-2 mt-0"></v-checkbox>
              <v-text-field :disabled="!phoneEnable" v-model="checkPhone" label="Phone Reminder"></v-text-field>
            </v-row> -->
          <!-- <v-row>
            <v-checkbox v-model="emailEnable" hide-details class="shrink mr-2 mt-0"></v-checkbox>
            <v-text-field :disabled="!emailEnable" v-model="checkEmail" label="Email Reminder"></v-text-field>
          </v-row> -->



          <!-- <v-text-field v-model="color" label="Color"></v-text-field>
          <v-row justify="space-around">
            <v-color-picker
              v-model="color"
              type="color"
              label="Color"
              class="ma-2"
              :swatches="swatches"
              hide-canvas
              hide-inputs
              hide-sliders
              show-swatches
            ></v-color-picker>
          </v-row> -->
          <br />
          <v-btn color="primary" class="mb-4 mr-2" @click="cancelCreateEvent">Cancel</v-btn>



          <v-btn :disabled="!service || !UserId" type="submit" color="primary" class="mb-4" @click.stop="handleSubmit">
            <v-progress-circular indeterminate color="white" size="24" width="3" v-if="loading">
            </v-progress-circular>

            <span v-if="!loading">Create</span></v-btn>



        </v-col>
      </v-row>
    </v-form>
    <v-dialog v-model="addNewUser" width="600px">
      <v-card>
        <v-card-title>Add New Patient
          <v-spacer />
          <v-btn icon @click="addNewUser = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <newPatient @close="addedNewPatient($event)" @id="getAddedId" :minimal="true" :key="reloadAddPatient" />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: "dayViewNewEventPage",
  props: {
    dateOnly: {
      dateOnly: String,
    },
    providerFill: {
      providerFill: String,
    },
    locationFill: {
      locationFill: String,
    },
    startFill: {
      startFill: String,
    },
    endFill: {
      endFill: String,
    },
  },
  async mounted() {
    function addZero(i) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    const d = new Date();
    let h = addZero(d.getHours());
    let m = addZero(d.getMinutes());
    let s = addZero(d.getSeconds());
    this.start = h + ":" + m + ":" + s;
    this.end = h + ":" + m + ":" + s;
  },
  components: {},
  data: () => ({
    oneUser: [],
    checkEmail: null,
    checkPhone: null,
    phoneEnable: false,
    emailEnable: false,
    UserId: "",
    menu: false,
    timeSlotLengthToDisplay: "",
    timeSlots: [],
    updateTimePicker: 0,
    perVisitDetails: null,
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+/.test(v) || "Email must be valid with @",
    ],
    allowedStep: (m) => m % 5 === 0,
    doctorSelecter: null,
    action_with_doctor_dropdown: ["Initial Visit", "Follow Up"],
    reloadAddPatient: 0,
    occupation: "",
    birth_date: "",
    first_name: "",
    preferred_name: "",
    last_name: "",
    email: "",
    phone: "",
    gender: "",
    doctor: "",
    service: "",
    action_with_doctor: "",
    service_type_dropdown: [],
    action: "",
    details: "",
    start: new Date().getHours() + ":" + new Date().getMinutes(),
    end: new Date().getHours() + ":" + new Date().getMinutes(),
    userSuggestionButtonList: [],
    dbUserList: [],
    color: "#1976D2",
    tempid: "",
    searchUser: "",
    location: null,
    SelectedUserId: "",
    locationId: "",
    loading: false,
    providerId: "",
    addNewUser: false,
    address: "",
    serviceChangeEndTime: null,
    counter: 0,
    currentlyEditingNotes: false,
    checkemail: "therapistyourself@gmail.com",
    checkphone: null,
    includeFiles: true,
    enabled: false,
    includeFiles1: true,
    serviceTimeDisplay: null,
    enabled1: false,
    suggestedUser: null,
    loyaltyRating: 5,
    swatches: [
      ["#DC2127", "#FF887C"],
      ["#FFB878", "#FBD75B"],
      ["#7AE7BF", "#51B749"],
      ["#46D6DB", "#5484ED"],
    ],
    location_name_dropdown: [],
    displayEndFill: null,
    showAllInfo: false,
    hasError: false,
    tempStart: null,
  }),
  watch: {},
  computed: {
    iconName() {
      return this.loading ? "mdi-loading" : "mdi-magnify";
    },
    dayOfWeek() {
      const dayNames = ["Sun", "Mon", "Tues", "Wed", "Thur", "Fri", "Sat"];
      let localDateStr = this.dateOnly + "T00:00";
      const dateObj = new Date(localDateStr);
      return dayNames[dateObj.getDay()];
    },
  },
  methods: {
    async handleSubmit() {
      this.loading = true;
      this.loading = false;
    },
    addedNewPatient(event) {
      this.getUserInfo(event);
      this.addNewUser = false;
    },
    closeEditUserInfo(eventId) {
      this.getUserInfo(eventId);
    },
    onServiceSelected(data) {
      this.service = data;
      this.tempStart = new Date(this.dateOnly + "T" + this.startFill).getTime();
      this.serviceChangeEndTime = this.tempStart + data.milliseconds

      this.serviceTimeDisplay = this.getTimeLengthDifference(data);
      let temp = new Date(this.serviceChangeEndTime - this.$tzOffset)
      let formatT = temp.toISOString().slice(0, -1).split(":")[0] + ":" + temp.toISOString().slice(0, -1).split(":")[1]
      this.displayEndFill = formatT.split('T')[1]


      this.getTimeLengthDifference(data);
    },
    getTimeLengthDifference(time) {
      let temp = time.milliseconds / 60000;

      if (temp === 5) {
        return "5 mins";
      } else if (temp === 10) {
        return "10 mins";
      } else if (temp === 15) {
        return "15 mins";
      } else if (temp === 20) {
        return "20 mins";
      } else if (temp === 25) {
        return "25 mins";
      } else if (temp === 30) {
        return "30 mins";
      } else if (temp === 35) {
        return "35 mins";
      } else if (temp === 40) {
        return "40 mins";
      } else if (temp === 45) {
        return "45 mins";
      } else if (temp === 50) {
        return "50 mins";
      } else if (temp === 55) {
        return "55 mins";
      } else if (temp === 60) {
        return "1 hr";
      } else if (temp === 65) {
        return "1 hr 5 mins";
      } else if (temp === 70) {
        return "1 hr 10 mins";
      } else if (temp === 75) {
        return "1 hr 15 mins";
      } else if (temp === 80) {
        return "1 hr 20 mins";
      } else if (temp === 85) {
        return "1 hr 25 mins";
      } else if (temp === 90) {
        return "1 hr 30 mins";
      } else if (temp === 95) {
        return "1 hr 35 mins";
      } else if (temp === 100) {
        return "1 hr 40 mins";
      } else if (temp === 105) {
        return "1 hr 45 mins";
      } else if (temp === 110) {
        return "1 hr 50 mins";
      } else if (temp === 115) {
        return "1 hr 55 mins";
      } else if (temp === 120) {
        return "2 hrs";
      }
      this.timeSlotLengthToDisplay = temp;
      this.addTimeAndConvertBack(time.milliseconds);
    },
    addTimeAndConvertBack(time) {
      this.end = this.start;
      const parts = this.start.split(":");
      const hours = parseInt(parts[0], 10);
      const minutes = parseInt(parts[1], 10);
      let inputTimeInMs = (hours * 60 + minutes) * 60 * 1000;
      let newTimeInMs = inputTimeInMs + parseInt(time);
      let date = new Date(newTimeInMs);
      let newHours = date.getUTCHours();
      let newMinutes = date.getUTCMinutes();
      newHours = newHours < 10 ? "0" + newHours : newHours;
      newMinutes = newMinutes < 10 ? "0" + newMinutes : newMinutes;
      this.end = `${newHours}:${newMinutes}`;
    },
    async getAddedId(value) {
      this.addNewUser = false;
      this.getUserInfo(value);
    },
    async searchUserFn() {
      if (this.loading) return;
      this.loading = true;
      this.userSuggestionButtonList = [];
      try {
        const response = await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/searchUser",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: { input: this.searchUser },
        });
        for (const doc of response.data) {
          let appData = doc;
          appData.id = doc.UserId;
          this.userSuggestionButtonList.push(appData);
        }
        this.userSuggestionButtonList.sort((a, b) => {
          let nameA = a.first_name.toUpperCase(); // ignore upper and lowercase
          let nameB = b.first_name.toUpperCase(); // ignore upper and lowercase
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          // names must be equal
          return 0;
        });
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
      if (this.userSuggestionButtonList.length < 1) {
        this.suggestedUser = "No Result!";
      } else {
        this.suggestedUser = null;
      }
      if (this.searchUser.length === 0) {
        this.userSuggestionButtonList = [];
      }
    },
    cancelCreateEvent() {
      this.$emit("cancelEvent");
    },
    async getUserInfo(event) {
      let user = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getOneUser",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { userId: event },
      })
        .then((response) => {
          "thi sis one persons stuff", response.data;
          let appData = response.data;
          user = appData;
        })
        .catch((error) => console.log(error));
      this.oneUser = user;
      this.UserId = user.UserId;
      if (user.firstVisit == true) {
        this.action_with_doctor = this.action_with_doctor_dropdown[0];
      } else {
        this.action_with_doctor = this.action_with_doctor_dropdown[1];
      }
      this.checkPhone = this.oneUser.defaultPhoneNumber
      this.checkEmail = this.oneUser.defaultEmail
      this.searchUser = null;
      this.userSuggestionButtonList = [];
      this.counter++;
    },
    async addEvent() {
      this.end = this.dateOnly + "T" + this.endFill
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/createNewEvent",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          perVisitDetails: this.perVisitDetails,
          doctor: this.providerFill,
          location: this.locationFill,
          service: this.service,
          action_with_doctor: this.action_with_doctor,
          color: this.color,
          first_name: this.oneUser.basicInfo.first_name,
          name:
            this.oneUser.basicInfo.first_name +
            " " +
            this.oneUser.basicInfo.preferred_name +
            " " +
            this.oneUser.basicInfo.last_name,
          preferred_name: this.oneUser.basicInfo.preferred_name,
          last_name: this.oneUser.basicInfo.last_name,
          UserId: this.oneUser.UserId,
          calEventStart: new Date(this.dateOnly + "T" + this.startFill).getTime(),
          calEventEnd: this.serviceChangeEndTime,
          category: this.providerFill.providerName,

        },
      })
        .then(() => { })
        .catch((error) => console.log(error));
      //send email
      // if (this.emailEnable == true) {
      //   let name = this.oneUser.basicInfo.last_name
      //   let date = this.$moment(this.tempStart).format('YYYY-MM-DD')
      //   let startTime = this.$moment(this.tempStart).format('HH:mm')
      //   let endTime = this.$moment(this.serviceChangeEndTime).format('HH:mm')
      //   await this.$axios({
      //     method: "post",
      //     url: this.$axios.defaults.baseURL + "/api/sendEmail",
      //     headers: { Authorization: localStorage.getItem("accessToken") },
      //     data: {
      //       email: this.checkEmail,
      //       start: startTime,
      //       name: name,
      //       date: date,
      //       end: endTime,
      //       location: this.locationFill,
      //     },
      //   })
      //     .catch((error) => console.log(error));
      // }
      if (this.phoneEnable == true) {
        let name = this.oneUser.basicInfo.last_name
        let date = this.$moment(this.tempStart).format('YYYY-MM-DD')
        let startTime = this.$moment(this.tempStart).format('HH:mm')
        let endTime = this.$moment(this.serviceChangeEndTime).format('HH:mm')
        await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/twilioTextMessage",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: {
            phoneNumber: this.checkPhone,
            start: startTime,
            name: name,
            date: date,
            end: endTime,
          }
        }).then(() => {
          alert('Reminder Sent!')
        })
          .catch((error) => console.log(error));
      }

      this.$refs.newEventDialog = false;
      this.color = "#1976D2";
      this.SelectedUserId = null;
      this.$emit("closePage", false);
      location.reload();
    },
  },
};
</script>
<style scoped>
.row-bottom {
  align-self: flex-end;
}

.multiline {
  white-space: pre-wrap;
  width: 400px;
}

.inlineblocking {
  display: inline-block;
}

.borderedLine {
  border-style: solid;
  padding-top: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border: 2px solid;
  border-radius: 5px;
  min-height: 10vh;
}

.but {
  display: inline-block;
}
</style>
