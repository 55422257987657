<template>
  <div v-if="onDate">
    <div class="outer-box">
      <div class="centered">
        <div
          v-for="provider in providerNameOnly"
          :key="provider.providerName"
          class="inner-box"
        >
          <div class="initial">{{ provider.providerName }}</div>
          <div class="chart-container">
            <div
              class="time-bar-row"
              v-for="hour in 17"
              :key="hour"
              :data-hour="hour + 7"
            >
              <div class="time-bar">
                <template v-for="(timeSlot, index) in provider.timeSlots">
                  <div
                    class="highlighted"
                    v-if="shouldHighlight(hour, timeSlot)"
                    :style="highlightStyle(hour, timeSlot)"
                    :key="hour + '_' + index"
                  ></div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    events: { events: Object },
    date: { date: String },
    selectedProvidersList: { selectedProvidersList: Object },
    providerWorkOffTime: { providerWorkOffTime: Object },
    locationWorkOffTime: { locationWorkOffTime: Object },
  },
  name: "previewSchedule",
  data() {
    return {
      onDate: false,
      providerNameOnly: [],
    };
  },
  created() {
    this.filterEvents();
  },
  mounted() {},
  methods: {
    getLocationOffDaysDates() {
      const year = parseInt(this.date.split("-")[0]);
      const month = parseInt(this.date.split("-")[1]) - 1;
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const locationOffDaysDates = [];

      const dayToCheckMap = {
        0: "sundayCheck",
        1: "mondayCheck",
        2: "tuesdayCheck",
        3: "wednesdayCheck",
        4: "thursdayCheck",
        5: "fridayCheck",
        6: "saturdayCheck",
      };

      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getUTCDay();
        const localDate = new Date(date - this.$tzOffset)
          .toISOString()
          .slice(0, 10);
        let isOffDay;
        if (
          this.locationWorkOffTime.workTime &&
          this.locationWorkOffTime.workTime.time
        ) {
          isOffDay =
            !this.locationWorkOffTime.workTime.time[dayToCheckMap[dayOfWeek]];
        }
        if (isOffDay) {
          locationOffDaysDates.push(localDate);
        }
      }
      return locationOffDaysDates;
    },

    getProviderOffDaysDates() {
      const year = parseInt(this.date.split("-")[0]);
      const month = parseInt(this.date.split("-")[1]) - 1;
      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const providerOffDaysMap = {};
      const dayToCheckMap = {
        0: "sundayCheck",
        1: "mondayCheck",
        2: "tuesdayCheck",
        3: "wednesdayCheck",
        4: "thursdayCheck",
        5: "fridayCheck",
        6: "saturdayCheck",
      };

      this.providerWorkOffTime.workTime.forEach((doc) => {
        providerOffDaysMap[doc.provider.providerName] = [];
      });

      for (let day = 1; day <= daysInMonth; day++) {
        const date = new Date(year, month, day);
        const dayOfWeek = date.getUTCDay();
        const localDate = new Date(date - this.$tzOffset)
          .toISOString()
          .slice(0, 10);

        this.providerWorkOffTime.workTime.forEach((doc) => {
          const isProviderOffDay = !doc.time[dayToCheckMap[dayOfWeek]];

          if (isProviderOffDay) {
            providerOffDaysMap[doc.provider.providerName].push(localDate);
          }
        });
      }

      const result = [];
      for (const [providerName, listOfDates] of Object.entries(
        providerOffDaysMap
      )) {
        if (listOfDates.length > 0) {
          result.push({ providerName, listOfDates });
        }
      }
      return result;
    },

    async filterEvents() {
      const locationOffDaysDates = this.getLocationOffDaysDates();

      let providerSlots = {};

      this.selectedProvidersList.forEach((provider) => {
        providerSlots[provider.providerName] = [];
      });

      locationOffDaysDates.forEach((offDayDate) => {
        this.selectedProvidersList.forEach((provider) => {
          if (offDayDate === this.date) {
            providerSlots[provider.providerName].push({
              date: offDayDate,
              startTime: "00:00",
              endTime: "23:59",
              color: "#808080",
              providerName: provider.providerName,
            });
          }
        });
      });

      const providerOffDaysDates = this.getProviderOffDaysDates();
      providerOffDaysDates.forEach((provider) => {
        provider.listOfDates.forEach((date) => {
          if (date === this.date) {
            providerSlots[provider.providerName].push({
              date: date,
              startTime: "00:00",
              endTime: "23:59",
              color: "#808080",
              providerName: provider.providerName,
            });
          }
        });
      });

      if (
        this.locationWorkOffTime.offTime &&
        this.locationWorkOffTime.offTime.timeSlot
      ) {
        this.locationWorkOffTime.offTime.timeSlot.forEach((slot) => {
          const start = new Date(slot.start - this.$tzOffset)
            .toISOString()
            .slice(0, 10);
          const end = new Date(slot.end - this.$tzOffset)
            .toISOString()
            .slice(0, 10);
          if (start === this.date || end === this.date) {
            for (let providerName in providerSlots) {
              providerSlots[providerName].push({
                startTime: new Date(slot.start).toTimeString().slice(0, 5),
                endTime: new Date(slot.end).toTimeString().slice(0, 5),
                color: "#808080",
                providerName,
              });
            }
          }
        });
      }

      if (this.providerWorkOffTime.offTime) {
        this.providerWorkOffTime.offTime.forEach((slotArray) => {
          slotArray.forEach((slot) => {
            const start = new Date(slot.start - this.$tzOffset)
              .toISOString()
              .slice(0, 10);
            const end = new Date(slot.end - this.$tzOffset)
              .toISOString()
              .slice(0, 10);
            const providerName = slot.category;

            if (start === this.date || end === this.date) {
              if (!providerSlots[providerName]) {
                providerSlots[providerName] = [];
              }

              providerSlots[providerName].push({
                startTime: new Date(slot.start).toTimeString().slice(0, 5),
                endTime: new Date(slot.end).toTimeString().slice(0, 5),
                color: "#808080",
                providerName,
              });
            }
          });
        });
      }

      this.events.forEach((doc) => {
        const dateOnly = new Date(doc.start - this.$tzOffset)
          .toISOString()
          .slice(0, -1)
          .split("T")[0];
        if (dateOnly !== this.date) return;
        const startTime =
          new Date(doc.start).getHours() +
          ":" +
          new Date(doc.start).getMinutes();
        const endTime =
          new Date(doc.end).getHours() + ":" + new Date(doc.end).getMinutes();
        let timeSlot;
        let providerName =
          doc.category ||
          (doc.provider &&
            doc.provider[0] &&
            doc.provider[0].provider &&
            doc.provider[0].provider.providerName);
        timeSlot = {
          startTime,
          endTime,
          color: doc.color || "#2242E5",
          providerName: doc.category,
        };
        if (providerSlots[providerName]) {
          providerSlots[providerName].push(timeSlot);
        }
      });
      this.providerNameOnly = Object.keys(providerSlots).map((providerName) => {
        return { providerName, timeSlots: providerSlots[providerName] };
      });
      this.onDate = true;
    },

    shouldHighlight(hour, timeSlot) {
      const startHour = parseInt(timeSlot.startTime.split(":")[0]) - 6;
      const endHour = parseInt(timeSlot.endTime.split(":")[0]) - 6;
      return hour >= startHour && hour <= endHour;
    },
    highlightStyle(hour, timeSlot) {
      const [startHour, startMinute] = timeSlot.startTime.split(":");
      const [endHour, endMinute] = timeSlot.endTime.split(":");
      const adjustedStartHour = parseInt(startHour) - 6;
      const adjustedEndHour = parseInt(endHour) - 6;
      const startPosition =
        hour == adjustedStartHour ? (parseInt(startMinute) / 60) * 100 : 0;
      const endPosition =
        hour == adjustedEndHour ? (parseInt(endMinute) / 60) * 100 : 100;
      const highlightColor = timeSlot.color;
      return {
        left: startPosition + "%",
        width: endPosition - startPosition + "%",
        backgroundColor: highlightColor,
      };
    },
  },
};
</script>
<style scoped>
.outer-box {
  width: 100%;
  height: 100%;
  padding: 1px;
  margin: auto;
}

.inner-box {
  padding-top: 1px;

  width: 57px;
  height: 100%;
  border: 1px solid black;
  margin: 1px;
  float: left;
  box-sizing: border-box;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
}

.initial {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 8px;
}

.chart-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.time-bar-row {
  width: 49px;
  height: 0.2em;

  margin-bottom: 2px;
  position: relative;
  background-color: rgb(181, 255, 192);
}

.time-bar {
  width: 100%;
  height: 100%;
}

.highlighted {
  position: absolute;
  top: 0;
  height: 100%;
}
</style>
