<template>
  <v-app>
    <v-container fluid class="main-container">
      <v-card class="content-card">
        <v-row>
          <v-col cols="12" md="3" class="left-section">
            <div class="chat-list">
              <v-row justify="space-between" class="align-center mb-2 chat-header">
                <v-col>
                  <h2 class="chats-title">Appointments</h2>
                </v-col>
                <v-col class="text-right">
                  <v-icon small @click="addChat" class="add-chat-button">mdi-plus</v-icon>
                </v-col>
              </v-row>
              <v-list dense class="chat-list-items">
                <v-list-item-group v-model="selectedChatId">
                  <v-list-item
                    v-for="chat in chats"
                    :key="chat.id"
                    @click="selectChat(chat.id)"
                    :class="{'chat-item': true, 'chat-item-hover': selectedChatId === chat.id, 'v-list-item--active': selectedChatId === chat.id}"
                    class="chat-item-border"
                  >
                    <div class="d-flex justify-space-between align-center" style="width: 100%;">
                      <v-list-item-content>
                        <v-list-item-title>{{ chat.name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="delete-icon">
                        <v-icon @click.stop="deleteChat(chat.id)">mdi-delete</v-icon>
                      </v-list-item-action>
                    </div>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-col>
          <v-col cols="12" md="9" class="right-section">
            <v-card class="chat-window">
              <v-card-title class="chat-title">
                {{ selectedChat ? selectedChat.name : 'Chat Window' }}
              </v-card-title>
              <v-card-text class="chat-content" ref="chatContent">
                <div
                  v-for="(message, index) in selectedChat.messages"
                  :key="index"
                  class="message-container"
                  :class="message.sender"
                >
                  <span class="sender-label">{{ message.sender }}:</span>
                  <div class="message-bubble">{{ message.text }}</div>
                </div>
                <div v-if="loading" class="loading-indicator">Doctor is typing...</div>
                <div v-if="responseSent" class="response-indicator">Response sent to doctor</div>
              </v-card-text>
              <v-card-actions class="chat-input">
                <v-btn
                  icon
                  :color="isRecording ? 'red' : 'green'"
                  @click="toggleRecording"
                  class="record-button"
                >
                  <v-icon>{{ isRecording ? 'mdi-pause-circle' : 'mdi-microphone' }}</v-icon>
                </v-btn>
                <div class="input-wrapper">
                  <textarea
                    v-model="displayMessage"
                    placeholder="Type a message"
                    @keyup.enter="sendMessage"
                    ref="textField"
                    @input="adjustTextFieldHeight"
                    rows="1"
                    class="input-textarea"
                  ></textarea>
                </div>
                <v-btn @click="sendMessage" color="primary" class="analyze-button">Send</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      chats: [
        { id: 1, name: 'Appointment 1', messages: [{ sender: 'Doctor', text: 'Hi Linda, how\'s your headache these past few days?' }] },
      ],
      selectedChatId: 1,
      selectedChat: { id: 1, name: 'Appointment 1', messages: [{ sender: 'Doctor', text: 'Hi Linda, how\'s your headache these past few days?' }] },
      message: '',
      displayMessage: '',
      isRecording: false,
      recognition: null,
      interimMessage: '',
      doctorMessages: [
        'How would you rate your headache before?',
        'Any other discomforts after taking this painkiller?',
      ],
      currentDoctorMessageIndex: 0,
      loading: false,
      responseSent: false,
    };
  },
  mounted() {
    if (window.SpeechRecognition || window.webkitSpeechRecognition) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      this.recognition = new SpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.lang = 'en-US';

      this.recognition.onstart = () => {
        this.isRecording = true;
      };

      this.recognition.onresult = (event) => {
        let interimTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            this.message += event.results[i][0].transcript + ' ';
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }
        this.displayMessage = this.message + interimTranscript;
        this.adjustTextFieldHeight(); // Adjust height dynamically as words are added
      };

      this.recognition.onerror = (event) => {
        console.error('Speech recognition error detected: ' + event.error);
      };

      this.recognition.onend = () => {
        this.isRecording = false;
        this.message += this.interimMessage;
        this.interimMessage = '';
        this.adjustTextFieldHeight();
      };
    } else {
      console.warn('Speech recognition not supported in this browser.');
    }
  },
  methods: {
    addChat() {
      const newChatId = this.chats.length + 1;
      const newChatName = `Appointment ${newChatId}`;
      const newChat = { id: newChatId, name: newChatName, messages: [] };
      this.chats.push(newChat);
      this.selectedChat = newChat;
      this.selectedChatId = newChatId;
    },
    deleteChat(chatId) {
      this.chats = this.chats.filter(chat => chat.id !== chatId);
      if (this.selectedChat && this.selectedChat.id === chatId) {
        this.selectedChat = this.chats.length ? this.chats[0] : null;
        this.selectedChatId = this.selectedChat ? this.selectedChat.id : null;
      }
    },
    selectChat(chatId) {
      this.selectedChat = this.chats.find(chat => chat.id === chatId);
      this.selectedChatId = chatId;
    },
    sendMessage() {
      if (this.displayMessage.trim() && this.selectedChat) {
        this.selectedChat.messages.push({ sender: 'Patient', text: this.displayMessage.trim() });
        this.displayMessage = '';
        this.message = ''; // Clear the message after sending
        this.adjustTextFieldHeight();
        this.scrollToBottom();
        this.displayNextDoctorMessage();
      }
    },
    async displayNextDoctorMessage() {
      if (this.currentDoctorMessageIndex < this.doctorMessages.length) {
        this.loading = true;
        await new Promise(resolve => setTimeout(resolve, 750)); // 3/4 second delay
        this.selectedChat.messages.push({ sender: 'Doctor', text: this.doctorMessages[this.currentDoctorMessageIndex] });
        this.currentDoctorMessageIndex++;
        this.loading = false;
        this.scrollToBottom();
      } else {
        this.responseSent = true;
      }
    },
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      if (this.recognition) { 
        this.recognition.start();
      }
    },
    stopRecording() {
      if (this.recognition) {
        this.recognition.stop();
      }
    },
    adjustTextFieldHeight() {
      this.$nextTick(() => {
        const textField = this.$refs.textField;
        if (textField) {
          textField.style.height = 'auto';
          const scrollHeight = textField.scrollHeight;
          const maxHeight = parseInt(getComputedStyle(textField).lineHeight, 10) * 4;
          if (scrollHeight > maxHeight) {
            textField.style.height = `${maxHeight}px`;
            textField.scrollTop = textField.scrollHeight; // Scroll to the bottom
          } else {
            textField.style.height = `${scrollHeight}px`;
          }
        }
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContent = this.$refs.chatContent;
        chatContent.scrollTop = chatContent.scrollHeight;
      });
    }
  }
};
</script>

<style>
.left-section { 
  color: white;
  height: calc(100vh - 8%);
  padding: 0;
}
.right-section {
  background-color: #f0f0f0;
  height: calc(100vh - 8%);
  padding: 0;
}
.main-container {
  padding: 2%;
  background-color: #d3d3d3;
}
.content-card {
  padding: 16px;
}
html, body, #app {
  height: 96%;
  margin: 0;
  background-color: #d3d3d3;
}
.chat-list {
  height: 100%;
  padding: 16px;
}
.chat-header {
  margin-bottom: 0;
  height: auto;
}
.chats-title {
  color: black;
  margin: 0;
  padding: 2vh 1.1vw;
  display: flex;
  align-items: flex-end; /* Align the text to the bottom */
}
.chat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin: 8px 0;
  border-radius: 8px; 
  user-select: none; /* Disable text selection */
}
.chat-item-hover,
.v-list-item--active {
  background-color: #bfbfbf; /* Same as hover color */
  border-radius: 8px;
}
.chat-item:hover {
  background-color: #bfbfbf;
}
.chat-item-border {
  border: 1px solid white;
}
.delete-icon {
  margin-left: auto; /* Push the delete icon to the right */
  pointer-events: auto; /* Allow pointer events */
}
.add-chat-button {
  cursor: pointer;
  margin: 8px;
  pointer-events: auto; /* Allow pointer events */
}
.chat-window {
  display: flex;
  flex-direction: column;
  height: 98vh;
  width: 100%; /* Fill the full width of the container */
}
.chat-title {
  padding: 3.8% 2vw;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between; /* Space between title and switch */
}
.chat-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: #f0f0f0;
  word-wrap: break-word; /* Ensure long words break to the next line */
  white-space: pre-wrap; /* Handle new lines and spaces properly */
}
.message-container {
  margin-top: 24px; /* Space between messages */
  position: relative;
  text-align: right; /* Bubble container is aligned right */
  margin-left: 20%;
}
.sender-label {
  font-size: 12px;
  color: #555;
  display: block;
  margin-bottom: 4px; /* Space between label and bubble */
}
.message-bubble {
  border-radius: 8px;
  padding: 8px 16px;
  max-width: 80%;
  user-select: text;
  pointer-events: auto;
  white-space: pre-wrap; /* Handle new lines and spaces properly */
  word-wrap: break-word; /* Ensure long words break to the next line */
  overflow-wrap: break-word; /* Ensure long words break to the next line */
  text-align: left; /* Align text left inside the bubble */
  background-color: #add8e6; /* Light blue for doctor messages */
}
.chat-input {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: white;
  width: 100%; /* Ensure chat input spans full width */
}
.record-button,
.analyze-button {
  margin: 0 8px;
  align-self: center;
}
.input-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  width: 100%; /* Ensure input wrapper spans full width */
}
.input-wrapper textarea {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px 15px;
  resize: none;
  overflow: hidden;
  line-height: 1.5;
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding is included in height */
  min-height: 40px; /* Set to single line height */
  max-height: calc(1.5em * 4); /* Sets the maximum height to 4 lines */
  overflow-y: auto; /* Allows scrolling when max height is reached */
}
.message-container.doctor {
  text-align: left;
  margin-right: 20%;
}
.message-bubble.doctor {
  background-color: #ffcccb; /* Light red for patient messages */
}
.loading-indicator {
  color: #555;
  font-style: italic;
  margin-top: 16px;
  text-align: center;
}
.response-indicator {
  color: #555;
  font-style: italic;
  margin-top: 16px;
  text-align: center;
}
</style>
