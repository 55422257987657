<template>
  <div>
    <v-card>
      <v-toolbar dark>
        <v-toolbar-title>
          {{
            oneUser && oneUser.basicInfo ? oneUser.basicInfo.first_name : ""
          }}
          {{ oneUser && oneUser.basicInfo ? oneUser.basicInfo.last_name : "" }}
          <div class="inlineblocking" v-if="oneUser && oneUser.basicInfo && oneUser.basicInfo.preferred_name
            ">
            ({{ oneUser.basicInfo.preferred_name }})
          </div>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        Loyalty: {{ loyaltyRating }}
        <v-btn icon @click="closePage">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-container>
        <patientInformationDisplay @close="getUserInfo($event)" v-if="oneUser && oneUser.UserId"
          :selectedEvent="oneUser" :key="counter" />
        <div>
          <v-container>
            <v-card class="mx-auto" outlined>
              <v-card-title>Upcoming Appointment</v-card-title>
              <v-list-item>
                <v-list-item-content>
                  <div v-if="noFutureEvents">There Are No Future Events</div>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Date</th>
                          <th class="text-left">Time</th>
                          <th class="text-left">note</th>
                          <th class="text-left">Provider</th>
                          <th class="text-left">Location</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(note, index) in futureEvents" :key="index">
                          <td>{{ note.date }}</td>
                          <td>{{ note.time }}</td>
                          <td>{{ note.note }}</td>
                          <td>{{ note.provider[0].provider.providerName }}</td>
                          <td>{{ note.location.location.companyName }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-container>
        </div>
        <br />
        <v-card class="mx-auto" outlined>
          <v-card-title>Patient Visits Analysis</v-card-title>
          <v-list-item>
            <v-list-item-content> </v-list-item-content>
          </v-list-item>
        </v-card>

        <br />
        <!-- Trigger Button for Voice Recognition Dialog -->
        <v-btn color="primary" @click="voiceReconDialog = true">
          Start Voice Recognition
        </v-btn>
        <br /><br />
        <v-btn color="primary" @click="medManDialog = true,newMedicalPageCounter++">
          Medicine Management
        </v-btn>
        <v-dialog class="higher-z-index" width="800px" v-model="medManDialog" persistent>
          <v-card class="d-flex flex-column">
            <v-toolbar text color="primary" dark>
              <v-toolbar-title>Medicine Management</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="medManDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-container class="flex-grow-1 overflow-y-auto">
              <userMedicalManagement @close="medManDialog = false" :selectedEvent="selectedEvent" :userId="selectedEvent.UserId" :key="newMedicalPageCounter" />
            </v-container>
          </v-card>
        </v-dialog>
        <br />

        <v-dialog class="higher-z-index" width="800px" v-model="voiceReconDialog" persistent>
          <v-card class="d-flex flex-column">
            <v-toolbar text color="primary" dark>
              <v-toolbar-title>Voice Recognition</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="voiceReconDialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-container class="flex-grow-1 overflow-y-auto">
              <voiceRecon :userId="selectedEvent.UserId" />
            </v-container>
          </v-card>
        </v-dialog>


        <br />
        <v-card class="mx-auto" outlined>
          <v-card-title>Payment history</v-card-title>
          <v-list-item>
            <v-list-item-content>

              <receiptPage :selectedEvent="selectedEvent" :displayOnly="true" />

            </v-list-item-content>
          </v-list-item>
        </v-card>
        <br />
        <v-card class="mx-auto" outlined>
          <v-card-title>Invoice</v-card-title>
        </v-card>
        <br />
        <v-btn red text icon @click="patientSettingsPage = true"><v-icon> mdi-cog </v-icon></v-btn><br />
      </v-container>
    </v-card>
    <v-dialog v-model="deletePatientConfirm" max-width="600">
      <div>
        <v-card>
          <v-toolbar text color="primary" dark>
            <v-toolbar-title>Are you sure you want to delete this Patient?</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="deletePatientConfirm = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card>
            <v-container>
              <v-btn color="primary" class="mb-4" @click.stop="deletePatientConfirm = false">No</v-btn>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-btn color="primary" class="mb-4" @click.stop="deletePatient()">Yes</v-btn>
            </v-container>
          </v-card>
        </v-card>
      </div>
    </v-dialog>
    <v-dialog v-model="patientSettingsPage" max-width="600">
      <div>
        <v-card>
          <v-toolbar text color="primary" dark>
            <v-toolbar-title>Manage Patient</v-toolbar-title>
            <v-spacer />
            <v-btn icon @click="patientSettingsPage = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card>
            <v-list-item>
              <v-list-item-content>
                <v-row no-gutters>
                  <v-col class="items-center" cols="12" sm="4">
                    Patient Status
                    <v-switch class="ml-5" v-model="patientStatusSwitch" @change="updatePatientStatus"></v-switch>
                    {{ pateintStatusText }}
                  </v-col>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-col class="items-center" cols="12" sm="5">
                    Date Created: <br />
                    <v-text-field v-model="date_Display_Edit" type="text" label="Date Created"></v-text-field>
                    <v-btn color="primary" class="mb-4" @click.stop="updateCreationDate()">Update</v-btn>
                  </v-col>
                  <v-divider class="mx-4" inset vertical></v-divider>
                  <v-col cols="12" sm="1">
                    <v-btn red text icon @click="deletePatientConfirm = true"><v-icon> mdi-delete </v-icon></v-btn>
                  </v-col>
                </v-row>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-card>
      </div>
    </v-dialog>
  </div>
</template>
<style scoped>
.multiline {
  white-space: pre-wrap;
}

.inlineblocking {
  display: inline-block;
}
</style>
<script>
import { VRow } from "vuetify/lib";
export default {
  components: {
    VRow,
  },
  name: "patientDetailPage",
  props: ["selectedEvent"],
  data: () => ({
    userId: null,
    UserId: "",
    details: "",
    row: null,
    oneUser: null,
    medManDialog:false,
    patientStatusSwitch: false,
    pateintStatusText: "null",
    loyaltyRating: 5,
    newMedicalPageCounter:0,
    patientInfoPageobj: [],
    date_created: "",
    date_Display_Edit: "",
    deletePatientConfirm: false,
    patientSettingsPage: false,
    allNotesVisible: false,
    noFutureEvents: false,
    futureEvents: [],
    voiceReconDialog: false,
    counter: 0,
  }),
  async mounted() {
    this.getFutureEvents();
    await this.getUserInfo(this.selectedEvent.UserId);
    if (this.oneUser.basicInfo.loyaltyRating) {
      this.loyaltyRating = this.oneUser.basicInfo.loyaltyRating;
    }
  },
  methods: {
    async getFutureEvents() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getFutureEvents",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { userId: this.selectedEvent.UserId },
      })
        .then((response) => { 

          if (!response.data[0]) {
            this.noFutureEvents = true;
          } else {
            this.futureEvents = response.data.map(event => {
              let dateTime = new Date(event.date); // Parse the timestamp

              // Format the combined date and time
              let formattedDateTime = dateTime.toLocaleDateString("en-US") + ' ' + dateTime.toLocaleTimeString("en-US");

              // Replace the original date with the formatted date-time string
              return {
                ...event,
                date: formattedDateTime
              };
            }).sort((a, b) => new Date(b.date) - new Date(a.date)); // Sorting the events

            this.noFutureEvents = false;
          }
        })
        .catch((error) => console.log(error));
    },
    async getUserInfo(event) {
      this.locationFill = null;
      this.providerFill = null;
      let user = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getOneUser",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { userId: event },
      })
        .then((response) => {
          let appData = response.data;
          user = appData;
        })
        .catch((error) => console.log(error));
      this.oneUser = user;
      this.UserId = user.UserId;
      if (this.oneUser.basicInfo.defaultLocation) {
        this.locationFill = this.oneUser.basicInfo.defaultLocation.locationId;
      }
      if (
        this.oneUser.basicInfo.defaultLocation &&
        this.oneUser.basicInfo.defaultProvider
      ) {
        this.providerFill = this.oneUser.basicInfo.defaultProvider.providerId;
      }
      this.counter++;
      this.searchUser = null;
      this.userSuggestionButtonList = [];
    },
    async updatePatientStatus(value) {
      if (value === true) {
        this.pateintStatusText = "Active";
      } else {
        this.pateintStatusText = "Inactive";
      }
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/updatePatientStatus",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.selectedEvent.UserId,
          patientStatus: value,
        },
      }).catch((error) => console.log(error));
    },
    async deletePatient() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/deleteUser",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { userId: this.oneUser.UserId },
      }).catch((error) => console.log(error));
      this.deletePatientConfirm = false;
      this.patientSettingsPage = false;
      this.$emit("close");
    },
    async updateCreationDate() {
      this.date_created =
        this.date_Display_Edit +
        "T" +
        this.patientInfoPageobj.date_created.slice(0, -1).split("T")[1];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/updateCreationDate",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.oneUser.UserId,
          updateCreationDate: this.date_created,
        },
      })
        .then(() => {
          alert("Creation Date Updated!");
        })
        .catch((error) => console.log(error));
    },
    closePage() {
      this.$emit("close");
    },
  },
};
</script>
