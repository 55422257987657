<template>
  <v-container>
    <div>
      <v-row>
        <v-col sm="9">
          <v-text-field
            v-model="newPhoneNumber"
            label="New phone number"
            :error-messages="phoneErrors"
            @input="acceptNumber()"
          ></v-text-field>
        </v-col>
        <v-col sm="1">
          <v-btn icon color="green" @click="confirmAddPhoneNumber"
            ><v-icon>mdi-tag-plus</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-list>
        <v-list-item v-for="(phone, index) in sortedPhones" :key="index">
          <v-list-item-content>
            <div
              :class="{
                'default-phone': phone.default,
                'phone-number': !phone.default,
              }"
            >
              <b>{{ phone.phoneNumber }}</b>
            </div>
          </v-list-item-content>
          <v-list-item-action v-if="phone.status">
            <v-row>
              <v-btn icon color="green" @click="makeDefault(phone)"
                ><v-icon>{{
                  phone.default
                    ? "mdi-checkbox-marked-circle-outline"
                    : "mdi-checkbox-blank-circle-outline"
                }}</v-icon></v-btn
              >
              <v-btn icon color="red" @click="promptDeletePhoneNumber(phone)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-dialog v-model="showDeleteDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline"
            >Confirmation <v-spacer />
            <v-btn icon @click="showDeleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-card-title
          >
          <v-card-text>{{ deleteDialogMessage }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isDefault"
              color="red darken-1"
              text
              @click="showDeleteDialog = false"
              >No</v-btn
            >
            <v-btn
              v-if="!isDefault"
              color="red darken-1"
              text
              @click="confirmDeletePhoneNumber"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
export default {
  components: {},
  computed: {
    sortedPhones() {
      return this.phoneNumbers.slice().sort((a, b) => b.default - a.default);
    },
  },
  name: "patientPhoneHistory",
  props: ["userId"],
  data: () => ({
    phoneToBeDeleted: null,
    showDeleteDialog: false,
    deleteDialogMessage: "",
    newPhoneNumber: "",
    phoneNumbers: [],
    phoneErrors: [],
    isDefault: false,
  }),
  async mounted() {
    this.getPhoneNumbers();
  },
  methods: {
    async getPhoneNumbers() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalPhone/get",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
        },
      })
        .then((response) => {
          if (response.data.phones) {
            this.phoneNumbers = response.data.phones;
          }
        })
        .catch((error) => console.log(error));
    },
    async addPhoneNumber() {
      this.validatePhoneInput();
      if (this.phoneErrors.length === 0) {
        await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/personalPhone/add",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: {
            userId: this.userId,
            phoneNumber: this.newPhoneNumber,
          },
        })
          .then(() => {
            this.getPhoneNumbers();
          })
          .catch((error) => console.log(error));
        this.newPhoneNumber = "";
      }
    },
    validatePhoneInput() {
      this.phoneErrors = [];
      if (!this.newPhoneNumber.trim()) {
        this.phoneErrors.push("Phone number cannot be empty");
      }
    },
    confirmAddPhoneNumber() {
      this.validatePhoneInput();
      if (this.phoneErrors.length === 0) {
        this.addPhoneNumber();
      }
    },
    async deletePhoneNumber(phone) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalPhone/delete",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
          phoneId: phone.number,
        },
      })
        .then(() => {
          this.getPhoneNumbers();
        })
        .catch((error) => console.log(error));
    },
    async makeDefault(phone) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalPhone/setDefault",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
          phone: phone,
        },
      })
        .then(() => {
          this.getPhoneNumbers();
        })
        .catch((error) => console.log(error));
    },
    setDefault(phone) {
      this.phoneNumbers.forEach((phoneItem) => {
        phoneItem.default = phoneItem === phone;
      });
    },
    promptDeletePhoneNumber(phone) {
      if (phone.default) {
        this.deleteDialogMessage =
          "This phone number is currently set as the default. It cannot be deleted.";
        this.isDefault = true;
      } else {
        this.deleteDialogMessage =
          "Are you sure you want to delete this phone number?";
        this.isDefault = false;
        this.phoneToBeDeleted = phone;
      }
      this.showDeleteDialog = true;
    },
    confirmDeletePhoneNumber() {
      if (this.phoneToBeDeleted) {
        this.deletePhoneNumber(this.phoneToBeDeleted);
        this.phoneToBeDeleted = null;
      }
      this.showDeleteDialog = false;
    },
    acceptNumber() {
      var x = this.newPhoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.newPhoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
  },
};
</script>
<style scoped>
.v-icon {
  color: blue;
}
.default-phone {
  color: green !important;
}
</style>
