import { render, staticRenderFns } from "./patientEmailHistory.vue?vue&type=template&id=4a522984&scoped=true&"
import script from "./patientEmailHistory.vue?vue&type=script&lang=js&"
export * from "./patientEmailHistory.vue?vue&type=script&lang=js&"
import style0 from "./patientEmailHistory.vue?vue&type=style&index=0&id=4a522984&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a522984",
  null
  
)

export default component.exports