<template>
  <v-app>
    <div class="flex-container">
      <!-- Adjusted sidebar with temporary prop -->
      <v-navigation-drawer v-model="sidebar" absolute temporary class="sidebar">
        <!-- Sidebar content -->
        <v-list dense>
          <v-list-item v-for="(chat, index) in chats" :key="index" @click="selectChat(chat)">
            <v-list-item-content>
              <v-list-item-title>{{ truncateText(chat.recordedText) }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>

      <!-- Main content container -->
      <div class="main-content">
        <!-- Navigation and action buttons -->
        <div class="custom-nav-container">
          <v-app-bar-nav-icon @click="toggleSidebar"></v-app-bar-nav-icon>
          <v-btn icon @click="addChat">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </div>


    <div class="chatbox-container">
      <!-- Start/Stop Recording Button at the Top -->
      <div class="controls-container">
        <div v-if="!recording">
          <v-btn color="green" @click="toggleRecognition">{{ currentTranslations.isRecording ?
            currentTranslations.stopRecording : currentTranslations.startRecognition }}</v-btn>
          <p v-if="loading">{{ currentTranslations.processing }}</p>
          <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
        </div>
        <div v-else>
          <v-btn color="red" @click="toggleRecognition"><v-icon color="red">mdi-stop</v-icon> Stop Recording</v-btn>
          <p>Recording...</p>
        </div>
      </div>

      <!-- Messages Container in the Middle -->
      <div class="flex-grow-1 messages-container" ref="container">
        <div class="userMessageWrapper">
          <v-row>
            <v-col cols="12">
              <!-- Always editable textarea linked directly to recordingDetails.text -->
              <v-textarea auto-grow label="Edit" class="edit-textbox" v-model="recordingDetails.text"></v-textarea>
              <v-textarea auto-grow v-if="analysisDone" color="white" label="Analysis" class="analysis-textbox"
                v-model="analysisText"></v-textarea>
            </v-col>
          </v-row>
        </div>
      </div>

      <!-- Upload Button at the Bottom -->
      <!-- Removed the duplicate upload button inside controls-container -->

      <div class="button-container">
        <v-btn class="action-button" :disabled="!uploadButtonEnabled" color="green" dark @click="analyzeData">{{
          currentTranslations.organizeText }}
        </v-btn>
        <v-btn class="action-button" :disabled="!uploadButtonEnabled" color="red" dark @click="sendVoiceMemo">{{
          currentTranslations.upload }}</v-btn>
      </div>

      <v-dialog v-model="uploadDialog" persistent max-width="800px" class="full-height-dialog">
  <v-card class="d-flex flex-column full-height">
    <v-card-title class="text-h5">{{ currentTranslations.uploading }}</v-card-title>
    <v-card-text class="d-flex flex-column flex-grow-1">
      <v-container class="flex-grow-1 overflow-auto">
              <v-row justify="center">
                <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
                <div v-else>Upload Complete!</div>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-alert v-model="showAlert" type="success" dismissible>
        {{ alertMessage }}
      </v-alert>

      <br /><br />
    </div>
    </div>
    </div>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      showAlert: false, // Controls the visibility of the alert
      alertMessage: '', // Stores the message to be displayed in the alert
      recording: false,
      loading: false,
      mediaRecorder: null,
      sidebar: false,
      audioChunks: [],
      recordingDetails: {
        text: '',
        isEditing: false,
        editedText: '',
      },
      chats: [],
      selectedChat: {
        "recordedText":""
    },
      analysisText: '',
      audioUrl: null,
      responseText: "",
      //currentRecordingStartTime: null,
      currentRecordingEndTime: null,
      uploadDialog: false,
      isUploadComplete: false,
      analysisDone: false,
      uploadButtonEnabled: false,
      shouldNotEdit: false,
      chunkIntervalId: null,
      recognition: null,
      selectedLanguage: 'cmn-Hans-CN',
      languages: [
        { text: '普通话', value: 'cmn-Hans-CN' },

        { text: 'English', value: 'en-US' },
        { text: '粤语', value: 'yue-Hant-HK' }
      ],
      translations: {
        'en-US': {
          changeLanguage: 'Change Language',
          startRecognition: 'Start Recognition',
          stopRecording: 'Stop Recording',
          recording: 'Recording...',
          processing: 'Processing...',
          upload: 'Upload',
          organizeText: 'Organize Text',
          uploading: 'Uploading...',
          uploadComplete: 'Upload Complete!',
        },
        'cmn-Hans-CN': {
          changeLanguage: '更改语言',
          startRecognition: '开始识别',
          stopRecording: '停止录音',
          recording: '录音中...',
          processing: '处理中...',
          upload: '上传',
          organizeText: '整理文本',
          uploading: '上传中...',
          uploadComplete: '上传完成!',
        },
        'yue-Hant-HK': {
          changeLanguage: '更改語言',
          startRecognition: '開始識別',
          stopRecording: '停止錄音',
          recording: '錄音中...',
          processing: '處理中...',
          upload: '上載',
          organizeText: '整理文本',
          uploading: '上載中...',
          uploadComplete: '上載完成!',
        }
      },
    
    };

  },
  computed: {
    currentTranslations() {
      return this.translations[this.selectedLanguage];
    },
  },
  async mounted() {

    await this.fetchChats();
    this.addChat()
  },

  props: ["userId"],
  methods: {
    processResult(event) {
      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          this.recordingDetails.text += event.results[i][0].transcript + ' ';
        }
      }
    },

    toggleRecognition() {
      if (this.recording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },


    async fetchChats() {
      try {
        await this.$axios({
          method: "post",
          url: "/api/voiceMemo/get",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: { userId: this.userId },
        }).then((response) => {
          this.chats = response.data.sort((b, a) => {
            // Convert endTime strings to Date objects for comparison
            let dateA = new Date(a.endTime);
            let dateB = new Date(b.endTime);
            
            return dateA - dateB; // Sorts in ascending order by endTime
          });
          if(this.chats.length > 0) {
        this.selectChat(this.chats[0]);
      }
         
        })
      } catch (error) {
        console.error('Failed to fetch chats:', error);
      }
    },

    addChat() {
      const newChat = {
        "recordedText": "",
      }
      this.chats.unshift(newChat);
      this.selectChat(newChat);
      this.showMessage("New Chat Created!")
    },
    async selectChat(chat) {
      try {
        this.sidebar = false;
        this.uploadButtonEnabled = true
        if (chat.analysisText) {
          this.analysisDone = true
        } else {
          this.analysisDone = false
        }
        // const response = await this.$axios.get(`/api/chats/${chat.id}`);
        this.selectedChat = chat
        this.recordingDetails.text = this.selectedChat.recordedText || '';
        this.recordingDetails.id = this.selectedChat.id,
          this.analysisText = this.selectedChat.analysisText || '';
      } catch (error) {
        console.error('Failed to fetch chat details:', error);
      }
    },
    async sendVoiceMemo() { 
      if (this.recordingDetails.text.length != 0) {
        this.currentRecordingEndTime = new Date(); // Capture the end time at upload
        this.uploadDialog = true;
        this.loading = true;
        this.isUploadComplete = false; 
        try {
          await this.$axios({
            method: "post",
            url: "/api/voiceMemo/upload",
            headers: { Authorization: localStorage.getItem("accessToken") },
            data: {
              userId: this.userId,
              id: this.recordingDetails.id,
              recordedText: this.recordingDetails.text,
             // startTime: this.currentRecordingStartTime,
              analysisText: this.analysisText,
              endTime: this.currentRecordingEndTime, // Now includes the end time
            },
          });
          this.fetchChats()
          this.loading = false;
          this.isUploadComplete = true;
        } catch (error) {
          console.error(error);
          this.loading = false;
        } finally {
          setTimeout(() => {
            this.uploadDialog = false;
            this.isUploadComplete = false;
          }, 2000);
        }
      } else {
        alert("Please enter something before upload.");
      }
    },

    async startRecording() {
      this.requestWakeLock()
      if (this.recording) {
        alert("Already recording!");
        return;
      }
      this.shouldNotEdit = true
      this.recordingDetails.isEditing = false;
      this.recording = true;
      this.uploadButtonEnabled = false
     // this.currentRecordingStartTime = new Date();
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        this.handleRecording(stream);
        // Set up an interval to handle chunks every 20 seconds
        this.chunkIntervalId = setInterval(() => {
          // Stop the recording which triggers the onstop event
          this.mediaRecorder.stop();
          // Immediately start a new recording session
          this.handleRecording(stream);
        }, 8000); // 6 seconds
      } catch (error) {
        console.error("Error accessing the microphone", error);
        this.recording = false;
      }
    },

    handleRecording(stream) {
      this.mediaRecorder = new MediaRecorder(stream);
      this.audioChunks = [];
      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };
      this.mediaRecorder.onstop = async () => {
        const audioBlob = new Blob(this.audioChunks, { type: "audio/mp3" });
        this.audioUrl = URL.createObjectURL(audioBlob);
        await this.uploadAudio(audioBlob); // Upload the chunk
        this.audioChunks = []; // Clear the chunks after uploading
      };
      this.mediaRecorder.start();
    },

    stopRecording() {
      this.shouldNotEdit = false
      this.loading = true
      this.uploadButtonEnabled = true
      if (!this.recording) {
        alert("Not recording!");
        return;
      }

      this.currentRecordingEndTime = new Date(); // Track session end time
      this.recording = false;
      clearInterval(this.chunkIntervalId);
      if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
        this.mediaRecorder.stop();
      }
    },

    async uploadAudio(audioBlob) {
      try {

        const formData = new FormData();
        formData.append("file", audioBlob, "recording.mp3");
        await this.$axios({
          method: "post",
          url: "/api/voiceMemo",
          headers: {
            Authorization: localStorage.getItem("accessToken"),
          },
          data: formData,
        })
          .then((response) => {
            this.recordingDetails.text += (this.recordingDetails.text ? " " : "") + response.data.text;
            this.loading = false;
            this.scrollToBottom();
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
      } catch (error) {
        console.error("Error uploading file", error);
        this.loading = false;
      }
    },
    truncateText(text) {
      if (!text) return "New Voice Record";
      const maxLength = 50; // Adjust this value based on your sidebar's width
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...'; // Truncate and add ellipsis
      }
      return text; // Return the text unmodified if it's short enough
    },
    enableEditing() {
      this.uploadButtonEnabled = false
      this.recordingDetails.isEditing = true;
      this.recordingDetails.editedText = this.recordingDetails.text;
    },
    saveEdit() {
      this.uploadButtonEnabled = true
      this.recordingDetails.isEditing = false;
      this.recordingDetails.text = this.recordingDetails.editedText;
    },
    analyzeData() {
      this.loading = true
      this.$axios({
        method: "post",
        url: "/api/langChain",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          questionBody: this.recordingDetails.text,
        },
      }).then((doc) => {
        this.analysisText = doc.data.answer;
        this.analysisDone = true
        this.scrollToBottom();
        this.loading = false
      }).catch((error) => {
        console.error('An error occurred:', error);
        this.showMessage('error uploading text')
        this.loading = false;
      });
  
    },

    async requestWakeLock() {
      if ('wakeLock' in navigator) {
        try {
          const wakeLock = await navigator.wakeLock.request('screen');
          wakeLock.addEventListener('release', () => {
            this.showMessage('Wake Lock was released');
          });
          this.showMessage('Wake Lock is active');
        } catch (err) {
          this.showMessage(`${err.name}, ${err.message}`);
        }
      } else {
        this.showMessage('Wake Lock API is not supported in this browser.');
      }
    },
    showMessage(message) {
      this.alertMessage = message;
      this.showAlert = true; // Show the alert

      // Hide the alert after 3 seconds (3000 milliseconds)
      setTimeout(() => {
        this.showAlert = false;
      }, 2000);
    },
    toggleSidebar() {
  this.sidebar = !this.sidebar;
 
},

    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$refs.container; // Assuming you have a ref="container" on your messages container
        container.scrollTop = container.scrollHeight;
      });
    },
  },
};
</script>

<style scoped>
.chatbox-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  /* Use the full height of the viewport */
  justify-content: space-between;
  /* Space between top and bottom elements */
}

.controls-container {
  text-align: center;
  padding: 10px;
}

.messages-container {
  flex-grow: 1;
  /* Allow this container to fill the space between buttons */
  overflow-y: auto;
  /* Add scroll to this container */
  margin: 20px 0;
  /* Add some space between the text and the buttons */
  padding: 0 20px;
  /* Padding inside the messages container */
  border: 1px solid #ccc;
  /* Optional: adds a border around the messages container */
}
.textarea-container {
  position: relative;
}

.edit-save-buttons {
  position: absolute;
  right: 0;
  bottom: 0;
  background: transparent;
}
.userMessageWrapper {
  display: flex;
  align-items: center;
  /* Align items vertically in the center */
  margin-bottom: 10px;
  /* Space between messages */
}


.userMessageContent,
.edit-textbox {
  background-color: #c5c5c5;
  /* Example color: light blue */

  width: 100%;
  height: auto;
  /* Adjust height automatically */
  overflow-y: hidden;
  /* Ensure it fills the container */
  padding: 10px;
  /* Padding inside each message for better readability */
  margin-right: 10px;
  /* Space between message and edit icon */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */

  /* Light background for the message content */
  border-radius: 5px;
  /* Rounded corners for the message boxes */
}

.edit-icon {
  cursor: pointer;
  /* Change cursor to pointer when hovering over the icon */
}

.button-container {
  display: flex;
  width: 100%;
  /* Ensure the container spans the full width */
  justify-content: space-between;
}

.analysis-textbox {
  background-color: #c5c5c5;
  /* Example color: light blue */
  color: white;
  /* Text color */
  /* Add any other styling you want for the analysis textarea */
}

.action-button {
  flex: 1;
  flex-basis: calc(50% - 5px);
  /* Assuming a total of 10px space between the buttons */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
}

/* Adjust if you want a specific margin between buttons */
.action-button:not(:last-child) {
  margin-right: 10px;
  /* Adjust based on your design needs */
}

.custom-nav-container {
  width: 56px;
  /* Example width, adjust as needed */
  height: 56px;
  /* Matching height for consistency */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 8px;
  /* Adjust margin as needed for positioning */
}
 

.full-height-dialog .v-card {
  height: 80vh; /* Adjust the height of the dialog */
  display: flex;
  flex-direction: column;
  justify-content: center; /* Centers the content vertically */
  margin: auto 0; /* Additional vertical centering for older browsers */
}

.full-height-dialog .v-card-text {
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allows this element to grow and fill available space */
}

.full-height-dialog .v-container {
  overflow-y: auto; /* Makes content within container scrollable */
  flex-grow: 1; /* Ensures the container uses available vertical space */
}
.flex-container {
  display: flex;
}

.sidebar {
  width: 300px; /* or whatever width you prefer */
}

.main-content {
  transition: margin-left .3s;
  width: 100%;
}


</style>