import Vue from "vue";
import Router from "vue-router";
import Login from "../views/loginPage.vue";
import Month from "../views/userCalendarMonthView.vue";
import Day from "../views/userCalendarDayView.vue";
import ClientDemo from "../components/demoVoice/clientDemo.vue"
import PatientDemo from "../components/demoVoice/patientDemo.vue"

Vue.use(Router);

var router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/month",
    },
    {
      path: "/client",
      name: "Client Demo",
      meta: { showToolbar: false },
      component: ClientDemo,
    },
    {
      path: "/patient",
      name: "Patient Demo",
      meta: { showToolbar: false },
      component: PatientDemo,
    },
    {
      path: "/loginPage",
      name: "Login",
      component: Login,
    },
    {
      path: "/month",
      name: "Month",
      component: Month,
    },
    {
      path: "/day",
      name: "Day",
      component: Day,
    },
 
  ],
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.accessToken;

  // Check if the navigation is redundant
  if (to.path === from.path) {
    next(false); // Abort the current navigation
    return;
  }

  // Check for authentication at the initial route.
  if (to.path === "/") {
    if (isAuthenticated) {
      next("/month");
    } else {
      next("/loginPage");
    }
    return;
  }

  // Existing logic for handling login and month views.
  if (isAuthenticated && to.path === "/loginPage") {
    next("/month");
    return;
  }

  if (!isAuthenticated && to.path !== "/loginPage") {
    next("/loginPage");
    return;
  }

  const lOpItem = localStorage.getItem("lOp");
  const lOpObject = lOpItem && JSON.parse(lOpItem);

  if (
    !lOpObject &&
    to.path !== "/loginPage" &&
    to.path !== "/month" &&
    to.path === "/"
  ) {
    next("/month");
    return;
  }

  next();
});


export default router;
