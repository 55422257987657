<template>
  <div>
    <v-card-title class="text-center">
      Medicine Usage
      <v-spacer></v-spacer>
    </v-card-title>
    <v-alert type="error" v-if="errorMessage" dense dismissible @click="clearError">
      {{ errorMessage }}
    </v-alert>
    <v-tabs background-color="white" class="elevation-1">
      <v-tab>Medicine Management</v-tab>
      <v-tab>Medicine Usage Table</v-tab>
      <v-tab-item>
        <v-card>
          <v-card-text>
            <v-row class="ma-0">
              <v-col cols="4" class="py-0">
                <v-checkbox v-model="languageFilters.chinese" label="中文" class="my-1"></v-checkbox>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-checkbox v-model="languageFilters.english" label="English" class="my-1"></v-checkbox>
              </v-col>
              <v-col cols="4" class="py-0">
                <v-checkbox v-model="languageFilters.latin" label="Latin" class="my-1"></v-checkbox>
              </v-col>
              <br />
              <v-divider></v-divider>
              <br />
              <v-col cols="8" class="py-0">
                <div class="d-flex justify-center">
                  <v-autocomplete v-model="selectedProduct" label="Search Product" outlined auto-select-first
                    append-outer-icon="mdi-magnify" :items="autocompleteOptions" :item-text="getAutocompleteItemText"
                    :item-value="getAutocompleteItemValue" @update:search-input="fetchAutocompleteOptions"
                    @change="getLeftOver" return-object class="my-4" solo flat></v-autocomplete>
                </div>
              </v-col>
            </v-row>
        
            <v-row v-if="selectedProduct">
              <v-col>
                <div>{{ displayProductName }}</div>
              </v-col>
              <v-col>
                <div>Stock Remaining: {{ selectedProduct.leftoverStock }} (g)</div>
              </v-col>
              <v-col>
                <v-text-field v-model="selectedProduct.amountUsed" label="Amount Used (g)" type="number" outlined solo
                  class="my-0"></v-text-field>
              </v-col>
              <v-col>
                <v-btn color="primary" @click="addMedicine"><v-icon>mdi-plus</v-icon></v-btn>
              </v-col>
            </v-row>

            <br />
            <div v-for="(med, index) in prescription" :key="index" class="my-2">
              <v-chip label color="blue" outlined @click="removeMedicine(index)">
                {{ med.name }} - {{ med.amountUsed }}g
                <v-icon right>mdi-close-circle</v-icon>
              </v-chip>
            </div>
            <br />
            <v-divider></v-divider>
            <br />
            <v-row>
              <v-col>
                How Many Times
                <v-text-field v-model="usage.time" type="number" outlined solo></v-text-field>
              </v-col>
              <v-col class="d-flex align-center justify-center" cols="1">
                <v-icon>mdi-close</v-icon>
              </v-col>
              <v-col>
                How many Days
                <v-text-field v-model="usage.day" type="number" outlined solo></v-text-field>
              </v-col>
              <v-col>
                <v-btn color="primary" v-if="prescription.length >= 1 && !selectedProduct" @click="addMultipleRecords">Save</v-btn>
              </v-col>
            </v-row>

            <v-card v-if="part2and3">
              <v-card-text>
                <v-divider></v-divider>
                <div>
                  <p v-for="med in prescription" :key="med.name">
                    {{ med.name }} - {{ med.amountUsed }}g used
                  </p>
                  <p>
                    {{ usage.time }} times in {{ usage.day }} days
                  </p>
                </div>
                <v-divider></v-divider>
                <div>
                  <p v-for="med in prescription" :key="med.name">
                    Total for {{ med.name }}: {{ calculateTotalGrams(med.amountUsed) }}g
                  </p>
                </div>
                <v-divider></v-divider>
              </v-card-text>
            </v-card>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-data-table :headers="tableHeaders" :items="usageData" class="elevation-1" sort-by="timestamp"
          sort-desc=""></v-data-table>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
export default {
  data() {
    return {
      part2and3: false,
      usage: {
        time: '',
        day: ''
      },
      selectedProduct: {
        id: null,
        name_ch: '',
        name_en: '',
        name_la: '',
        leftoverStock: 0,
        amountUsed: 0,
        lotNumber: '',
      },
      languageFilters: {
        chinese: true,
        english: false,
        latin: false
      },
      autocompleteOptions: [], 
      errorMessage: '',
      menu: false,
      location: null,
      locationId: null,
      usageData: [],
      prescription: [],
      tableHeaders: [
        { text: 'Usage Date', value: 'timestamp' },
        { text: 'Name', value: 'name_ch' },
        { text: 'Name', value: 'name_en' },
        { text: 'Name', value: 'name_la' },
        { text: 'Amount Used(g)', value: 'amountUsed' },
      ]
    };
  },
  props: ['selectedEvent'],
  computed: {
    displayProductName() {
      if (!this.selectedProduct) return '';
      if (this.languageFilters.chinese && this.selectedProduct.name_ch) {
        return this.selectedProduct.name_ch + (this.selectedProduct.name_pinyin ? ' (' + this.selectedProduct.name_pinyin + ')' : '');
      } else if (this.languageFilters.english && this.selectedProduct.name_en) {
        return this.selectedProduct.name_en;
      } else if (this.languageFilters.latin && this.selectedProduct.name_la) {
        return this.selectedProduct.name_la;
      }
      return '';  // Fallback if no filters are selected
    },
    getAutocompleteItemText() {
      return (item) => {
        let parts = [];
        if (this.languageFilters.chinese && item.name_ch) {
          let displayName = item.name_ch;
          if (item.name_pinyin) {
            displayName += ' (' + item.name_pinyin + ')';
          }
          parts.push(displayName);
        }
        if (this.languageFilters.english && item.name_en) {
          parts.push(item.name_en);
        }
        if (this.languageFilters.latin && item.name_la) {
          parts.push(item.name_la);
        }
        return parts.join(' ');
      };
    },
    getAutocompleteItemValue() {
      return (item) => item.id;
    },
  },
  mounted() {
    const storedOption = localStorage.getItem("lOp");
    if (storedOption) {
      this.location = JSON.parse(storedOption);
      this.locationId = this.location.locationId;
    }
    this.fetchPatientUsage()
  },
  methods: {
    addMedicine() {
      if (!this.selectedProduct || this.selectedProduct.amountUsed <= 0 || this.selectedProduct.amountUsed > this.selectedProduct.leftoverStock) {
        this.errorMessage = "Please select a product, specify a valid amount, and ensure it does not exceed the stock.";
        this.selectedProduct = null
        return;
      }

      const medicineToAdd = {
        name: this.displayProductName,
        amountUsed: this.selectedProduct.amountUsed,
        selectedProduct: this.selectedProduct
      };
      this.prescription.push(medicineToAdd); 
      this.selectedProduct.amountUsed = 0; // Optionally reset the amount after adding
      this.selectedProduct = null; // Reset selected product to clear display
    },
    removeMedicine(index) {
      this.prescription.splice(index, 1);
    },
    clearError() {
      this.errorMessage = '';
    },
    fetchPatientUsage() {
      this.$axios.post("/api/patientProducts/getPatientUsage", {
        userId: this.selectedEvent.UserId,
      })
        .then(response => {
          this.usageData = response.data;
          this.usageData.forEach((doc) => {
            doc.timestamp = new Date(doc.timestamp.seconds * 1000).toLocaleDateString()
          })
          console.log('backdata', this.usageData)
        })
        .catch(error => {
          console.error("Failed to fetch usage data:", error);
          this.errorMessage = "Failed to fetch usage data.";
        });
    },
    fetchAutocompleteOptions(input) {
      if (!input || input.trim().length < 1) {
        this.autocompleteOptions = [];
        return;
      }
      this.$axios.post("/api/patientProducts/searchProducts", {
        searchText: input,
      })
        .then(response => {
          this.autocompleteOptions = response.data;
        })
        .catch(error => {
          console.error("Error fetching autocomplete options:", error);
          this.errorMessage = "Failed to fetch autocomplete options.";
        });
    },
    getLeftOver(item) {
      this.$axios.post("/api/patientProducts/getProductStock", {
        locationId: this.locationId,
        productId: item.id
      })
        .then((response) => {
          this.$set(this.selectedProduct, 'leftoverStock', response.data.leftoverStock || 0);
          this.$set(this.selectedProduct, 'lotNumber', response.data.lotNumber || 0);
        })
        .catch(error => {
          console.error("Failed to get stock:", error);
          this.errorMessage = "An error occurred while getting stock history. Please try again.";
        });
    },
    addMultipleRecords() {
        this.$axios.post("/api/patientProducts/addMultipleRecords", {
          prescription: this.prescription,
          usage: this.usage,
          userId: this.selectedEvent.UserId
        })
          .then(() => {
            this.part2and3 = true; // Display part2and3 on success
          })
          .catch(error => {
            console.error("Failed to add record:", error);
            this.errorMessage = "An error occurred while adding the record. Please try again.";
          });
    },
    calculateTotalGrams(amountUsed) {
      return amountUsed * this.usage.time * this.usage.day;
    }
  }
};
</script>
