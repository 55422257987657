<template>
  <!-- get all locaitons -->
  <div>
    <v-select
      v-model="location"
      :item-text="'companyName'"
      :item-value="'companyName'"
      :items="locationList"
      label="Location"
      dense
      outlined
      @change="getLocationId($event)"
      return-object
    ></v-select>
  </div>
</template>
<script>
export default {
  name: "locationSelector",
  props: {
    locationId: {
      locationId: String,
    },
    locationFill: {
      locationFill: String,
    },
  },
  data: () => ({
    location: null,
    locationList: [],
  }),
  async mounted() {
    await this.updateLocationList();
    if (this.locationFill) {
      let selectedLocation = this.locationList.find( // if there is a location fill or location id, render it as the default location and return it
        (location) => location.locationId === this.locationFill
      );
      this.location = selectedLocation;
      this.$emit("locationSelected", selectedLocation);
    } else if (this.locationId) {
      let selectedLocation = this.locationList.find(
        (location) => location.locationId === this.locationId
      );
      this.location = selectedLocation;
    }
  },
  methods: {
    async updateLocationList() {
      await this.$getLocationsList();
      this.locationList = this.$LocationsListObj;
    },
    getLocationId(data) {
      this.$emit("locationSelected", data);
    },
  },
};
</script>
