<template>
  <v-container>
    <div>
      <v-row>
        <v-col sm="9">
          <v-text-field
            v-model="newEmail"
            label="New email"
            :error-messages="emailErrors"
            @input="validateEmailInput"
          ></v-text-field>
        </v-col>
        <v-col sm="1">
          <v-btn icon color="green" @click="confirmAddEmail"
            ><v-icon>mdi-tag-plus</v-icon></v-btn
          >
        </v-col>
      </v-row>
      <v-list>
        <v-list-item v-for="(email, index) in sortedEmails" :key="index">
          <v-list-item-content>
            <div
              :class="{
                'default-email': email.default,
                'email-address': !email.default,
              }"
            >
              <b>{{ email.email }}</b>
            </div>
          </v-list-item-content>
          <v-list-item-action v-if="email.status">
            <v-row>
              <v-btn icon color="green" @click="makeDefault(email)"
                ><v-icon>{{
                  email.default
                    ? "mdi-checkbox-marked-circle-outline"
                    : "mdi-checkbox-blank-circle-outline"
                }}</v-icon></v-btn
              >
              <v-btn icon color="red" @click="promptDeleteEmail(email)"
                ><v-icon>mdi-delete</v-icon></v-btn
              >
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <v-dialog v-model="showDeleteDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline"
            >Confirmation <v-spacer />
            <v-btn icon @click="showDeleteDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn></v-card-title
          >
          <v-card-text>{{ deleteDialogMessage }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="!isDefault"
              color="red darken-1"
              text
              @click="showDeleteDialog = false"
              >No</v-btn
            >
            <v-btn
              v-if="!isDefault"
              color="red darken-1"
              text
              @click="confirmDeleteEmail"
              >Yes</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showEmailErrorDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="headline">Email Errors</v-card-title>
          <v-card-text>
            <div v-for="(error, index) in emailErrors" :key="index">
              {{ error }}
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="red darken-1"
              text
              @click="showEmailErrorDialog = false"
              >OK</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>
<script>
export default {
  components: {},
  computed: {
    sortedEmails() {
      return this.emails.slice().sort((a, b) => b.default - a.default);
    },
  },
  name: "patientEmailHistory",
  props: ["userId"],
  data: () => ({
    showEmailErrorDialog: false,
    emailToBeDeleted: null,
    showDeleteDialog: false,
    deleteDialogMessage: "",
    newEmail: "",
    emails: [],
    emailErrors: [],
    isDefault: false,
  }),
  async mounted() {
    this.getEmails();
  },
  methods: {
    async getEmails() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalEmail/get",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
        },
      })
        .then((response) => {
          if (response.data.emails) {
            this.emails = response.data.emails;
          }
        })
        .catch((error) => console.log(error));
    },
    async addEmail() {
      if (this.emailErrors.length === 0) {
        await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/personalEmail/add",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: {
            userId: this.userId,
            email: this.newEmail,
          },
        })
          .then(() => {
            this.getEmails();
          })
          .catch((error) => console.log(error));
        this.newEmail = "";
      }
    },
    validateEmailInput() {
      this.emailErrors = [];
      if (!this.newEmail.trim()) {
        this.emailErrors.push("Email cannot be empty");
      } else if (!this.newEmail.includes("@")) {
        this.emailErrors.push("Email should contain an '@'");
      }
    },
    confirmAddEmail() {
      this.validateEmailInput();
      if (this.emailErrors.length > 0) {
        this.showEmailErrorDialog = true;
      } else {
        this.addEmail();
      }
    },
    async deleteEmail(email) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalEmail/delete",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
          emailId: email.id,
        },
      })
        .then(() => {
          this.getEmails();
        })
        .catch((error) => console.log(error));
    },
    async makeDefault(email) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/personalEmail/setDefault",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          userId: this.userId,
          email: email.id,
        },
      })
        .then(() => {
          this.getEmails();
        })
        .catch((error) => console.log(error));
    },
    setDefault(email) {
      this.emails.forEach((emailItem) => {
        emailItem.default = emailItem === email;
      });
    },
    promptDeleteEmail(email) {
      if (email.default) {
        this.deleteDialogMessage =
          "This email address is currently set as the default. It cannot be deleted.";
        this.isDefault = true;
      } else {
        this.deleteDialogMessage =
          "Are you sure you want to delete this email address?";
        this.isDefault = false;
        this.emailToBeDeleted = email;
      }
      this.showDeleteDialog = true;
    },
    confirmDeleteEmail() {
      if (this.emailToBeDeleted) {
        this.deleteEmail(this.emailToBeDeleted);
        this.emailToBeDeleted = null;
      }
      this.showDeleteDialog = false;
    },
  },
};
</script>
<style scoped>
.v-icon {
  color: blue;
}
.default-email {
  color: green !important;
}
</style>
