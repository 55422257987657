<template>
  <div>
    <!-- select provider based on given location-->
    <v-select 
      v-model="doctorSelector"
      :items="doctor_list_dropdown"
      :item-value="'providerName'"
      name="doctor"
      :item-text="'providerName'"
      label="Provider"
      dense
      outlined
      @change="getProviderId($event)"
      return-object
    ></v-select>
  </div>
</template>
<script>
export default {
  name: "providerSelector",
  props: {
    locationId: String,
    providerId: String,
    providerFill: String,
  },
  created() { 
    this.providerID = this.providerId;
  },
  data: () => ({
    doctorSelector: null,
    doctor_list_dropdown: [],
    providerID: null,
  }),
  async mounted() { // if given providerId or providerFill, make that default
    if (this.locationId) {
      await this.updateDoctorList(this.locationId);
    }
    if (this.providerFill) {
      let selectedProviderId = this.doctor_list_dropdown.find(
        (provider) => provider.providerId === this.providerFill
      );
      if (selectedProviderId) {
        this.doctorSelector = selectedProviderId;
        this.$emit("providerSelected", selectedProviderId);
      }
    }
    if (this.providerID) {
      let selectedProviderId = this.doctor_list_dropdown.find(
        (provider) => provider.providerId === this.providerID
      );
      if (selectedProviderId) {
        this.doctorSelector = selectedProviderId;
        this.$emit("providerSelected", selectedProviderId);
      }
    }
  },
  methods: {
    async updateDoctorList(doctorId) {
      let doctors = [];
      await this.$axios({
        method: "post",
        url:
          this.$axios.defaults.baseURL + "/api/getProviderFilterWithLocation",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          locationId: doctorId,
        },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            doctors.push(appData);
          });
        })
        .catch((error) => console.log(error));
      this.doctor_list_dropdown = doctors;
    },
    getProviderId(data) {
      this.$emit("providerSelected", data);
    },
  },
};
</script>
