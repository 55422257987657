<template>
  <div class="container">
    <div class="vertical-center border">
      <form @submit.prevent="login()">
        <h2 class="mb-3">Login</h2>
        <div class="input border">
          <label for="email">Email address</label>
          <input
            class="form-control border"
            type="email"
            v-model="email"
            :rules="emailRules"
            placeholder="email here"
            required
          />
        </div>
        <div class="input border">
          <label for="password">Password</label>
          <input
            class="form-control border"
            type="password"
            v-model="password"
            placeholder="password here"
            required
          />
        </div>
        <button type="submit" class="mt-4 btn-pers" id="login_button">
          <div v-if="loading == false">Login</div>
          <div v-else class="text-center">
            <v-progress-circular
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </button>
        <div
          class="alert alert-warning alert-dismissible fade show mt-5 d-none"
          role="alert"
          id="alert_1"
        >
          <button
            type="submit"
            class="btn-close"
            data-bs-dismiss="alert"
            aria-label="Close"
          ></button>
        </div>
      </form>
    </div>
  </div>
</template>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
#content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 1px solid lightgray;
  padding: 4rem 4rem;
  border-radius: 5px;
  background: #fefefe;
}
.background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background: rgb(250, 250, 250);
}
.navbar {
  background: #fafafa;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 70px;
  box-shadow: 6.7px 6.7px 5.3px rgba(0, 0, 0, 0.028),
    22.3px 22.3px 17.9px rgba(0, 0, 0, 0.042),
    100px 100px 80px rgba(0, 0, 0, 0.07);
}
.nav {
  width: 80%;
  min-width: 400px;
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #fafafa;
}
#name_project {
  font-weight: 700;
}
.container {
  position: relative;
  height: 100%;
  width: 400px;
  max-width: 95%;
}
.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
.input > label {
  text-align: start;
}
.input > input {
  margin-top: 6px;
  height: 38px !important;
}

.btn-pers {
  position: relative;
  left: 50%;
  padding: 1em 2.5em;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  font-weight: 700;
  color: #000;
  background-color: #fff;
  border: none;
  border-radius: 45px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease 0s;
  cursor: pointer;
  outline: none;
  transform: translateX(-50%);
}
.btn-pers:hover {
  background-color: #198754;
  box-shadow: 0px 15px 20px rgba(46, 229, 157, 0.4);
  color: #fff;
  transform: translate(-50%, -7px);
}
.btn-pers:active {
  transform: translate(-50%, -1px);
}

.alternative-option {
  text-align: center;
}
.alternative-option > span {
  color: #0d6efd;
  cursor: pointer;
}
#sign_out {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}
</style>
<script>
export default {
  name: "loginPage",
  data: () => ({
    loading: false,
    email: null,
    password: null,
    emailRules: [
      (v) => !!v || "Email is required",
      (v) => /.+@.+/.test(v) || "Email must be valid with @",
    ],
  }),
  methods: {
    async login() {
      this.loading = true;
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/auth",
        data: {
          email: this.email.toLowerCase(),
          password: this.password,
        },
      })
        .then((res) => {
          if (res.data.token && res.data.refreshToken) {
            let token = res.data.token;
            let refresh = res.data.refreshToken;
            localStorage.setItem("accessToken", "Bearer " + token);
            localStorage.setItem("refresh", refresh);
            this.$root.$emit("login-success");
            this.$router.push("/");
          } else {
            alert(res.data);
            this.$root.$emit("login-failed");
            this.loading = false;
          }
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
