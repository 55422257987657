<template>
  <div>
    <br />
    <v-container>
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>Location Information</v-card-title>
            <v-container>
              <v-form @submit.prevent="editLocationInfo">
                <v-row>
                  <v-col cols="6">
                    <h2>{{ EditCompanyName }}</h2>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="EditLocationName"
                      label="Location Address"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="6">
                    <v-text-field
                      v-model="EditLocationEmail"
                      label="Location Email"
                    />
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-model="EditLocationPhone"
                      label="Location Phone Number"
                      @input="acceptLocation"
                    />
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-btn
                    type="submit"
                    color="primary"
                    class="mb-4"
                    @click.stop="addNewLocation = false"
                  >
                    Update
                  </v-btn>
                </v-card-actions>
              </v-form>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <br />

      <v-card>
        <v-card-title>Location Time Management</v-card-title>
        <v-container>
          <v-dialog v-model="addTimeSlotDialog" persistent max-width="650px">
            <v-card>
              <v-card-title>
                <span class="headline">Add Time Slot</span>
                <v-spacer />
                <v-btn icon @click="addTimeSlotDialog = false">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-menu
                    ref="menu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        type="text"
                        label="Date (Year/Month/Day)"
                        v-model="dateOnly"
                        readonly
                        required
                        @click="menu = !menu"
                        v-on="on"
                        v-bind="attrs"
                      />
                    </template>
                    <v-date-picker
                      v-model="selectedDate"
                      :events="bookedDates"
                      :event-color="eventColor"
                      no-title
                      @input="updateDate"
                      :picker-date.sync="pickerDate"
                    >
                    </v-date-picker>
                  </v-menu>

                  <div v-if="dayOfWeek">{{ dayOfWeek }}</div>
                  <span class="error--text" v-if="dateError">{{
                    dateError
                  }}</span>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <v-time-picker
                        v-model="start"
                        :allowed-minutes="allowedStep"
                        ampm-in-title
                        class="mt-4"
                        required
                      ></v-time-picker>
                    </v-col>
                    <v-col cols="12" sm="6">
                      <v-time-picker
                        v-model="end"
                        :allowed-minutes="allowedStep"
                        ampm-in-title
                        class="mt-4"
                        required
                      ></v-time-picker>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3">
                      <v-checkbox
                        v-model="fullDay"
                        label="Full Day"
                        @change="toggleFullDay"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="3"> </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="addTimeSlotDialog = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  color="blue darken-1"
                  text
                  @click="addTimeSlot"
                  :disabled="!isFormValid"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-row>
            <v-col cols="4">
              <h1>Location Work Days</h1>
              <v-card max-width="350px">
                <v-checkbox
                  v-model="dateTime.mondayCheck"
                  label="Monday"
                ></v-checkbox>
                <div v-if="dateTime.mondayCheck">
                  <time-range-picker
                    v-model="dateTime.monday"
                    inputLabel="Work Hours"
                    hide-details
                    hide-selected
                    outlined
                    outerIcon=""
                    step="15"
                  />
                </div>
                <v-checkbox
                  v-model="dateTime.tuesdayCheck"
                  label="Tuesday"
                ></v-checkbox>
                <div v-if="dateTime.tuesdayCheck">
                  <time-range-picker
                    v-model="dateTime.tuesday"
                    inputLabel="Work Hours"
                    hide-details
                    hide-selected
                    outerIcon=""
                    outlined
                    step="15"
                  />
                </div>
                <v-checkbox
                  v-model="dateTime.wednesdayCheck"
                  label="Wednesday"
                ></v-checkbox>
                <div v-if="dateTime.wednesdayCheck">
                  <time-range-picker
                    v-model="dateTime.wednesday"
                    inputLabel="Work Hours"
                    hide-details
                    hide-selected
                    outlined
                    outerIcon=""
                    step="15"
                  />
                </div>
                <v-checkbox
                  v-model="dateTime.thursdayCheck"
                  label="Thursday"
                ></v-checkbox>
                <div v-if="dateTime.thursdayCheck">
                  <time-range-picker
                    v-model="dateTime.thursday"
                    inputLabel="Work Hours"
                    hide-details
                    hide-selected
                    outerIcon=""
                    outlined
                    step="15"
                  />
                </div>
                <v-checkbox
                  v-model="dateTime.fridayCheck"
                  label="Friday"
                ></v-checkbox>
                <div v-if="dateTime.fridayCheck">
                  <time-range-picker
                    v-model="dateTime.friday"
                    inputLabel="Work Hours"
                    hide-details
                    hide-selected
                    outlined
                    outerIcon=""
                    step="15"
                  />
                </div>
                <v-checkbox
                  v-model="dateTime.saturdayCheck"
                  label="Saturday"
                ></v-checkbox>
                <div v-if="dateTime.saturdayCheck">
                  <time-range-picker
                    v-model="dateTime.saturday"
                    inputLabel="Work Hours"
                    hide-details
                    hide-selected
                    outerIcon=""
                    outlined
                    step="15"
                  />
                </div>
                <v-checkbox
                  v-model="dateTime.sundayCheck"
                  label="Sunday"
                ></v-checkbox>
                <div v-if="dateTime.sundayCheck">
                  <time-range-picker
                    v-model="dateTime.sunday"
                    inputLabel="Work Hours"
                    hide-details
                    hide-selected
                    outlined
                    outerIcon=""
                    step="15"
                  />
                </div>
              </v-card>
              <v-btn @click="updateTime" color="primary">update the time</v-btn>
            </v-col>
            <v-col cols="8">
              <v-col cols="auto">
                <v-btn color="primary" dark @click="addTimeSlotDialog = true"
                  >Add Time Slot</v-btn
                >
              </v-col>
              <v-row>
                <v-card max-width="290">
                  <v-date-picker
                    v-model="selectedDate"
                    :events="bookedDates"
                    :event-color="eventColor"
                    no-title
                    @input="updateDate"
                    :picker-date.sync="pickerDate"
                  >
                  </v-date-picker>
                </v-card>

                <v-data-table
                  :headers="timeSlotHeader"
                  :items="individualDocLocationListItem"
                  :items-per-page="-1"
                  class="elevation-1"
                  :custom-sort="customSort"
                >
                  <template v-slot:[`item.actions`]="{ item }">
                    <v-icon small class="mr-2" @click="deleteTimeSlot(item)"
                      >mdi-delete</v-icon
                    >
                  </template>
                  <template v-slot:[`item.dateOnly`]="{ item }">
                    <span :class="{ 'grey-out': isPastDate(item.dateOnly) }">{{
                      item.dateOnly
                    }}</span>
                  </template>
                </v-data-table>
              </v-row>
            </v-col>
          </v-row>

          <br />
          <br />
          <br />
          <br />
          <br />
          <br />
        </v-container>
      </v-card>
      <br />
      <v-card>
        <v-card-title>Provider Management</v-card-title>
        <v-container>
          <v-btn color="primary" @click="addNewProvider()">
            New Provider
          </v-btn>
          <br />
          <v-card color="#f1f2f6">
            <div>
              <v-tabs>
                <v-tab
                  v-for="provider in provider_list_dropdown"
                  :key="provider.providerId"
                >
                  {{ provider.providerName }}
                </v-tab>

                <v-tab-item
                  v-for="provider in provider_list_dropdown"
                  :key="provider.providerId"
                >
                  <v-card>
                    <v-card-title>Provider Service Management</v-card-title>
                    <AvailableService
                      :providerId="provider.providerId"
                      :key="provider.providerId"
                      :locationId="selectedLocation.locationId"
                    />
                  </v-card>
                  <v-card>
                    <v-card-title> Provider Time Management </v-card-title>
                    <v-card-text>
                      <ProviderTimeManagement
                        :providerId="provider.providerId"
                        :key="provider.providerId + ' ' + providerCounter"
                        :locationId="selectedLocation.locationId"
                      />
                    </v-card-text>
                  </v-card>
                </v-tab-item>
              </v-tabs>
            </div>
          </v-card>
        </v-container>
      </v-card>
      <v-dialog
        v-if="newProviderPage"
        v-model="newProviderPage"
        max-width="600px"
      >
        <v-card flat>
          <v-card-title>
            <span class="headline">Add Provider</span>
            <v-spacer />
            <v-btn icon @click="newProviderPage = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6">
                <v-select
                  return-object
                  v-model="providerNameDropdown"
                  :items="provider_name_dropdown"
                  label="Default Provider"
                  item-text="providerName"
                  dense
                  outlined
                />
              </v-col>
            </v-row>
            <v-btn
              :disabled="!providerNameDropdown"
              color="primary"
              class="mr-4"
              @click="addProvider"
              >Add Provider</v-btn
            >
          </v-card-text>
        </v-card>
      </v-dialog>
      <br />

      <v-card> </v-card>
    </v-container>
  </div>
</template>
<script>
import ProviderTimeManagement from "../ProviderSettingsPages/ProviderTimeManagement.vue";
import AvailableService from "../ProviderSettingsPages/AvailableService.vue";
export default {
  name: "settingsPage",
  props: ["selectedLocation"],
  components: {
    AvailableService,
    ProviderTimeManagement,
  },
  data: () => ({
    range: {
      start: "07:00",
      end: "21:00",
    },
    currentYear: new Date().getFullYear(),
    currentMonth: new Date().getMonth() + 1,
    dateTime: {
      mondayCheck: false,
      tuesdayCheck: false,
      wednesdayCheck: false,
      thursdayCheck: false,
      fridayCheck: false,
      saturdayCheck: false,
      sundayCheck: false,
      monday: {
        start: "07:00",
        end: "21:00",
      },
      tuesday: {
        start: "07:00",
        end: "21:00",
      },
      wednesday: {
        start: "07:00",
        end: "21:00",
      },
      thursday: {
        start: "07:00",
        end: "21:00",
      },
      friday: {
        start: "07:00",
        end: "21:00",
      },
      saturday: {
        start: "07:00",
        end: "21:00",
      },
      sunday: {
        start: "07:00",
        end: "21:00",
      },
    },
    hideCheckbox: true,
    selectedTime: null,
    milliseconds: null,

    EditCompanyName: null,
    EditLocationName: null,
    EditLocationEmail: null,
    EditLocationPhone: null,
    serviceCounter: 0,
    timeCounter: 0,
    providerCounter: 0,
    deletepopup: false,
    SelectedDoctorId: null,
    provider_name_dropdown: [],
    newProviderPage: false,
    providerNameDropdown: null,
    menu: false,
    date: null,
    selectedDate: null,
    pickerDate: new Date().toISOString().substr(0, 7),
    start: null,
    end: null,
    allowedStep: (m) => m % 15 === 0,
    isWeekly: false,
    fullDay: false,
    dateOnly: "",
    addTimeSlotDialog: false,
    timeSlots: [],
    timeSlotHeader: [
      { text: "Date", value: "dateOnly" },
      { text: "Start", value: "start" },
      { text: "End", value: "end" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    docLocationListHeader: [
      {
        text: "Location Name",
        align: "start",
        value: "location.companyName",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    individualDocLocationListItem: [],
    providerListHeader: [
      {
        text: "Provider Name",
        align: "start",
        value: "providerName",
      },
      { text: "Phone Number", value: "providerPhone" },
      { text: "Email", value: "providerEmail" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    uniqueId: null, 
    provider_list_dropdown: [],
    locationWorkHour: [],

  }),
  async mounted() {
    this.EditCompanyName = this.selectedLocation.companyName;
    this.EditLocationName = this.selectedLocation.location;
    this.EditLocationEmail = this.selectedLocation.email;
    this.EditLocationPhone = this.selectedLocation.phone;
    await this.updateDoctorList();
    await this.getLocationOffTimeList();
    await this.fetchLocationWorkTime();
  },
  watch: {
    pickerDate(val) {
      this.currentMonth = val.split("-")[1];
      this.currentYear = val.split("-")[0];
      this.bookedDates;
    },
  },
  computed: {
    bookedDates: function () {
      if (
        !this.locationWorkHour ||
        Object.keys(this.locationWorkHour).length === 0
      ) {
        return [];
      }

      const currentMonth = this.currentMonth - 1;

      const currentYear = this.currentYear;
      const daysOfWeek = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const bookedWeekdays = daysOfWeek.filter((day) => {
        return this.locationWorkHour[day + "Check"] === false;
      });

      const datesForMonth = [];
      for (let i = 1; i <= 31; i++) {
        const dateObj = new Date(currentYear, currentMonth, i);
        if (dateObj.getMonth() === currentMonth) {
          const dayName = daysOfWeek[dateObj.getDay()];
          if (bookedWeekdays.includes(dayName)) {
            datesForMonth.push(dateObj.toISOString().split("T")[0]);
          }
        }
      }

      return [
        ...this.individualDocLocationListItem.map(
          (timeSlot) => timeSlot.dateOnly
        ),
        ...datesForMonth,
      ];
    },

    dayOfWeek() {
      if (this.dateOnly) {
        const date = new Date(this.dateOnly);
        const days = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
          "Sunday",
        ];
        return days[date.getDay()];
      }
      return "";
    },
    dateError() {
      if (!this.dateOnly) {
        return "Date is required.";
      }
      if (this.isPastDate(this.dateOnly)) {
        return "Cannot book past dates.";
      }
      if (this.isDateBooked(this.dateOnly)) {
        return "This date is already booked.";
      }
      if (this.isWeekly) {
        const date = new Date(this.dateOnly);
        const day = date.getDay();
        if (this.isDayOfWeekBlocked(day)) {
          return "This day of the week is already blocked off for weekly bookings.";
        }
      }
      if (this.isDayBookedInLocationSettings(this.dateOnly)) {
        return "This day of the week is booked.";
      }
      return "";
    },
    isFormValid() {
      return this.dateError === "" && this.start && this.end;
    },
  },
  methods: {
    isDayOfWeekBlocked(day) {
      return this.timeSlots.some(
        (slot) => slot.isWeekly && new Date(slot.dateOnly).getDay() === day
      );
    },
    isPastDate(date) {
      const today = new Date().toISOString().split("T")[0];
      return date < today;
    },
    isDateBooked(date) {
      return this.individualDocLocationListItem.some(
        (slot) => slot.dateOnly === date
      );
    },
    async addTimeSlot() {
      let newSlot = {};
      if (this.dateOnly && this.start && this.end) {
        this.start = this.dateOnly + "T" + this.start;
        this.end = this.dateOnly + "T" + this.end;
        newSlot = {
          startTime: new Date(this.start).getTime(),
          endTime: new Date(this.end).getTime(),
          isWeekly: false,
        };
        await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/addLocationOffTimeList",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: {
            locationId: this.uniqueId,
            time: newSlot,
          },
        }).catch((error) => console.log(error));
        this.getLocationOffTimeList();
        this.dateOnly = "";
        this.start = "";
        this.end = "";
        this.fullDay = false;
        this.providerCounter++;
        this.addTimeSlotDialog = false;
      }
    },
    eventColor(date) {
      const today = new Date().toISOString().split("T")[0];
      if (date < today) {
        return "grey";
      } else {
        const timeSlot = this.timeSlots.find((slot) => slot.dateOnly === date);
        if (timeSlot && this.start === "00:00" && this.end === "23:59") {
          return "red";
        }
        return "grey";
      }
    },
    async updateDate(date) {
      this.menu = !this.menu;
      this.selectedDate = date;
      this.dateOnly = date;
    },
    toggleFullDay() {
      if (this.fullDay) {
        this.start = "00:00";
        this.end = "23:59";
      } else {
        this.start = "";
        this.end = "";
      }
    },

    customSort(items, index, isDescending) {
      if (isDescending) {
        items.sort((a, b) => {
          if (a.isWeekly && !b.isWeekly) {
            return -1;
          }
          if (!a.isWeekly && b.isWeekly) {
            return 1;
          }
          if (this.isPastDate(a.dateOnly) && !this.isPastDate(b.dateOnly)) {
            return 1;
          }
          if (!this.isPastDate(a.dateOnly) && this.isPastDate(b.dateOnly)) {
            return -1;
          }
          return new Date(a.dateOnly) - new Date(b.dateOnly);
        });
      } else {
        items.sort((a, b) => {
          if (a.isWeekly && !b.isWeekly) {
            return -1;
          }
          if (!a.isWeekly && b.isWeekly) {
            return 1;
          }
          if (this.isPastDate(a.dateOnly) && !this.isPastDate(b.dateOnly)) {
            return 1;
          }
          if (!this.isPastDate(a.dateOnly) && this.isPastDate(b.dateOnly)) {
            return -1;
          }
          return new Date(b.dateOnly) - new Date(a.dateOnly);
        });
      }
      return items;
    },
    async deleteTimeSlot(index) {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/deleteLocationOffTimeList",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { id: index.id, locationId: this.uniqueId },
      })
        .then(() => {
          this.getLocationOffTimeList();
        })
        .catch((error) => console.log(error));
    },
    async getLocationOffTimeList() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getLocationOffTimeList",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          locationId: this.selectedLocation.locationId,
        },
      })
        .then((doc) => {
          let timeSlots = [];
          doc.data.timeSlot.forEach((slot) => {
            let appData = slot;
            let tempIsWeekly = appData.isWeekly;
            this.isWeekly = tempIsWeekly;
            let tempStart =
              String(new Date(appData.start).getHours()).padStart(2, "0") +
              ":" +
              String(new Date(appData.start).getMinutes()).padStart(2, "0");
            let tempEnd =
              String(new Date(appData.end).getHours()).padStart(2, "0") +
              ":" +
              String(new Date(appData.end).getMinutes()).padStart(2, "0");
            let tempDate =
              String(new Date(appData.start).getFullYear()).padStart(4, "0") +
              "-" +
              String(new Date(appData.start).getMonth() + 1).padStart(2, "0") +
              "-" +
              String(new Date(appData.start).getDate()).padStart(2, "0");
            let weekName = "";
            if (tempIsWeekly == true && tempDate) {
              const date = new Date(tempDate);
              const days = [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday",
              ];
              weekName = days[date.getDay()];
            }
            timeSlots.push({
              start: tempStart,
              end: tempEnd,
              id: appData.id,
              dateOnly: tempDate,
              isWeekly: tempIsWeekly,
              weekName: weekName,
            });
          });
          this.individualDocLocationListItem = timeSlots;
        })
        .catch((error) => console.log(error));
    },
    async updateDoctorList() {
      let doctors = [];
      this.uniqueId = this.selectedLocation.locationId;
      if (this.uniqueId) {
        await this.$axios({
          method: "post",
          url:
            this.$axios.defaults.baseURL + "/api/getProviderFilterWithLocation",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: {
            locationId: this.uniqueId,
          },
        })
          .then((response) => {
            response.data.forEach((doc) => {
              let appData = doc;
              doctors.push(appData);
            });
          })
          .catch((error) => console.log(error));
      }
      this.provider_list_dropdown = doctors;
    },
    async addNewProvider() {
      await this.$getDoctorsList();
      this.provider_name_dropdown = this.$doctorsListObj;
      const newProviderNameDropdown = this.provider_name_dropdown.filter(
        (providerNameObj) => {
          return !this.provider_list_dropdown.find((providerListObj) => {
            return providerNameObj.providerId === providerListObj.providerId;
          });
        }
      );
      this.provider_name_dropdown = newProviderNameDropdown;
      this.newProviderPage = true;
    },
    async addProvider() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/addDocLocationList",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          docId: this.providerNameDropdown.providerId,
          location: this.selectedLocation,
        },
      })
        .then(() => {
          this.newProviderPage = false;
          this.updateDoctorList();
        })
        .catch((error) => console.log(error));
    },
    async deleteProvider() {
      //to be added back later. provider should not be deleted easily
      //change delete to filter out isDeleted in location provider
      // await this.$axios({
      //   method: 'post',
      //   url: this.$axios.defaults.baseURL + '/api/deleteProvider',
      //   headers: { Authorization: localStorage.getItem('accessToken') },
      //   data: { docId: this.SelectedDoctorId }
      // }).catch(error => console.log(error));
      // await this.updateDoctorList();
      // alert('Provider Deleted!')
      // gotta refresh the table again!
      // this.deletepopup = false
    },
    calculateMilliseconds() {
      this.milliseconds = this.selectedTime * 60 * 1000;
      
    },
    calculateTimeLength(time) {
      let temp = time / 60000;

      if (temp === 5) {
        return "5 mins";
      } else if (temp === 10) {
        return "10 mins";
      } else if (temp === 15) {
        return "15 mins";
      } else if (temp === 20) {
        return "20 mins";
      } else if (temp === 25) {
        return "25 mins";
      } else if (temp === 30) {
        return "30 mins";
      } else if (temp === 35) {
        return "35 mins";
      } else if (temp === 40) {
        return "40 mins";
      } else if (temp === 45) {
        return "45 mins";
      } else if (temp === 50) {
        return "50 mins";
      } else if (temp === 55) {
        return "55 mins";
      } else if (temp === 60) {
        return "1 hr";
      } else if (temp === 65) {
        return "1 hr 5 mins";
      } else if (temp === 70) {
        return "1 hr 10 mins";
      } else if (temp === 75) {
        return "1 hr 15 mins";
      } else if (temp === 80) {
        return "1 hr 20 mins";
      } else if (temp === 85) {
        return "1 hr 25 mins";
      } else if (temp === 90) {
        return "1 hr 30 mins";
      } else if (temp === 95) {
        return "1 hr 35 mins";
      } else if (temp === 100) {
        return "1 hr 40 mins";
      } else if (temp === 105) {
        return "1 hr 45 mins";
      } else if (temp === 110) {
        return "1 hr 50 mins";
      } else if (temp === 115) {
        return "1 hr 55 mins";
      } else if (temp === 120) {
        return "2 hrs";
      } else {
        return temp + " mins";
      }
    },

    async editLocationInfo() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/editLocation",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          id: this.selectedLocation.locationId,
          location: this.EditLocationName,
          companyName: this.EditCompanyName,
          phone: this.EditLocationPhone,
          email: this.EditLocationEmail,
        },
      })
        .then(() => {
          this.$emit("close");
        })
        .catch((error) => console.log(error));
    },
    closePage() {
      this.$emit("close");
    },
    acceptLocation() {
      var x = this.EditLocationPhone.replace(/\D/g, "").match(
        /(\d{0,3})(\d{0,3})(\d{0,4})/
      );
      this.EditLocationPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    async showTime() {
      await this.$axios({
        method: "post",
        url:
          this.$axios.defaults.baseURL + "/api/updateLocationWorkDays/update",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          locationId: this.uniqueId,
          time: this.dateTime,
        },
      })
        .then(
          this.fetchLocationWorkTime(),
          alert("Location Work Time Updated!")
        )
        .catch((error) => console.log(error));
    },
    async fetchLocationWorkTime() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/updateLocationWorkDays/get",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          locationId: this.uniqueId,
        },
      })
        .then((doc) => {
          if (doc.data.time) {
            this.dateTime = doc.data.time;
            this.locationWorkHour = doc.data.time;
          }
        })
        .catch((error) => console.log(error));
    },
    updateTime() {
      this.providerCounter++;
      this.showTime();
    },
    isDayBookedInLocationSettings(dateString) {
      if (
        !this.locationWorkHour ||
        Object.keys(this.locationWorkHour).length === 0
      ) {
        return false;
      }

      const inputDate = new Date(`${dateString}T12:00:00`);

      const daysOfWeek = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];
      const dayName = daysOfWeek[inputDate.getDay()];

      return this.locationWorkHour[dayName + "Check"] === false;
    },
  },
};
</script>
<style scoped>
table,
td,
th {
  border: 1px solid;
}

.center {
  text-align: center;
  position: relative;
}
</style>
