<template>
  <v-app>
    <v-container fluid class="main-container">
      <v-card class="content-card">
        <v-row>
          <v-col cols="12" md="3" class="left-section">
            <div class="chat-list">
              <v-row justify="space-between" class="align-center mb-2 chat-header">
                <v-col>
                  <h2 class="chats-title">Patients</h2>
                </v-col>
                <v-col class="text-right">
                  <v-icon small @click="addChat" class="add-chat-button">mdi-plus</v-icon>
                </v-col>
              </v-row>
              <v-list dense class="chat-list-items">
                <v-list-item-group v-model="selectedChatId">
                  <v-list-item v-for="chat in chats" :key="chat.id" @click="selectChat(chat.id)"
                    :class="{ 'chat-item': true, 'chat-item-hover': selectedChatId === chat.id, 'v-list-item--active': selectedChatId === chat.id }"
                    class="chat-item-border">
                    <div class="d-flex justify-space-between align-center" style="width: 100%;">
                      <v-list-item-content>
                        <v-list-item-title>{{ chat.name }}</v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action class="delete-icon">
                        <v-icon @click.stop="deleteChat(chat.id)">mdi-delete</v-icon>
                      </v-list-item-action>
                    </div>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </div>
          </v-col>
          <v-col cols="12" md="9" class="right-section">
            <v-card class="chat-window">
              <v-card-title class="chat-title">
                {{ selectedChat ? selectedChat.name : 'Chat Window' }}
              </v-card-title>
              <v-card-text class="chat-content" ref="chatContent">
                <div v-for="(message, index) in selectedChat.messages" :key="index" class="message-container sent">
                  <span class="sender-label">Doctor:</span>
                  <div class="message-bubble" v-html="message"></div>
                </div>
              </v-card-text>
              <v-card-actions class="chat-input">
                <v-btn icon :color="isRecording ? 'red' : 'green'" @click="toggleRecording" class="record-button">
                  <v-icon>{{ isRecording ? 'mdi-pause-circle' : 'mdi-microphone' }}</v-icon>
                </v-btn>
                <div class="input-wrapper">
                  <textarea v-model="displayMessage" placeholder="Type a message" @keyup.enter="sendMessage"
                    ref="textField" @input="adjustTextFieldHeight" rows="1" class="input-textarea"></textarea>
                </div>
                <v-btn @click="sendMessage" color="primary" class="analyze-button">Send</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
export default {
  data() {
    return {
      chats: [
        {
          id: 1,
          name: 'Patient 1',
          messages: [`
Doctor: What's the problem today?
Patient: I have a <span style="color:red;">headache</span>. 
Doctor: So you have a <span style="color:red;">headache</span>? 
Patient: My <span style="color:red;">headache</span> has been quite severe these <span style="color:red;">past two days</span>. 
Doctor: So <span style="color:red;">it's been severe</span> these <span style="color:red;">past two days</span>? 
Patient: Yes, <span style="color:red;">it's been severe</span>. Doctor: Have you been anywhere? Have you been out of town? 
Patient: I went hiking these <span style="color:red;">past two days</span>. <span style="color:red;">The weather hasn't been good</span>, would that worsen my <span style="color:red;">headache</span>? 
Doctor: Yes, any windy or rainy weather can make it worse. 
Doctor: Where does it <span style="color:red;">hurt</span>? 
Patient: It <span style="color:red;">hurts in the front of my head</span>. 
Doctor: So both sides <span style="color:red;">hurt</span> more? 
Patient: Yes, both sides <span style="color:red;">hurt</span>. 
Doctor: OK. Does your family have any hereditary diseases? 
Patient: I'm a bit concerned about heart disease. My mother has a bit of diabetes. We don't have any other hereditary diseases. But we are all quite old. 
Doctor: Let's do some <span style="color:red;">pain relief</span> for your <span style="color:red;">headache</span>. Do you usually have any stomach discomfort or stomach disease? 
Patient: No. 
Doctor: OK, no contraindications. Good. Then I'll give you a <span style="color:red;">prescription</span>. You can go to the pharmacy to get it. Just take it when you feel weak. Is that OK? 
Patient: How many times a day should I take it? Doctor: You can take it... up to... not <span style="color:red;">four times</span>, but <span style="color:red;">every four to six hours</span>. The pharmacist will explain this to you. 
Patient: Thank you. 
Doctor: OK, good. Thank you.

SOAP Note: 
- Subjective: 
Chief Complaint: Severe headache for the past two days. 
History of Present Illness: 
- Patient reports severe headache for the past two days, worsened by poor weather conditions. 
- Pain is located in the front of the head, with both sides hurting more. 
Past Medical History: 
- Family history of heart disease and diabetes (mother). 
Current Medications: None mentioned. 
Lifestyle: Recently went hiking. 

- Objective: 
Vital Signs: Not provided. 
Physical Examination Findings: Not provided. 
Laboratory and Diagnostic Results: Not provided. 
Assessment of Systems: Not provided. 

- Assessment: 
Diagnosis: Severe headache, possibly tension-type or weather-related. 
Clinical Impressions: Patient is generally healthy but has a family history of heart disease and diabetes. 

- Plan: 
Treatment Plan: 
- Prescription for pain relief medication provided. 
- Patient advised to take medication every four to six hours as needed for pain. 
Patient Education: 
- Patient educated on the potential impact of weather on headache severity. 
- Patient advised to consult with pharmacist for medication instructions. 
Follow-up: Not specified.`
          ]
        },
        {
          id: 2,
          name: 'Medical Alert',
          messages: [ `<em>New Alert, patient reporting problem</em>
          
          <em>Doctor: Hi Linda, how's your headache these past few days?</em>
          <em>Patient: Ah, my headache is a little better, but it still hurts quite a bit.</em>
          <em>Doctor: How would you rate your headache before?</em> 
          <em>Patient: It was a six out of ten, now a eight out of ten.</em> 
          <em>Doctor: Any other discomforts after taking this painkiller?</em>
          <em>Patient: I have a stomachache. I feel like vomiting and nauseous.</em>

          <em>Please send message back to patient.</em>` 
          ]
        }
      ],
      selectedChat: {
        id: 1,
        name: 'Patient 1',
        messages: [`
Doctor: What's the problem today?
Patient: I have a <span style="color:red;">headache</span>. 
Doctor: So you have a <span style="color:red;">headache</span>? 
Patient: My <span style="color:red;">headache</span> has been quite severe these <span style="color:red;">past two days</span>. 
Doctor: So <span style="color:red;">it's been severe</span> these <span style="color:red;">past two days</span>? 
Patient: Yes, <span style="color:red;">it's been severe</span>. Doctor: Have you been anywhere? Have you been out of town? 
Patient: I went hiking these <span style="color:red;">past two days</span>. <span style="color:red;">The weather hasn't been good</span>, would that worsen my <span style="color:red;">headache</span>? 
Doctor: Yes, any windy or rainy weather can make it worse. 
Doctor: Where does it <span style="color:red;">hurt</span>? 
Patient: It <span style="color:red;">hurts in the front of my head</span>. 
Doctor: So both sides <span style="color:red;">hurt</span> more? 
Patient: Yes, both sides <span style="color:red;">hurt</span>. 
Doctor: OK. Does your family have any hereditary diseases? 
Patient: I'm a bit concerned about heart disease. My mother has a bit of diabetes. We don't have any other hereditary diseases. But we are all quite old. 
Doctor: Let's do some <span style="color:red;">pain relief</span> for your <span style="color:red;">headache</span>. Do you usually have any stomach discomfort or stomach disease? 
Patient: No. 
Doctor: OK, no contraindications. Good. Then I'll give you a <span style="color:red;">prescription</span>. You can go to the pharmacy to get it. Just take it when you feel weak. Is that OK? 
Patient: How many times a day should I take it? Doctor: You can take it... up to... not <span style="color:red;">four times</span>, but <span style="color:red;">every four to six hours</span>. The pharmacist will explain this to you. 
Patient: Thank you. 
Doctor: OK, good. Thank you.

SOAP Note: 
- Subjective: 
Chief Complaint: Severe headache for the past two days. 
History of Present Illness: 
- Patient reports severe headache for the past two days, worsened by poor weather conditions. 
- Pain is located in the front of the head, with both sides hurting more. 
Past Medical History: 
- Family history of heart disease and diabetes (mother). 
Current Medications: None mentioned. 
Lifestyle: Recently went hiking. 

- Objective: 
Vital Signs: Not provided. 
Physical Examination Findings: Not provided. 
Laboratory and Diagnostic Results: Not provided. 
Assessment of Systems: Not provided. 

- Assessment: 
Diagnosis: Severe headache, possibly tension-type or weather-related. 
Clinical Impressions: Patient is generally healthy but has a family history of heart disease and diabetes. 

- Plan: 
Treatment Plan: 
- Prescription for pain relief medication provided. 
- Patient advised to take medication every four to six hours as needed for pain. 
Patient Education: 
- Patient educated on the potential impact of weather on headache severity. 
- Patient advised to consult with pharmacist for medication instructions. 
Follow-up: Not specified.`
        ]
      },
      selectedChatId: 1,
      message: '',
      displayMessage: '',
      isRecording: false,
      recognition: null,
      interimMessage: ''
    };
  },
  mounted() {
    if (window.SpeechRecognition || window.webkitSpeechRecognition) {
      const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
      this.recognition = new SpeechRecognition();
      this.recognition.continuous = true;
      this.recognition.interimResults = true;
      this.recognition.lang = 'en-US';

      this.recognition.onstart = () => {
        this.isRecording = true;
      };

      this.recognition.onresult = (event) => {
        let interimTranscript = '';
        for (let i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            this.message += event.results[i][0].transcript + ' ';
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }
        this.displayMessage = this.message + interimTranscript;
        this.adjustTextFieldHeight();
      };

      this.recognition.onerror = (event) => {
        console.error('Speech recognition error detected: ' + event.error);
      };

      this.recognition.onend = () => {
        this.isRecording = false;
        this.message += this.interimMessage;
        this.interimMessage = '';
        this.adjustTextFieldHeight();
      };
    } else {
      console.warn('Speech recognition not supported in this browser.');
    }
  },
  methods: {
    addChat() {
      const newChatId = this.chats.length + 1;
      const newChatName = `Patient ${newChatId}`;
      const newChat = { id: newChatId, name: newChatName, messages: [] };
      this.chats.push(newChat);
      this.selectedChat = newChat;
      this.selectedChatId = newChatId;
    },
    deleteChat(chatId) {
      this.chats = this.chats.filter(chat => chat.id !== chatId);
      if (this.selectedChat && this.selectedChat.id === chatId) {
        this.selectedChat = this.chats.length ? this.chats[0] : null;
        this.selectedChatId = this.selectedChat ? this.selectedChat.id : null;
      }
    },
    selectChat(chatId) {
      this.selectedChat = this.chats.find(chat => chat.id === chatId);
      this.selectedChatId = chatId;
    },
    sendMessage() {
      if (this.displayMessage.trim() && this.selectedChat) {
        this.selectedChat.messages.push(this.displayMessage.trim());
        this.message = '';
        this.displayMessage = '';
        this.adjustTextFieldHeight();
        this.scrollToBottom();
      }
    },
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    startRecording() {
      if (this.recognition) {
        this.recognition.start();
      }
    },
    stopRecording() {
      if (this.recognition) {
        this.recognition.stop();
      }
    },
    adjustTextFieldHeight() {
      this.$nextTick(() => {
        const textField = this.$refs.textField;
        if (textField) {
          textField.style.height = 'auto';
          const scrollHeight = textField.scrollHeight;
          const maxHeight = parseInt(getComputedStyle(textField).lineHeight, 10) * 4;
          if (scrollHeight > maxHeight) {
            textField.style.height = `${maxHeight}px`;
            textField.scrollTop = textField.scrollHeight;
          } else {
            textField.style.height = `${scrollHeight}px`;
          }
        }
      });
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const chatContent = this.$refs.chatContent;
        chatContent.scrollTop = chatContent.scrollHeight;
      });
    }
  }
};
</script>

<style>
.left-section {
  color: white;
  height: calc(100vh - 8%);
  padding: 0;
}

.right-section {
  background-color: #f0f0f0;
  height: calc(100vh - 8%);
  padding: 0;
}

.main-container {
  padding: 2%;
  background-color: #d3d3d3;
}

.content-card {
  padding: 16px;
}

html,
body,
#app {
  height: 96%;
  margin: 0;
  background-color: #d3d3d3;
}

.chat-list {
  height: 100%;
  padding: 16px;
}

.chat-header {
  margin-bottom: 0;
  height: auto;
}

.chats-title {
  color: black;
  margin: 0;
  padding: 2vh 1.1vw;
  display: flex;
  align-items: flex-end;
}

.chat-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  margin: 8px 0;
  border-radius: 8px;
  user-select: none;
}

.chat-item-hover,
.v-list-item--active {
  background-color: #bfbfbf;
  border-radius: 8px;
}

.chat-item:hover {
  background-color: #bfbfbf;
}

.chat-item-border {
  border: 1px solid white;
}

.delete-icon {
  margin-left: auto;
  pointer-events: auto;
}

.add-chat-button {
  cursor: pointer;
  margin: 8px;
  pointer-events: auto;
}

.chat-window {
  display: flex;
  flex-direction: column;
  height: 98vh;
  width: 100%;
}

.chat-title {
  padding: 3.8% 2vw;
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.chat-content {
  flex-grow: 1;
  overflow-y: auto;
  padding: 16px;
  background-color: #f0f0f0;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.message-container {
  margin-top: 24px;
  position: relative;
  text-align: right;
  margin-left: 20%;
}

.sender-label {
  font-size: 12px;
  color: #555;
  display: block;
  margin-bottom: 4px;
}

.message-bubble {
  border-radius: 8px;
  padding: 8px 16px;
  max-width: 80%;
  user-select: text;
  pointer-events: auto;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
  text-align: left;
  background-color: #add8e6;
}

.chat-input {
  display: flex;
  align-items: center;
  padding: 8px;
  background-color: white;
  width: 100%;
}

.record-button,
.analyze-button {
  margin: 0 8px;
  align-self: center;
}

.input-wrapper {
  flex-grow: 1;
  display: flex;
  align-items: center;
  width: 100%;
}

.input-wrapper textarea {
  width: 100%;
  max-width: 100%;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 10px 15px;
  resize: none;
  overflow: hidden;
  line-height: 1.5;
  font-size: 16px;
  box-sizing: border-box;
  min-height: 40px;
  max-height: calc(1.5em * 4);
  overflow-y: auto;
}
</style>
