<template>
  <v-card flat>
    <v-card-text>
      <v-btn color="primary" @click="addNewProvider()"> New Provider </v-btn>

      <v-dialog v-model="addNewDoctor" max-width="450">
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Add New Provider</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="addNewDoctor = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card>
          <v-container>
            <v-form @submit.prevent="addProviderEvent" class="container-outline">
              <v-text-field v-model="addDoctorName" type="text" required label="Provider First Name"></v-text-field>
              <v-text-field v-model="addDoctorLastName" type="text" label="Provider Last Name"></v-text-field>
              <v-text-field v-model="addDoctorSuffix" type="text" label="Provider Suffix Name"></v-text-field>
              <div>
                <v-container>
                  <v-row>
                    <v-col cols="12" v-for="(item, index) in selectedItems" :key="index">
                      <v-card>
                        <v-card-title>Select a Profession</v-card-title>
                        <v-card-text>
                          <v-select :items="titles" v-model="item.title" label="Title"
                            :menu-props="{ maxHeight: '400' }"></v-select>
                          <v-text-field label="Enter number" v-model="item.number"></v-text-field>
                        </v-card-text>
                        <v-card-actions>

                          <v-icon small class="mr-2" @click="deleteItem(index)">mdi-delete</v-icon>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-container>
                <v-btn @click="addItem">Add Profession</v-btn>
                <br />
              </div>
              <v-text-field v-model="addDoctorPhone" type="text" label="Provider Phone"
                @input="acceptProvider"></v-text-field>
              <v-text-field v-model="addDoctorAddress" type="text" label="Provider Address"></v-text-field>
              <v-text-field v-model="addDoctorEmail" type="text" label="Provider Email"></v-text-field>
              <v-btn :disabled="!addDoctorName && !addDoctorLastName && !addDoctorSuffix && !selectedItems" type="submit"
                color="primary" class="mb-4" @click.stop="addNewDoctor = false">Add</v-btn>
            </v-form>
          </v-container>
        </v-card>
      </v-dialog>
      <v-data-table :headers="adddocheader" :items="doctor_list_dropdown" :items-per-page="5" class="elevation-1">
        <template v-slot:[`item.actions`]="{ item }">
          <v-icon small class="mr-2" @click="editItem(item)">mdi-pencil</v-icon>
        </template>
      </v-data-table>
    </v-card-text>
    <v-dialog v-if="editPage" v-model="editPage" max-width="1200px">
      <v-card>
        <v-toolbar flat color="primary" dark>
          <v-toolbar-title>Provider Detail</v-toolbar-title>
          <v-spacer />
          <v-btn icon @click="editPage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-tabs vertical>
          <v-tab>
            <v-icon left>mdi-account</v-icon>
            Edit Information
          </v-tab>
          <v-tab>
            <v-icon left>mdi-account</v-icon>
            Locations
          </v-tab>
          <!-- <v-tab>
             <v-icon left>mdi-account</v-icon>
            Delete
          </v-tab> -->
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-container>
                  <v-form @submit.prevent="editDoctorEvent" class="container-outline">
                    <h1>{{ addDoctorName }} {{ addDoctorLastName }}</h1>
                    <br />
                    <v-text-field v-model="addDoctorSuffix" type="text" label="Provider Suffix Name"></v-text-field>
                    <v-text-field v-model="addDoctorPhone" type="text" label="Provider Phone"
                      @input="acceptProvider"></v-text-field>
                    <v-text-field v-model="addDoctorAddress" type="text" label="Provider Address"></v-text-field>
                    <v-text-field v-model="addDoctorEmail" type="text" label="Provider Email"></v-text-field>
                    <div>
                      <v-container>
                        <v-row>
                          <v-col cols="12" v-for="(item, index) in selectedItems" :key="index">
                            <v-card>
                              <v-card-title>Select a Profession</v-card-title>
                              <v-card-text>
                                <v-select :items="titles" v-model="item.title" label="Title"
                                  :menu-props="{ maxHeight: '400' }"></v-select>
                                <v-text-field label="Enter number" v-model="item.number"></v-text-field>
                              </v-card-text>
                              <v-card-actions>
                                <v-icon small class="mr-2" @click="deleteItem(index)">mdi-delete</v-icon>
                              </v-card-actions>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-container>
                      <v-btn @click="addItem">Add Profession</v-btn>
                      <br />
                    </div>
                    <v-btn type="submit" color="primary" class="mb-4">Update</v-btn>
                  </v-form>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat>
              <v-card-text>
                <ProvidersLocation :providerId="SelectedDoctorId" :locationId="locationId" />
              </v-card-text>
            </v-card>
          </v-tab-item>
          <!-- <v-tab-item>
            <v-card flat>
              <v-card-text>
                <v-btn color="primary" @click="deletepopup = true">
                  Delete Provider
                </v-btn>
                <v-dialog v-model="deletepopup" max-width="450">
                  <v-toolbar flat color="primary" dark>
                    <v-toolbar-title>Are you sure to delete this provider?</v-toolbar-title>
                  </v-toolbar>
                  <v-card>
                    <v-container class="center">
                      <v-row>
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-btn color="primary" @click="deleteProvider()">
                            Yes
                          </v-btn>
                        </v-col>
                        <v-col class="d-flex" cols="12" sm="6">
                          <v-btn color="primary" @click="deletepopup = false">
                            No
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-dialog>
              </v-card-text>
            </v-card>
          </v-tab-item> -->
        </v-tabs>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import ProvidersLocation from "../ProviderSettingsPages/ProvidersLocation.vue";
export default {
  name: "settingsPage",
  created() { },
  props: ["userGroup"],
  components: {
    ProvidersLocation,
  },
  data: () => ({
    titles: [
      'Homeopathy', 'Herbal medicine', 'Chiropractic', 'Acupuncture', 'Naturopathic medicine',
      'Ayurveda', 'Osteopathy', 'Massage', 'Therapy', 'Yoga', 'Traditional Chinese medicine',
      'Meditation', 'Aromatherapy', 'Reiki', 'Biofeedback', 'Tai chi', 'Reflexology',
      'Hypnosis', 'Hypnotherapy', 'Cupping therapy', 'Hydrotherapy', 'Moxibustion',
      'Acupressure', 'Qigong', 'Physiotherapy', 'Dietitians', 'Psychologists',
      'Speech therapists', 'Physician', 'Nurse'
    ],
    selectedItems: [],

    addDoctorFirstName: null,
    addDoctorLastName: null,
    addDoctorSuffix: null,
    location: "",
    addServiceHST: false,
    addNewDoctor: false,
    addDoctorName: null,
    addDoctorPhone: null,
    addDoctorAddress: null,
    addDoctorEmail: null,
    addServiceName: null,
    addServicePrice: null,
    addLocationPhone: null,
    deletepopup: false,
    doctor_list_dropdown: [],
    editPage: false,
    editedItem: {},
    adddocheader: [
      { text: "id", align: "start", value: "idNumber" },
      {
        text: "Provider First Name",
        align: "start",
        value: "providerName",
      },
      { text: "Provider Last Name", value: "doctorLastName" },
      { text: "Provider Suffix", value: "doctorSuffix" },
      { text: "Phone Number", value: "providerPhone" },
      { text: "Email", value: "providerEmail" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    serviceList: [],
    SelectedDoctorId: null,
    locationId: null,
  }),
  async mounted() {
    await this.getProvidersList();
  },
  methods: {
    addItem() {
      if (this.selectedItems) {
        this.selectedItems.push({ title: '', number: '' });
      } else {
        this.selectedItems = [{ title: '', number: '' }]
      }
    },
    deleteItem(index) {
      this.selectedItems.splice(index, 1);
    },
    addNewProvider() {
      this.addDoctorName = null;
      this.addDoctorLastName = null
      this.addDoctorSuffix = null
      this.addDoctorPhone = null;
      this.addDoctorAddress = null;
      this.addDoctorEmail = null;
      this.selectedItems = []
      this.addNewDoctor = true;
    },

    async editItem(item) {
      (this.editedItem = null),
        (this.SelectedDoctorId = null),
        (this.editedItem = Object.assign({}, item));
      this.SelectedDoctorId = this.editedItem.providerId;
      this.addDoctorName = this.editedItem.providerName;
      this.addDoctorLastName = this.editedItem.doctorLastName;
      this.addDoctorSuffix = this.editedItem.doctorSuffix;

      this.addDoctorPhone = this.editedItem.providerPhone;
      this.addDoctorAddress = this.editedItem.providerAddress;
      this.addDoctorEmail = this.editedItem.providerEmail;
      this.selectedItems = this.editedItem.professionalNumber

      this.editPage = true;
    },
    async getProvidersList() {
      let doctors = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/apl/get",
        headers: { Authorization: localStorage.getItem("accessToken") },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            appData.id = doc.doctorName;
            doctors.push(appData);
          });
        })
        .catch((error) => console.log(error));
      this.doctor_list_dropdown = doctors;
    },
    async addProviderEvent() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/apl/add",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          doctorName: this.addDoctorName,
          doctorLastName: this.addDoctorLastName,
          doctorSuffix: this.addDoctorSuffix,
          professionalNumber: this.selectedItems,
          doctorPhone: this.addDoctorPhone,
          doctorAddress: this.addDoctorAddress,
          doctorEmail: this.addDoctorEmail,
        },
      }).catch((error) => console.log(error));
      await this.getProvidersList();
      this.addDoctorName = null;
      this.addDoctorLastName = null
      this.addDoctorSuffix = null
      this.selectedItems = []
      this.addDoctorPhone = null;
      this.addDoctorAddress = null;
      this.addDoctorEmail = null;
    },
    async editDoctorEvent() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/apl/update",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          doctorId: this.SelectedDoctorId,
          doctorName: this.addDoctorName,
          doctorLastName: this.addDoctorLastName,
          professionalNumber: this.selectedItems,
          doctorSuffix: this.addDoctorSuffix,
          doctorPhone: this.addDoctorPhone,
          doctorAddress: this.addDoctorAddress,
          doctorEmail: this.addDoctorEmail,
        },
      })
        .then((doc) => {
          this.editPage = false
          this.editedItem = doc.data;
        })
        .catch((error) => console.log(error));
      await this.getProvidersList();
      this.addDoctorName = null;
      this.addDoctorLastName = null
      this.addDoctorSuffix = null
      this.selectedItems = []
      this.addDoctorPhone = null;
      this.addDoctorAddress = null;
      this.addDoctorEmail = null;
    },
    acceptProvider() {
      var x = this.addDoctorPhone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.addDoctorPhone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    async deleteProvider() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/apl/delete",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { docId: this.SelectedDoctorId },
      }).catch((error) => console.log(error));
      await this.getProvidersList();
      alert("Provider Deleted!");
      this.editPage = false;
    },
  },
};
</script>
<style scoped>
table,
td,
th {
  border: 1px solid;
}

.patientTable {
  border-collapse: collapse;
  text-align: center;
  width: 100%;
}

.center {
  text-align: center;
  position: relative;
}
</style>
