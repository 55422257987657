<template>
  <div>
    <v-container fluid>
      <v-form @submit.prevent="editUserInfoFn()" class="container-outline">
        <v-list-item>
          <v-row>
            <v-col cols="3" class="button-col">
              <v-sheet class="sticky-sheet">
                <v-tabs
                  vertical
                  background-color="transparent"
                  class="mt-3"
                  v-model="currentTab"
                >
                  <v-tab @click="scrollTo('section1')">Basic Info </v-tab>
                  <v-tab @click="scrollTo('section2')">Contact Info </v-tab>
                  <v-tab @click="scrollTo('section3')">Others</v-tab>
                  <br />
                  <v-tab>
                    <v-btn type="submit" class="mb-4" color="primary"
                      >Save</v-btn
                    ></v-tab
                  >
                </v-tabs>
              </v-sheet>
            </v-col>
            <v-col cols="8">
              <br />
              <div id="section1" ref="section1">
                <h1>Basic Info</h1>
                <br />
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.basicInfo.first_name"
                            type="text"
                            label="First Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.basicInfo.preferred_name"
                            type="text"
                            label="Preferred Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.basicInfo.last_name"
                            type="text"
                            label="Last Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.basicInfo.birth_date"
                            type="date"
                            label="Date of Birth"
                            max="9999-12-31"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          Gender: <br />
                          <v-radio-group v-model="userObj.basicInfo.gender" row>
                            <v-radio label="Male" value="M"></v-radio>
                            <v-radio label="Female" value="F"></v-radio>
                            <v-radio label="Others" value="O"></v-radio>
                          </v-radio-group>
                          <br />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <locationSelector
                            @locationSelected="onLocationSelected"
                            :locationId="this.locationId"
                          />
                        </v-col>
                        <v-col cols="12" sm="6">
                          <providerSelector
                            v-if="locationId"
                            @providerSelected="onProviderSelected"
                            :providerId="providerId"
                            :locationId="this.locationId"
                            :key="locationId"
                          ></providerSelector>
                        </v-col>
                      </v-row>
                      <br />
                      <v-card class="mx-auto" outlined v-if="this.userId">
                        <v-card-title>Personal Notes</v-card-title>
                        <v-list-item>
                          <patientNotesHistory
                            :key="this.userId"
                            :userId="this.userId"
                          />
                        </v-list-item>
                        <br />
                      </v-card>
                      <v-card>
                        <v-card-title>Loyalty Rating</v-card-title>
                        <v-card-text>
                          <v-slider
                            v-model="userObj.basicInfo.loyaltyRating"
                            step="1"
                            ticks
                            max="10"
                            thumb-label="always"
                            :thumb-size="15"
                          ></v-slider>
                        </v-card-text>
                      </v-card>
                      <br />
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
              <br />
              <br />
              <div id="section2" ref="section2">
                <h1>Contact Info</h1>
                <br />
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-card class="mx-auto" outlined v-if="this.userId">
                        <v-card-title>Personal Phone</v-card-title>
                        <v-list-item>
                          <patientPhoneHistory
                            :key="this.userId"
                            :userId="this.userId"
                          />
                        </v-list-item>
                        <br />
                      </v-card>
                      <br />
                      <v-card class="mx-auto" outlined v-if="this.userId">
                        <v-card-title>Personal Email</v-card-title>
                        <v-list-item>
                          <patientEmailHistory
                            :key="this.userId"
                            :userId="this.userId"
                          />
                        </v-list-item>
                        <br />
                      </v-card>
                      <br />
                      <v-card class="mx-auto" outlined v-if="this.userId">
                        <v-card-title>Personal Address</v-card-title>
                        <v-list-item>
                          <patientAddressHistory
                            :key="this.userId"
                            :userId="this.userId"
                          />
                        </v-list-item>
                        <br />
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
              <br />
              <br />
              <br />
              <div id="section3" ref="section3">
                <h1>Others</h1>
                <br />
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.otherInfo.occupation"
                            type="text"
                            label="Occupation"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.otherInfo.familydoctorname"
                            type="text"
                            label="Family Doctor Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.otherInfo.familydoctorphone"
                            type="text"
                            label="Family Doctor Phone"
                            @input="acceptDoctor"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.otherInfo.emergencyname"
                            type="text"
                            label="Emergency Contact Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.otherInfo.emergencyphone"
                            type="text"
                            label="Emergency Contact Phone"
                            @input="acceptEmergency"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
              <br />
            </v-col>
          </v-row>
        </v-list-item>
      </v-form>
    </v-container>
  </div>
</template>
<script>
export default {
  props: {
    selectedEvent: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    currentTab: null,
    loyaltyRating: 5,
    userObj: {
      otherInfo: {
        emergencyphone: "",
        occupation: "",
        familydoctorname: "",
        emergencyname: "",
        familydoctorphone: "",
      },
      basicInfo: {
        first_name: "",
        gender: "",
        defaultProvider: null,
        loyaltyRating: 5,
        birth_date: "",
        name: "",
        defaultLocation: {},
        preferred_name: "",
        last_name: "",
      },
    },
    locationId: null,
    providerId: null,
    userId: null,
  }),
  mounted() {
    this.userId = this.selectedEvent.UserId;
    this.userObj = this.selectedEvent;

    if (this.selectedEvent.basicInfo.defaultLocation.locationId) {
      this.locationListDropdown = this.selectedEvent.basicInfo.defaultLocation;
      this.locationId = this.selectedEvent.basicInfo.defaultLocation.locationId;
    }

    if (this.selectedEvent.basicInfo.defaultProvider.providerId) {
      this.providerNameDropdown = this.selectedEvent.basicInfo.defaultProvider;
      this.providerId = this.selectedEvent.basicInfo.defaultProvider.providerId;
    }
  },
  methods: {
    onLocationSelected(data) {
      this.locationId = data.locationId;
      this.locationListDropdown = data;
      this.providerId = null;
    },
    onProviderSelected(data) {
      this.providerId = data.providerId;
      this.providerNameDropdown = data;
    },
    scrollTo(section) {
      this.$refs[section].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    async editUserInfoFn() {
      let patientInformation = this.userObj;
      let providerTempId = "";
      let locationTempId = "";
      if (this.locationListDropdown?.locationId) {
        locationTempId = this.locationListDropdown.locationId;
      }
      if (
        this.locationListDropdown?.locationId &&
        this.providerNameDropdown?.providerId
      ) {
        providerTempId = this.providerNameDropdown.providerId;
      }
      let basicInfo = {
        first_name: patientInformation.basicInfo.first_name,
        name:
          patientInformation.basicInfo.first_name +
          " " +
          patientInformation.basicInfo.preferred_name +
          " " +
          patientInformation.basicInfo.last_name,
        preferred_name: patientInformation.basicInfo.preferred_name,
        last_name: patientInformation.basicInfo.last_name,
        gender: patientInformation.basicInfo.gender,
        loyaltyRating: patientInformation.basicInfo.loyaltyRating,
        birth_date: patientInformation.basicInfo.birth_date,
        defaultProvider: providerTempId,
        defaultLocation: locationTempId,
      };
      let otherInfo = {
        emergencyphone: patientInformation.otherInfo.emergencyphone,
        emergencyname: patientInformation.otherInfo.emergencyname,
        familydoctorphone: patientInformation.otherInfo.familydoctorphone,
        familydoctorname: patientInformation.otherInfo.familydoctorname,
        occupation: patientInformation.otherInfo.occupation,
      };
      try {
        await this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + "/api/updatePatientInfo",
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: {
            userId: patientInformation.UserId,
            basicInfo: basicInfo,
            otherInfo: otherInfo,
          },
        });
        this.$emit("close");
      } catch (error) {
        console.log(error);
      }
    },
    acceptDoctor() {
      var x = this.userObj.otherInfo.familydoctorphone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.userObj.otherInfo.familydoctorphone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    acceptEmergency() {
      var x = this.userObj.otherInfo.emergencyphone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.userObj.otherInfo.emergencyphone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
  },
};
</script>
<style scoped>
.sticky-sheet {
  top: 0;
  position: sticky;
}
</style>
