<template>
  <div>
    <v-container>
      <template>
        <v-data-table :headers="balanceHeader" :items="balanceList" :items-per-page="5" class="elevation-1">
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editBalance(item)">mdi-pencil</v-icon>
          </template></v-data-table>
      </template>
    </v-container>




    <v-dialog v-model="editBalancePage" max-width="600px">

      <v-card>
        <v-card-title>
          <span class="text-h5">Current Balance</span>
          <v-spacer />
          <v-btn icon @click="editBalancePage = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          Your Remaining Balance is: ${{ selectedBalance.balance }}

          <v-row>
            <v-col>
              <v-text-field v-model="selectedBalance.balance" type="number" label="New Balance"></v-text-field>
            </v-col>
            <v-col>
              <v-btn @click="clearBalance" color="primary" class="mb-4">Clear Balance</v-btn>
            </v-col>
          </v-row>

          <v-btn @click="updateBalance" color="primary" class="mb-4">Update Balance</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>




  </div>
</template>
<script>
export default {
  components: {

  },
  name: "allBalancePage",
  props: [],
  data: () => ({
    editBalancePage: false,
    balanceList: [],
    balanceHeader: [
      {
        text: "Date",
        align: "start",
        sortable: true,
        value: "date",
      },
      {
        text: "Name",
        value: "selectedEvent.name",
      },
      {
        text: "Balance Left ($)",
        value: "balance",
      },
      { text: "Actions", value: "actions", sortable: false },
    ],
    selectedBalance: [{ balance: 0 }]

  }),
  async mounted() {
    this.getBalanceList()
  },
  watch: {

  },
  computed: {

  },

  methods: {
    clearBalance() { 
      this.selectedBalance.balance = 0
    },
    async editBalance(data) {
      this.selectedBalance = data
      this.editBalancePage = true
    },
    async getBalanceList() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/balance",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {

        }
      })
        .then((response) => {
          let list = []
          response.data.forEach((doc) => {
            const [date, time] = doc.date.split('T');
            const formattedTime = time.split('.')[0];
            doc.date = `${date} ${formattedTime}`
            list.push(doc)
          });
          this.balanceList = list
        })
        .catch((error) => console.log(error));
    },
    async updateBalance() {
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/balance/update",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          eventId: this.selectedBalance.id,
          newBalance: this.selectedBalance.balance
        }
      })
        .then(() => {
          this.getBalanceList()
          this.editBalancePage = false
        })
        .catch((error) => console.log(error));
    },
  },
};
</script>
<style scoped></style>
