<template>
  <div>
    <v-container fluid>
      <v-form @submit.prevent="editUserInfoFn()" class="container-outline">
        <v-list-item>
          <v-row>
            <v-col cols="3" class="button-col">
              <v-sheet class="sticky-sheet">
                <v-tabs
                  vertical
                  background-color="transparent"
                  class="mt-3"
                  v-model="currentTab"
                >
                  <v-tab @click="scrollTo('section1')">Basic Info </v-tab>
                  <v-tab @click="scrollTo('section2')">Contact Info </v-tab>
                  <v-tab v-if="!minimal" @click="scrollTo('section3')"
                    >Others</v-tab
                  >
                  <br />
                  <v-tab>
                    <v-btn
                      :disabled="isButtonDisabled"
                      type="submit"
                      class="mb-4"
                      color="primary"
                      >Create</v-btn
                    ></v-tab
                  >
                </v-tabs>
              </v-sheet>
            </v-col>
            <v-col cols="8">
              <br />
              <div id="section1" ref="section1">
                <h1>Basic Info</h1>
                <br />
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.first_name"
                            value="this.first_name"
                            type="text"
                            label="First Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.preferred_name"
                            type="text"
                            label="Preferred Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.last_name"
                            type="text"
                            label="Last Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.birth_date"
                            type="date"
                            label="Date of Birth"
                            max="9999-12-31"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          Gender: <br />
                          <v-radio-group v-model="userObj.gender" row>
                            <v-radio label="Male" value="M"></v-radio>
                            <v-radio label="Female" value="F"></v-radio>
                            <v-radio label="Others" value="O"></v-radio>
                          </v-radio-group>
                          <br />
                        </v-col>
                        <v-col v-if="!minimal" cols="12" sm="6">
                          <locationSelector
                            @locationSelected="onLocationSelected"
                            :locationId="this.locationId"
                          />
                        </v-col>
                        <v-col v-if="!minimal" cols="12" sm="6">
                          <providerSelector
                            v-if="locationId"
                            @providerSelected="onProviderSelected"
                            :providerId="providerId"
                            :locationId="this.locationId"
                            :key="locationId"
                          ></providerSelector>
                        </v-col>
                      </v-row>
                      <br />
                      <v-card class="mx-auto" outlined v-if="!minimal">
                        <v-card-title>Notes</v-card-title>
                        <v-list-item>
                          <v-text-field
                            v-model="userObj.newNoteText"
                            label="New note"
                          ></v-text-field>
                        </v-list-item>
                        <br />
                      </v-card>

                      <br />
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
              <br />
              <br />
              <div id="section2" ref="section2">
                <h1>Contact Info</h1>
                <br />
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-card class="mx-auto" outlined>
                        <v-card-title>Phone</v-card-title>
                        <v-list-item>
                          <v-text-field
                            v-model="userObj.newPhoneNumber"
                            label="New phone number"
                            @input="acceptNumber()"
                          ></v-text-field>
                        </v-list-item>
                        <br />
                      </v-card>
                      <br />
                      <v-card class="mx-auto" outlined>
                        <v-card-title>Email</v-card-title>
                        <v-list-item>
                          <v-text-field
                            v-model="userObj.newEmail"
                            label="New email"
                            :error-messages="emailErrors"
                            @input="validateEmailInput"
                          ></v-text-field>
                        </v-list-item>
                        <br />
                      </v-card>
                      <br />
                      <v-card v-if="!minimal" class="mx-auto" outlined>
                        <v-card-title>Address</v-card-title>
                        <v-list-item>
                          <v-row>
                            <v-col sm="9">
                              <v-text-field
                                v-model="userObj.newAddress.AddressLine1"
                                label="Address Line 1"
                              ></v-text-field>
                              <v-text-field
                                v-model="userObj.newAddress.AddressLine2"
                                label="Address Line 2"
                              ></v-text-field>
                              <v-text-field
                                v-model="userObj.newAddress.City"
                                label="City"
                              ></v-text-field>
                              <v-text-field
                                v-model="userObj.newAddress.Province"
                                label="Province"
                              ></v-text-field>
                              <v-text-field
                                v-model="userObj.newAddress.PostalCode"
                                label="Postal Code"
                              ></v-text-field>
                              <v-text-field
                                v-model="userObj.newAddress.Country"
                                label="Country"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-list-item>
                        <br />
                      </v-card>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
              <br />
              <br />
              <br />
              <div v-if="!minimal" id="section3" ref="section3">
                <h1>Others</h1>
                <br />
                <v-card>
                  <v-list-item>
                    <v-list-item-content>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.occupation"
                            type="text"
                            label="Occupation"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.familydoctorname"
                            type="text"
                            label="Family Doctor Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.familydoctorphone"
                            type="text"
                            label="Family Doctor Phone"
                            @input="acceptDoctor"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.emergencyname"
                            type="text"
                            label="Emergency Contact Name"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="userObj.emergencyphone"
                            type="text"
                            label="Emergency Contact Phone"
                            @input="acceptEmergency"
                          ></v-text-field>
                        </v-col>
                      </v-row>
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </div>
              <br />
            </v-col>
          </v-row>
        </v-list-item>
      </v-form>
    </v-container>
  </div>
</template>
<script>
export default {
  props: {
    minimal: Boolean,
  },
  computed: {
    isButtonDisabled() {
      return !this.userObj.first_name && !this.userObj.last_name;
    },
  },
  data: () => ({
    UserId: null,
    currentTab: null,
    userObj: {
      first_name: "",
      preferred_name: "",
      last_name: "",
      birth_date: null,
      gender: null,
      occupation: "",
      familydoctorname: "",
      familydoctorphone: "",
      emergencyname: "",
      emergencyphone: "",
      newPhoneNumber: "",
      newEmail: "",
      newNoteText: "",
      newAddress: {
        AddressLine1: "",
        AddressLine2: "",
        City: "",
        Province: "",
        PostalCode: "",
        Country: "",
      },
    },
    emailErrors: null,
    locationId: "",
    providerId: "",
  }),
  mounted() {},
  methods: {
    onLocationSelected(data) {
      this.locationId = data.locationId;
      this.locationListDropdown = data;
      this.providerId = null;
    },
    onProviderSelected(data) {
      this.providerId = data.providerId;
      this.providerNameDropdown = data;
    },
    scrollTo(section) {
      this.$refs[section].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    async editUserInfoFn() {
      let patientInformation = this.userObj;

      let basicInfo = {
        first_name: patientInformation.first_name,
       
        name:
          patientInformation.first_name +
          " " +
          patientInformation.preferred_name +
          " " +
          patientInformation.last_name,
        preferred_name: patientInformation.preferred_name,
        last_name: patientInformation.last_name,
        gender: patientInformation.gender,
        loyaltyRating: 5,
        birth_date: patientInformation.birth_date,
        defaultProvider: this.providerId,
        defaultLocation: this.locationId,
      };
      let otherInfo = {
        emergencyphone: patientInformation.emergencyphone,
        emergencyname: patientInformation.emergencyname,
        familydoctorphone: patientInformation.familydoctorphone,
        familydoctorname: patientInformation.familydoctorname,
        occupation: patientInformation.occupation,
      };
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/createNewPatient",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          basicInfo: basicInfo,
          otherInfo: otherInfo,
          email: this.userObj.newEmail,
          phone: this.userObj.newPhoneNumber,
          address: this.userObj.newAddress,
          note: this.userObj.newNoteText,
        },
      })
        .then((doc) => {
          this.UserId = doc.data;

          this.$emit("close", this.UserId);
        })
        .catch((error) => console.log(error));
    },
    acceptDoctor() {
      var x = this.userObj.familydoctorphone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.userObj.familydoctorphone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    acceptEmergency() {
      var x = this.userObj.emergencyphone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.userObj.emergencyphone = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    acceptNumber() {
      var x = this.userObj.newPhoneNumber
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      this.userObj.newPhoneNumber = !x[2]
        ? x[1]
        : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    },
    validateEmailInput() {
      this.emailErrors = [];
      if (!this.userObj.newEmail.trim()) {
        this.emailErrors.push("Email cannot be empty");
      } else if (!this.userObj.newEmail.includes("@")) {
        this.emailErrors.push("Email should contain an '@'");
      }
    },
  },
};
</script>
<style scoped>
.sticky-sheet {
  top: 0;
  position: sticky;
}
</style>
