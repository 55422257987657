<template> 
<!-- get the data of all services based on given location and provider -->
  <div>
    <v-select
      v-model="service"
      :items="service_type_dropdown"
      name="service"
      :item-text="'serviceName'"
      @change="getTimeLengthDifference"
      label="Service"
      dense
      outlined
      return-object
    ></v-select>
  </div>
</template>
<script>
export default {
  name: "serviceSelector",
  props: {
    providerId: {
      providerId: String,
    },
    locationId: {
      locationId: String,
    },
  },
  data: () => ({
    service: null,
    service_type_dropdown: [],
  }),
  async mounted() {
    if (this.providerId) {
      this.getDoctorService(this.providerId);
    }
  },
  methods: {
    async getDoctorService(serviceId) {
      let services = [];
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getDoctorService",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: { docId: serviceId },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            if (doc.locationId == this.locationId) {
              services.push(appData);
            }
          });
        })
        .catch((error) => console.log(error));
      this.service_type_dropdown = services;
    },
    getTimeLengthDifference(data) {
      this.$emit("serviceSelected", data);
    },
  },
};
</script>
