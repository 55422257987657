<template>
  <div>
    <br />
    <v-tabs vertical>
      <v-tab @click="locationNumber++">
        <v-icon left>mdi-account</v-icon>
        Manage Location
      </v-tab>
      <v-tab @click="providerNumber++">
        <v-icon left>mdi-account</v-icon>
        All Providers List
      </v-tab>
      <v-tab @click="serviceNumber++">
        <v-icon left>mdi-account</v-icon>
        All Services List
      </v-tab>
      <v-tab>
        <v-icon left>mdi-account</v-icon>
        Sign Out
      </v-tab>
      <v-tab-item>
        <v-card flat>
          <settingsManageLocation :key="locationNumber" />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card flat>
          <settingsManageProvider :key="providerNumber" />
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat>
          <settingsManageService :key="serviceNumber" />
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <br />
        <v-btn outlined class="mr-4" color="grey darken-2" @click="logOut()"
          >Logout</v-btn
        >
      </v-tab-item>
    </v-tabs>
  </div>
</template>
<style scoped>
table,
td,
th {
  border: 1px solid;
}
.patientTable {
  border-collapse: collapse;
  text-align: center;
  width: 100%;
}
.center {
  text-align: center;
  position: relative;
}
</style>
<script>
import settingsManageProvider from "./settingsManageProvider.vue";
import settingsManageService from "./settingsManageService.vue";
import settingsManageLocation from "./settingsManageLocation.vue";
export default {
  name: "settingsPage",
  created() {},
  props: ["userGroup"],
  components: {
    settingsManageProvider,
    settingsManageService,
    settingsManageLocation,
  },
  data: () => ({
    providerNumber: 0,
    serviceNumber: 0,
    locationNumber: 0,
  }),
  async mounted() {},
  methods: {
    close() {
      this.$emit("closePage");
    },
    logOut() {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("refresh");
      location.reload();
    },
  },
};
</script>
