<template>
  <div >
    <v-row>
      <v-col>
        <v-sheet height="970" class="unselectable">
          <!-- top bar to display location and provider -->
          <v-row class="ml-3">
            <v-col cols="12" sm="2" md="3">
              <v-select
                v-model="location"
                :item-text="'companyName'"
                :item-value="'companyName'"
                :items="locationList"
                label="Location"
                :day-format="dayFormat"
                dense
                outlined
                @change="getLocationId($event)"
                return-object
              >
              </v-select>
            </v-col>
            <v-col >
              <v-chip-group
                v-model="selectedProviderIndexes"
                multiple
                max="3"
                @change="selectProvider($event)"
                active-class="primary--text"
              >
                <v-chip
                  v-for="(key, index) in pickProvider"
                  :key="key.providerName"
                  :value="index"
                >
                  {{ key.providerName }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
          <!-- calendar body -->
          <v-calendar
            ref="calendar"
            v-model="focus"
            color="primary"
            :now="today"
            :value="today"
            :type="'month'"
            :event-ripple="false"
            @click:date="clickOnDate"
            @change="getEvents"
          >
            <template v-slot:day="{ date }">
              <v-sheet rounded="lg" class="text-center mx-auto eachCell">
                <monthlyPreviewSchedule
                  class="blackborder"
                  v-if="allowRender"
                  :events="events"
                  :selectedProvidersList="selectedProvidersList"
                  :date="date"
                  :key="changeChipNumber"
                  :locationWorkOffTime="locationWorkOffTime"
                  :providerWorkOffTime="providerWorkOffTime"
                />
              </v-sheet>
            </template>
          </v-calendar>
        </v-sheet>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Vue from "vue";
import monthlyPreviewSchedule from "../components/calendarComponents/monthlyPreviewSchedule.vue";
export default {
  name: "userCalendarMonthView",
  components: {
    monthlyPreviewSchedule,
  },
  async created() {
    
    this.$root.$on("nextDay", this.next);
    this.$root.$on("prevDay", this.prev);
    this.$root.$on("focusToday", this.focusToday);
  },
  beforeDestroy() {
    this.$root.$off("nextDay", this.next);
    this.$root.$off("prevDay", this.prev);
    this.$root.$off("focusToday", this.focusToday);
  },
  async mounted() {
    const locationData = localStorage.getItem("lOp");
      if (locationData) {
        this.selectedLocation = JSON.parse(locationData);
      }
    // call some functions to prototype for later use
    await this.getProviderByLocation();
    Vue.prototype.$getDoctorsList = async () => {
      let doctors = [];
      await this.$axios({
        method: "get",
        url: this.$axios.defaults.baseURL + "/api/getDoctorsList",
        headers: { Authorization: localStorage.getItem("accessToken") },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            doctors.push(appData);
          });
        })
        .catch((error) => console.log(error));
      Vue.prototype.$doctorsListObj = doctors;
    };

    Vue.prototype.$getServicesList = async () => {
      let services = [];
      await this.$axios({
        method: "get",
        url: this.$axios.defaults.baseURL + "/api/getServicesList",
        headers: { Authorization: localStorage.getItem("accessToken") },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            services.push(appData);
          });
        })
        .catch((error) => console.log(error));
      Vue.prototype.$servicesListObj = services;
    };
    Vue.prototype.$getLocationsList = async () => {
      let locationListTemp = [];
      await this.$axios({
        method: "get",
        url: this.$axios.defaults.baseURL + "/api/getLocationsList",
        headers: { Authorization: localStorage.getItem("accessToken") },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            locationListTemp.push(appData);
          });
        })
        .catch((error) => console.log(error));
      Vue.prototype.$LocationsListObj = locationListTemp;
    };
    try {
      //add none to location list to clear all data, and default it when first time using website
      const response = await this.updateLocationList();
      if (response && Array.isArray(response.data)) {
        this.locationList = [{ companyName: "None", value: null }].concat(
          response.data
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
    //get data from provider
    await this.selectProvider(this.selectedProviderIndexes);
    //render monthly view for time table
    this.changeChipNumber++;
  },
  data: () => ({
    location: "",
    locationList: [],
    locationId: "",
    changeChipNumber: 0,
    onboarding: 0,
    settingsPageToggle: false,
    today: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    focus: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    type: "month",
    category: "category",
    typeToLabel: {
      month: "Day",
      week: "Week",
      category: "Day",
    },
    color: "#1976D2",
    events: [],
    allPatientsListPage: false,
    allPatientsPageCounter: 0,
    settingsPageCounter: 0,
    newEventPageCounter: 0,
    newEventDialog: false,
    createEventstart: "",
    createEventend: "",
    provider: "",
    selectedElement: null,
    selectedEvent: {},
    checkInStatus: false,
    pickProvider: [],
    selectedProvidersList: [],
    selectedProviderIndexes: [0, 1, 2],
    companyName: "",
    locationWorkOffTime: {
      workTime: [],
      offTime: [],
    },
    providerWorkOffTime: {
      workTime: [],
      offTime: [],
    },
  }),
  computed: {
    allowRender() {
      return (
        this.selectedProvidersList &&
        this.locationWorkOffTime &&
        this.providerWorkOffTime
      );
    },
  },
  watch: {},
  methods: {
    focusToday: function () {
      this.today = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.focus = new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
    },
    dayFormat(date) {
      const day = date.date.slice(-2);
      const month = new Date(date.date).toLocaleString("default", {
        month: "short",
      });
      return `${month} ${day}`;
    },
    async getProviderByLocation() {
      //get this location's provider and fill in pickProvider
      let uniqueId = "";
      const storedOption = localStorage.getItem("lOp");
      if (storedOption) {
        uniqueId = JSON.parse(storedOption).locationId;
      }
      let doctors = [];
      await this.$axios({
        method: "post",
        url:
          this.$axios.defaults.baseURL + "/api/getProviderFilterWithLocation",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          locationId: uniqueId,
        },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            let appData = doc;
            doctors.push(appData);
          });
        })
        .catch((error) => console.log(error));

      this.pickProvider = doctors;
      return this.pickProvider;
    },
    async updateLocationList() {// update location's list
      await this.$getLocationsList();
      let data = this.$LocationsListObj;
      this.locationList = data;
      return new Promise((resolve) => {
        resolve({
          data,
        });
      });
    },
    async getLocationId(event) {
      this.locationId = event;
      if(event){
        localStorage.setItem("lOp", JSON.stringify(event));
      }
      const providers = await this.getProviderByLocation();
      const dataToPass = {
        selectedDate: this.today,
        selectedProviders: providers,
      };
      localStorage.setItem("dwp", JSON.stringify(dataToPass));

      const length = providers.length;
      let indexes;
      if (length >= 3) {
        indexes = [0, 1, 2];
      } else {
        indexes = Array.from({ length: length }, (_, i) => i);
      }

      this.selectedProviderIndexes = indexes;

      await this.selectProvider(this.selectedProviderIndexes);
      await this.getEvents();
      await this.updateLocationList();
    },

    async getEvents() {
      //get all events 
      let events = [];
      let tempItem = [];
      const storedOption = localStorage.getItem("lOp");
      if (storedOption) {
        tempItem = JSON.parse(storedOption);
        tempItem = tempItem.companyName;
      }
      this.companyName = tempItem;
      await this.$axios({
        method: "post",
        url: this.$axios.defaults.baseURL + "/api/getMonthlyEvents",
        headers: { Authorization: localStorage.getItem("accessToken") },
        data: {
          companyName: this.companyName,
        },
      })
        .then((response) => {
          response.data.forEach((doc) => {
            events.push(doc);
          });
        })
        .catch((error) => console.log(error));

      this.loadSelectedOption();
      this.events = events;
      this.changeChipNumber++;
    },
    loadSelectedOption() {
      //if there is a previous data stored, default it instead of picking from fetched data from database
      const storedOption = localStorage.getItem("lOp");
      if (storedOption) {
        this.location = JSON.parse(storedOption);
      }
    },
    prev() {
      //go back a month and rerender the provider's info
      this.$refs.calendar.prev();
      this.selectProvider(this.selectedProviderIndexes);
      this.$root.$emit("focusChanged", this.focus);
    },
    next() {
       //go forward a month and rerender the provider's info
      this.$refs.calendar.next();
      this.selectProvider(this.selectedProviderIndexes);
      this.$root.$emit("focusChanged", this.focus);
    },
    async selectProvider(event) {
      //push the name of each provider to the chip group to display
      if (event) {
        event = event.sort();
        let tempList = [];
        event.forEach((doc) => {
          if (this.pickProvider[doc]) {
            tempList.push(this.pickProvider[doc]);
          }
        });
        this.selectedProvidersList = tempList;
      }
      //set current location
      const storedOption = localStorage.getItem("lOp");
      if (storedOption) {
        this.location = JSON.parse(storedOption);
      }
      //given the providers list, get location and provider's work time and off time
      let SPIobj = { index: event, location: this.location.locationId };
      localStorage.setItem("SPI", JSON.stringify(SPIobj));
      await this.fetchLocationWorkOffTimes();
      await this.fetchProviderWorkOffTimes();
      this.changeChipNumber++;
    },

    clickOnDate: function (event) {
      //when clicking on each day's date, store it to local storage and push to /day
      const dataToPass = {
        selectedDate: event.date,
        selectedProviders: this.selectedProvidersList,
      };
      localStorage.setItem("dwp", JSON.stringify(dataToPass));
      this.$root.$emit("focusChanged", this.focus);
      this.$router.push({
        name: "Day",
      });
    },

    async fetchProviderWorkOffTimes() {
      //get provider off time and work time
      const providerUrls = [
        "/api/getProviderOffTime",
        "/api/updateProviderWorkDays/get",
      ];

      let tempOffTime = {
        offTime: [],
        workTime: [],
      };

      const allPromises = [];

      this.selectedProvidersList.forEach((prov) => {
        const promises = providerUrls.map((url) => {
          return this.$axios({
            method: "post",
            url: this.$axios.defaults.baseURL + url,
            headers: { Authorization: localStorage.getItem("accessToken") },
            data: {
              providerId: prov.providerId,
              locationId: this.location.locationId,
            },
          });
        });

        allPromises.push(
          Promise.all(promises).then((responses) => {
            responses[1].data.provider = prov;
            tempOffTime.offTime.push(responses[0].data);
            tempOffTime.workTime.push(responses[1].data);
            //store the results here
            this.providerWorkOffTime = tempOffTime;
          })
        );
      });
      await Promise.all(allPromises);
    },

    async fetchLocationWorkOffTimes() {
      //get location work and off time
      const locationUrls = [
        "/api/getLocationOffTimeList",
        "/api/updateLocationWorkDays/get",
      ];
      const promises = locationUrls.map((url) => {
        return this.$axios({
          method: "post",
          url: this.$axios.defaults.baseURL + url,
          headers: { Authorization: localStorage.getItem("accessToken") },
          data: {
            providerIdList: this.selectedProvidersList,
            locationId: this.location.locationId,
          },
        });
      });

      await Promise.all(promises)
        .then((responses) => {
          //store it here
          this.locationWorkOffTime.offTime = responses[0].data;
          this.locationWorkOffTime.workTime = responses[1].data;
        })
        .catch((error) => {
          console.error("One or more requests failed:", error);
        });
    },
  },
};
</script>
<style scoped lang="scss">
.highlight-text {
  font-size: 1.5em;
  font-weight: bold;
}
.eachCell {
  height: 70%;
  display: table;
}
.blackborder {
  border: 1px solid black;
  display: table-row;
}
.v-event-draggable {
  padding-left: 6px;
}
.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}
.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;
  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }
  &:hover::after {
    display: block;
  }
}
.container-outline {
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 30px;
  margin-left: 30px;
}
.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
